.tournaments-container {
   perspective: 1500px;
   perspective-origin: center 100%;
   width: 100%;
   display: grid;
   grid-template-rows: repeat(1,1fr);
   /* grid-template-columns: repeat(auto-fill,minmax(336px,1fr)); */
   grid-template-columns: repeat(auto-fill, minmax(290px, 336px));
   justify-content: center;
   grid-gap: 20px !important;
   margin-bottom: 25px;
}
.actual-head th {
  text-align: center !important;
}
.user-rankings * {
  color: var(--green) !important
}
.no-results {
  padding: 15px;
  padding-left: 0;
}
.tournament-card {
  transform-origin: top center;
  -webkit-animation-name: move;
  animation-name: move;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  /*border: 2px solid transparent;*/
  position: relative;
  transition: all .3s ease-out;
  border-radius: 3px;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  background: var(--pr-card-bg);
  box-shadow: var(--pr-card-bsh);
  border: 2px solid transparent;
}
.tournament-top {
  height: 308px;
  overflow: hidden;
  cursor: pointer;
  transition: all .3s ease-out;
  border-bottom: 2px solid transparent;
  align-self: stretch;
}
.tournament-preview {
   width: 100%;
   height: 100%;
   transition: all .3s ease-out;
   border-radius: 3px 3px 0px 0px;
}
.read-more {
  text-align: center;
   color: var(--green);
   background: var(--buttons-bg);
   border: 1px solid var(--green);
   max-width: 164.74px;
   width: 100%;
   height: 46.66px;
   /* height: 100%; */
   cursor: pointer;
   margin: 6px auto;
   font-weight: 500;
   font-size: 14px;
   line-height: 17px;
   text-transform: uppercase;
   align-items: center;
   justify-content: center;
   border-radius: 3px;
   transition: all .3s ease-out;
}
.read-more:hover {
   background: var(--green);
   color: #ffffff;
   box-shadow: var(--active-shadow);
}
.tournament-card .btn-container {
   height: 312px;
}
.tournament-card:hover {
   border: 2px solid var(--green);
}
body.dark .tournament-card:hover {
   box-shadow: 0px 9px 30px rgba(127, 244, 174, 0.35);
}
.tournament-card:hover .tournament-top {
   border-bottom: 2px solid var(--green);
}
.tournament-card:hover .tournament-preview {
   opacity: .25;
}
.tournament-card:hover .btn-container {
   opacity: 1;
   z-index: 10
}
.tournament-card .tournament-info {
  height: 186px;
  position: relative;
  padding: 35px 28px 0;
  color: var(--font-default);
  flex-direction: column;
  border-radius: 0px 0px 3px 3px;
  align-self: stretch;
}
.tournament-card p {
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;
   margin: 0;
   max-width: 100%;
   margin-bottom: 5px;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}
.tournament-count {
  position: absolute;
  top: 294px;
  z-index: 1;
  /* left: 13px; */
}
.tournament-count span {
   display: inline-block;
   padding: 8px 28px;
   border-radius: 50px;
   background-color: var(--orange);
}
.tournament-count * {
   color: #fff;
}
.tournament-card .tournament-date {
  margin-top: 10px;
  margin-bottom: 0;
  flex-direction: column;
}
.tournament-card .tournament-date span {
   mix-blend-mode: normal;
   opacity: 0.5;
   font-size: 14px;
   font-weight: 400;
   line-height: 17px;
   max-width: 70%;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}
.tournament-card .tournament-date span:first-child {
  margin-right: 23px;
  margin-bottom: 5px;
}
.tournament-card .prize-block {
  margin-top: 25px;
  justify-content: space-between;
  align-items: center;
}
.tournament-card .prize-title {
   color: var(--green);
}
.tournament-card .prize-btn {
  display: flex;
  align-items: center;
  padding: 8px 28px;
  border-radius: 50px;
  background-color: var(--green);
  color: #fff;
}
.tournament-card .prize-btn img {
  margin-left: 5px;
}
.tournament-description {
  padding: 20px;
  border-radius: 3px;
  background: var(--pr-card-bg);
    box-shadow: var(--pr-card-bsh);
}
.tournament-description .account-table {
  max-width: 300px;
  margin: 0 auto;
  height: auto;
  padding: 0;
  min-height: 0px !important;
  width: 100%;
}
.tournament-description .account-table tr td {
  text-align: center;
}
.tournament-description .account-table thead th {
  color: var(--green);
}
.tournament-description .account-table td div.first-place,
.tournament-description .account-table td div.second-place,
.tournament-description .account-table td div.third-place,
.tournament-description .account-table td div.empty-place {
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 1;
    margin-right: 5px;
    margin-bottom: -10px;
}
.tournament-description .account-table td div.first-place {
  background-image: url(../images/medals/gold.svg);
}
.tournament-description .account-table td div.second-place {
  background-image: url(../images/medals/silver.svg);
}
.tournament-description .account-table td div.third-place {
  background-image: url(../images/medals/bronze.svg);
}
.tournament-description .account-table .device-title > div{
  display: inline-block;
}
.tournament-detail-cms {
  width: 50%;
  max-width: 570px;
  flex-direction: column;
}
.tournament-table .actual-head th{
  font-size: 12px
}
.tournament-table tbody td,
.tournament-table .device-title div {
  font-size: 14px;
}
.tournament-description .account-table td div.empty-place {
  height: 24px !important;
}
#tournaments-page .games-container {
  margin-top: 25px;
}
#tournaments-page .width-container{
  margin-bottom: 30px;
}
/* #tournaments-page .fav-block {
  display: none;
} */
.back-button {
  margin-top: 65px;
  margin-bottom: 0;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
}
.tournament-single-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tournament-single-header .tournament-date {
  margin-top: 32px;
  margin-bottom: 22px;
  opacity: .5;
}
.tournament-single-header .tournament-date span,
.tournament-single-header .tournament-date span strong {
  color: var(--tournament-card-date-label);
}
.tournament-single-header .tournament-date .tournament-start {
  margin-right: 20px;
}
.tournament-single-btns-container {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}
.tournament-single-prize-block {
  align-items: center;
}
.tournament-single-prize-block .prize-title {
  margin-right: 22px;
  font-weight: 500;
}
.tournament-single-prize-block .prize-btn {
  margin-right: 22px;
  display: flex;
  align-items: center;
  padding: 8px 28px;
  border-radius: 50px;
  background-color: var(--green);
  color: #fff;
}
.tournament-single-prize-block .prize-btn img {
  margin-left: 5px;
}
.tournament-single-count.tournament-count {
  position: inherit;
}
/* .tournament-single-prize-block p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  max-width: 100%;
  margin-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
} */