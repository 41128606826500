.cookie-window::part(content) {
  height: auto;
  min-height: 260px
}
/*Accepting Rules*/
.mod-confirm-wrapper {
  width: 100%;
  max-width: 885px;
  margin: 50px auto;
  margin-top: 0px;
}
.mod-confirm-wrapper .btn {
  /* width: 158px; */
  cursor: pointer;
  margin-top: 30px;
  margin-left: auto;
  background-color: #268A00;
  font-weight: 500;
  /* margin-right: 52px; */
}
.mod-confirm-wrapper .btn:hover {
  opacity: 0.7;
}
.mod-confirm  {
  border-radius: 4px;
}
.mod-confirm .modal-data {
  padding: 35px 50px;
}
.mod-confirm .modal-title, .mod-confirm .modal-description {
  margin-left: 0
}
.mod-confirm .modal-title {
  margin-top: 0;
}
.modal-content {
  font-size: 14px;
  margin: 0;
}
.modal-content a {
  color: var(--font-default);
  text-decoration: underline;
  font-weight: 500;
}
.modal-content a:hover,
.modal-content a:active,
.modal-content a:focus {
  text-decoration: none;
}
.btn-cancel {
  width: 158px;
  margin-right: 16px;;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  position: relative;
  color: #8B9A96;
  background: var(--cancel-btn-bg);
  border-radius: 5px;
  padding: 20px;
  height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mod-confirm .modal-buttons {
  margin-right: 0;
  width: auto;
  justify-content: end;
}
.mod-confirm .modal-buttons {
  margin-top: 30px;
}