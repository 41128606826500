.width-container .header-giveaways-container {
  margin-bottom: 56px;
}
.width-container .header-giveaways-container .header-giveaways {
  display: flex;
  justify-content: space-between;
}
.width-container .header-giveaways-container .header-giveaways .giveaways-page-title {
  margin-top: 56px;
  margin-bottom: 0px;
  color: var(--section-message-text);
  width: 573px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 50px;
}
.width-container .header-giveaways-container .header-giveaways .sort-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.width-container .header-giveaways-container .header-giveaways .sort-cont .text-sortyby {
  margin-top: 71px;
  margin-right: 16px;
  color: var(--subscribe-text-description);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 58px;
}
.width-container .header-giveaways-container .header-giveaways .sort-cont .giveaways-header-sorting {
  margin-top: 56px;
  display: flex;
  min-width: 313px;
  width: 313px;
  height: 52px;
  background-color: var(--header-background);
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  justify-content: center;
  gap: 4px;
}
.width-container .header-giveaways-container .header-giveaways .sort-cont .giveaways-header-sorting .leader-board-header {
  gap: 4px;
}
.width-container .header-giveaways-container .header-giveaways .sort-cont .giveaways-header-sorting .leader-board-header .leader-board-header-tab {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  font-family: "Rubik";
  font-style: normal;
}
.width-container .body-event-giveaways {
  height: 190px;
  background: var(--bg-event-giveaways);
  border: 1px solid rgba(38, 138, 0, 0.2);
  border-left-style: none;
  box-shadow: 0px 44px 140px -20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0px 0px 0px 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.width-container .body-event-giveaways .onclick-joined {
  position: absolute;
  z-index: 1;
  left: 40px;
  width: calc( 100vw - 80px );
  height: 190px;
}
.width-container .body-event-giveaways .event-left-giveaways {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50vw;
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways {
  position: relative;
  display: flex;
  flex-direction: row;
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--subscribe-text-description);
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .timer-event-giveaways .time-color-one {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: var(--green);
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .timer-event-giveaways .time-color-two {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: var(--subscribe-text-description);
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-is-being-verified {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 2px 8px;
  gap: 4px;
  background: rgba(238, 183, 134, 0.2);
  border-radius: 40px;
  margin-top: 6px;
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-is-being-verified .winner-text {
  font-family: "Rubik";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--orange);
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-is-being-verified .winner-icon {
  width: 16px;
  height: 16px;
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  background: rgba(168, 208, 153, 0.2);
  border-radius: 40px;
  margin-top: 6px;
  position: relative;
  z-index: 4;
  cursor: pointer;
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-event-text {
  color: var(--text-winner-giveaways);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-cont .avatar-winner {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  overflow: hidden;
  margin-left: 4px;
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-cont .nickname-winner {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-left: 4px;
  color: var(--text-winner-giveaways);
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event:hover .nickname-winner {
  color: var(--green);
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .prize-value-giveaways {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 216px;
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .prize-value-giveaways .text-prize-value {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--subscribe-text-description);
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .prize-value-giveaways .sum-prize {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: var(--orange);
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 366px;
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways .text-joined-value {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--subscribe-text-description);
}
.width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways .cur-joined {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: var(--text-description);
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont {
  display: flex;
  flex-direction: row;
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways {
  display: flex;
  flex-direction: row;
  margin-bottom: 23px;
  position: relative;
  z-index: 2;
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways {
  width: 83px;
  height: 40px;
  border-radius: 8px;
  padding: 9px 24px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--green);
  bottom: 0px;
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways:hover {
  background-color: var(--green-btn-hover);
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete {
  display: flex;
  flex-direction: row;
  position: relative;
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body {
  width: 117px;
  height: 40px;
  background-color: var(--cancel-btn);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body .buttons-event-giveaways-complete-icon {
  margin-right: 11.44px;
  color: var(--button-share-icon);
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body .buttons-event-giveaways-complete-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--button-share-icon);
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled {
  opacity: 0.3;
  background-color: var(--cancel-btn);
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled .buttons-event-giveaways-complete-icon {
  color: var(--button-share-icon);
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled .buttons-event-giveaways-complete-text {
  color: var(--button-share-icon);
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways {
  width: 40px;
  height: 40px;
  background-color: var(--cancel-btn);
  justify-content: center;
  align-items: center;
  padding: 9px;
  gap: 10px;
  border-radius: 8px;
  margin-left: 8px;
  margin-right: 24px;
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways:hover {
  background-color: var(--button-share-giveaways-hover);
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways:hover .icon-share-giveaways {
  color: var(--button-share-icon-hover);
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .icon-share-giveaways {
  width: 22px;
  height: 22px;
  color: var(--button-share-icon);
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .icon-share-giveaways:hover {
  color: var(--button-share-icon-hover);
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont {
  display: flex;
  flex-direction: column;
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont .text-need-to-join {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--subscribe-text-description);
}
.width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont .tickets-value {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--green);
}
.width-container .body-event-giveaways .event-right-giveaways {
  display: flex;
  flex-direction: row;
  width: 50vw;
  padding: 0px 9px 0px 0px;
}
.width-container .body-event-giveaways .event-right-giveaways .border-stick {
  border: 0.5px solid var(--stick-giveaways);
  opacity: 0.3;
  margin-left: 12px;
  margin-right: 12px;
}
.width-container .body-event-giveaways .event-right-giveaways .show-prizes {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}
.width-container .body-event-giveaways .event-right-giveaways .show-prizes .image-prizes {
  min-height: 85px;
  max-height: 95px;
  margin-bottom: 19px;
}
.width-container .body-event-giveaways .event-right-giveaways .show-prizes .title-of-item {
  color: var(--text-description);
  letter-spacing: 0.15px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.width-container .body-event-giveaways .event-right-giveaways .show-prizes .price-item {
  color: var(--orange);
  letter-spacing: 0.15px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.body-no-giveaways {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1060px;
  height: 410px;
  border-radius: 14px;
  background: var(--levels-main-info-container-bg);
  border: 1px solid rgba(38, 138, 0, 0.2);
  box-shadow: 0px 44px 140px -20px rgba(0, 0, 0, 0.1);
}
.body-no-giveaways .img-no-giveaways {
  margin-top: 116px;
  margin-bottom: 24px;
}
.body-no-giveaways .text-no-giveaways {
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 42px;
  color: var(--section-message-text);
}
.back-btn-joined {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 25px;
  margin-bottom: 25px;
  cursor: pointer;
}
.back-btn-joined svg {
  fill: var(--subscribe-text-description);
  margin-right: 10px;
}
.back-btn-joined .text-back-btn-joined {
  color: var(--subscribe-text-description);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.back-btn-joined:hover svg {
  fill: var(--address-title);
}
.back-btn-joined:hover .text-back-btn-joined {
  color: var(--address-title);
}
.joined-users-title-cont {
  margin-bottom: 24px;
  margin-top: 60px;
}
.joined-users-title-cont .joined-users-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: var(--section-message-text);
}
.joined-users-cont .joined-zero-conteiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1060px;
  height: 410px;
  border-radius: 8px;
  background-color: var(--header-background);
}
.joined-users-cont .joined-zero-conteiner .img-lightning {
  margin-top: 84px;
  margin-bottom: 24px;
}
.joined-users-cont .joined-zero-conteiner .text-joined-zero {
  font-weight: 500;
  font-size: 34px;
  line-height: 42px;
  margin-bottom: 24px;
  color: var(--section-message-text);
}
.joined-users-cont .joined-zero-conteiner .button-join-giveaways {
  width: 209px;
  height: 40px;
  border-radius: 8px;
  padding: 9px 24px;
  gap: 10px;
  background-color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
}
.joined-users-cont .joined-zero-conteiner .button-join-giveaways p {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}
.joined-users-cont .joined-zero-conteiner .button-join-giveaways:hover {
  background-color: var(--green-btn-hover);
}
.users-joined-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.users-joined-cont .square-user {
  display: flex;
  flex-direction: column;
  width: 160px;
  height: 134px;
  border-radius: 8px;
  padding: 0px 0px 16px 0px;
  background-color: var(--header-background);
}
.users-joined-cont .square-user .you-or-no {
  margin-left: 4px;
  margin-top: 4px;
  width: 41px;
  height: 24px;
  padding: 0px 6px;
  position: absolute;
  background-color: var(--red);
  border-radius: 4px;
}
.users-joined-cont .square-user .you-or-no .text-you-or-no {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}
.users-joined-cont .square-user .user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}
.users-joined-cont .square-user .user-info .user-avatar {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 16px;
}
.users-joined-cont .square-user .user-info .user-nick {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: var(--section-message-text);
}
.users-joined-cont .square-user:hover .user-info .user-nick {
  color: var(--green);
}
ion-modal.premium-modal.modal-steam-link {
  --width: 500px;
  --height: 520px;
  --border-radius: 8px;
  --background: var(--modal-body-standart-bg);
}
.header-steam-link-modal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 52px;
  width: inherit;
  background-color: var(--modal-header-bg);
  padding: 14px 20px;
}
.header-steam-link-modal .text-header-steam {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: var(--section-message-text);
}
.steam-link-modal-info {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;
}
.steam-link-modal-info .img-steam-link-modal {
  margin-bottom: 24px;
}
.steam-link-modal-info .title-steam-link-modal {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: var(--section-message-text);
}
.steam-link-modal-info .text-steam-link-modal {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: var(--subscribe-text-description);
}
.steam-link-modal-info .steam-link-input-cont {
  margin-top: 24px;
}
.steam-link-modal-info .steam-link-input-cont .text-upper-input-cont .text-upper-input {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--subscribe-text-description);
}
.steam-link-modal-info .steam-link-input-cont .input-steam-link {
  margin-top: 6px;
  width: 436px;
  height: 50px;
  padding: 14px;
  border: none;
  box-shadow: 0 0 0 1px var(--input-border);
  gap: 10px;
  border-radius: 8px;
  background-color: var(--subscribe-input-bg);
}
.steam-link-modal-info .steam-link-input-cont .input-steam-link:focus {
  outline: 1px solid #268a00;
}
.steam-link-modal-info .steam-link-input-cont .input-steam-link:hover {
  outline: 1px solid #268a00;
}
.steam-link-modal-info .btn-steam-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 436px;
  height: 50px;
  margin-top: 24px;
  background-color: var(--green);
  padding: 14px 30px;
  border-radius: 8px;
}
.steam-link-modal-info .btn-steam-link .text-in-btn-steam-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}
.steam-link-modal-info .btn-steam-link:disabled {
  opacity: 0.3;
}
.steam-link-modal-info .btn-steam-link:hover {
  background-color: var(--green-btn-hover);
}
ion-modal.premium-modal.modal-requirement {
  --width: 500px;
  --height: auto;
  --border-radius: 8px;
  --background: var(--modal-body-standart-bg);
}
.header-requirement-modal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  width: inherit;
  padding: 14px 20px;
  gap: 10px;
  background-color: var(--modal-header-bg);
}
.header-requirement-modal .title-header-requirement-modal {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: var(--section-message-text);
}
.requirement-modal-cont {
  width: inherit;
  height: 330px;
}
.requirement-modal-cont .requirement-modal-info {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.requirement-modal-cont .requirement-modal-info .title-requirement-modal {
  margin-top: 24px;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: var(--section-message-text);
}
.requirement-modal-cont .requirement-modal-info .text-requirement-modal {
  margin-top: 4px;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: var(--subscribe-text-description);
}
.requirement-modal-cont .requirement-modal-info .button-requirement-modal {
  margin-top: 16px;
  width: 95px;
  height: 50px;
  padding: 14px 30px;
  background-color: var(--green);
  border-radius: 8px;
}
.requirement-modal-cont .requirement-modal-info .button-requirement-modal .text-btn-requirement {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}
.requirement-modal-cont .requirement-modal-info .button-requirement-modal:hover {
  background-color: var(--green-btn-hover);
}
.requirement-modal-no-tickets-cont {
  width: inherit;
  height: 434px;
  padding: 0px 24px;
}
.requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .title-requirement-no-tickets-modal {
  margin-top: 24px;
  font-weight: 500;
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  color: var(--section-message-text);
}
.requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  width: 452px;
  height: 142px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--modal-header-bg);
}
.requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner .requirement-modal-no-tickets-first-yarus {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner .requirement-modal-no-tickets-first-yarus span {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: var(--section-message-text);
}
.requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner .requirement-modal-no-tickets-second-yarus {
  margin-top: 16px;
}
.requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner .requirement-modal-no-tickets-second-yarus button {
  width: 149px;
  height: 50px;
  border-radius: 8px;
  padding: 14px 30px 14px 30px;
  background-color: var(--green);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}
.requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner .requirement-modal-no-tickets-second-yarus button:hover {
  background-color: var(--green-btn-hover);
}
ion-toast.toast-joined {
  --background: var(--header-background);
  --border-radius: 8px;
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  --width: 352px;
  color: var(--section-message-text);
  --height: 118px;
}
.green {
  color: var(--green);
}
ion-toast.toast-joined::part(header) {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
  margin-bottom: 4px;
}
ion-toast.toast-joined::part(message) {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
ion-toast.toast-joined:font {
  color: #268a00;
}
ion-toast.toast-joined::part(container) {
  align-items: normal;
}
ion-toast.toast-joined::part(icon) {
  margin-top: 16px;
}
ion-toast.toast-joined::part(button) {
  margin-top: 6px;
}
.page-switch-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 46px;
}
.page-switch-cont .page-swicth-show-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 328px;
  height: 50px;
  padding: 13px 20px;
  border-radius: 8px;
  background-color: var(--green);
  margin-bottom: 24px;
}
.page-switch-cont .page-swicth-show-more .el-in-btn-show-more {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.page-switch-cont .page-swicth-show-more .el-in-btn-show-more .icon-btn-show-more {
  font-size: 150%;
  margin-right: 13px;
  fill: #fff;
}
.page-switch-cont .page-swicth-show-more .el-in-btn-show-more .text-btn-show-more {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}
.page-switch-cont .page-swicth-show-more:hover {
  background-color: var(--green-btn-hover);
}
.page-switch-cont .arr-btn-page-switch-cont {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
}
.page-switch-cont .arr-btn-page-switch-cont .ellipsis {
  order: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: var(--page-switch-bg);
}
.page-switch-cont .arr-btn-page-switch-cont .ellipsis span {
  color: var(--section-message-text);
}
.page-switch-cont .arr-btn-page-switch-cont .ellipsis.last-page {
  order: 1;
  margin-left: 8px;
}
.page-switch-cont .arr-btn-page-switch-cont .ellipsis.first-page {
  margin-left: 8px;
}
.page-switch-cont .arr-btn-page-switch-cont .focus-page-switch.current .arr-btn-page-switch {
  background-color: var(--green);
}
.page-switch-cont .arr-btn-page-switch-cont .focus-page-switch.current .arr-btn-page-switch .arr-btn-text {
  color: var(--text-focus-switch);
}
.page-switch-cont .arr-btn-page-switch-cont .focus-page-switch.current .arr-btn-page-switch:hover {
  background-color: var(--green-btn-hover);
}
.page-switch-cont .arr-btn-page-switch-cont .focus-page-switch.current .arr-btn-page-switch:hover .arr-btn-text {
  color: var(--text-focus-switch);
}
.page-switch-cont .arr-btn-page-switch-cont .focus-page-switch .ellipsis-cont {
  display: flex;
  flex-direction: row;
}
.page-switch-cont .arr-btn-page-switch-cont .focus-page-switch .arr-btn-page-switch {
  margin-left: 8px;
  order: 1;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: var(--page-switch-bg);
}
.page-switch-cont .arr-btn-page-switch-cont .focus-page-switch .arr-btn-page-switch .arr-btn-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: var(--section-message-text);
}
.page-switch-cont .arr-btn-page-switch-cont .focus-page-switch .arr-btn-page-switch:hover .arr-btn-text {
  color: var(--green);
}
.page-switch-cont .arr-btn-page-switch-cont .focus-page-switch .arr-btn-page-switch.last-page {
  order: 2;
}
@media  ( min-width: 3400px )  {
  #root .width-container .header-giveaways-container {
    margin-bottom: 100px;
  }
  #root .width-container .header-giveaways-container .header-giveaways .giveaways-page-title {
    margin-top: 100px;
    width: 1284px;
    font-weight: 500;
    font-size: 84px !important;
    line-height: 94px;
  }
  #root .width-container .header-giveaways-container .header-giveaways .sort-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  #root .width-container .header-giveaways-container .header-giveaways .sort-cont .text-sortyby {
    margin-top: 125px;
    margin-right: 32px;
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
    width: 200px;
    text-align: right;
  }
  #root .width-container .header-giveaways-container .header-giveaways .sort-cont .giveaways-header-sorting {
    margin-top: 100px;
    min-width: 596px;
    width: 596px;
    height: 92px;
    padding: 14px;
    border-radius: 14px;
    gap: 8px;
  }
  #root .width-container .header-giveaways-container .header-giveaways .sort-cont .giveaways-header-sorting .leader-board-header {
    gap: 8px;
  }
  #root .width-container .header-giveaways-container .header-giveaways .sort-cont .giveaways-header-sorting .leader-board-header .leader-board-header-tab {
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
  }
  #root .width-container .body-event-giveaways {
    height: 380px;
    border-radius: 14px;
    padding: 0px 0px 0px 32px;
    margin-bottom: 24px;
  }
  #root .width-container .body-event-giveaways .onclick-joined {
    width: 2120px;
    left: calc(( 100vw - 2120px - 500px ) / 2);
    height: 380px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways {
    margin-top: 24px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways {
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .timer-event-giveaways .time-color-one {
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .timer-event-giveaways .time-color-two {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.15px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-is-being-verified {
    padding: 4px 14px 4px 10px;
    gap: 8px;
    border-radius: 40px;
    margin-top: 12px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-is-being-verified .winner-text {
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-is-being-verified .winner-icon {
    width: 30px;
    height: 30px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event {
    padding: 4px 14px;
    border-radius: 40px;
    margin-top: 12px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-event-text {
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-cont .avatar-winner {
    width: 40px;
    height: 40px;
    margin-left: 8px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-cont .nickname-winner {
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    margin-left: 8px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .prize-value-giveaways {
    left: 457px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .prize-value-giveaways .text-prize-value {
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .prize-value-giveaways .sum-prize {
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways {
    left: 754px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways .text-joined-value {
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways .cur-joined {
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways {
    margin-bottom: 35px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways {
    width: 165px;
    height: 80px;
    border-radius: 14px;
    padding: 21px 48px;
    gap: 10px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways p {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body {
    width: 231px;
    height: 80px;
    border-radius: 14px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body .buttons-event-giveaways-complete-icon {
    margin-right: 21.94px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body .buttons-event-giveaways-complete-text {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways {
    width: 80px;
    height: 80px;
    padding: 21px;
    gap: 10px;
    border-radius: 14px;
    margin-left: 16px;
    margin-right: 32px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .icon-share-giveaways {
    width: 32px;
    height: 36px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont .text-need-to-join {
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
  }
  #root .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont .tickets-value {
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
  }
  #root .width-container .body-event-giveaways .event-right-giveaways {
    width: 50vw;
    padding: 0px 9px 0px 0px;
  }
  #root .width-container .body-event-giveaways .event-right-giveaways .border-stick {
    border: 0.5px solid var(--stick-giveaways);
    opacity: 0.3;
    margin-left: 15px;
    margin-right: 15px;
  }
  #root .width-container .body-event-giveaways .event-right-giveaways .show-prizes {
    margin-top: 46px;
    margin-bottom: 16px;
  }
  #root .width-container .body-event-giveaways .event-right-giveaways .show-prizes .image-prizes {
    min-height: 200px;
    margin-bottom: 40px;
  }
  #root .width-container .body-event-giveaways .event-right-giveaways .show-prizes .title-of-item {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  #root .width-container .body-event-giveaways .event-right-giveaways .show-prizes .price-item {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  #root .body-no-giveaways {
    width: 2120px;
    height: 820px;
    border-radius: 14px;
  }
  #root .body-no-giveaways .img-no-giveaways {
    width: 182.85px;
    height: 200px;
    margin-top: 251px;
    margin-bottom: 40px;
  }
  #root .body-no-giveaways .text-no-giveaways {
    font-weight: 500;
    font-size: 64px;
    line-height: 78px;
  }
  .back-btn-joined {
    margin-bottom: 60px;
    padding-top: 60px;
  }
  .back-btn-joined svg {
    margin-right: 19px;
    width: 31.67px;
    height: 31.67px;
  }
  .back-btn-joined .text-back-btn-joined {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  #root .joined-users-title-cont {
    margin-bottom: 56px;
    margin-top: 100px;
  }
  #root .joined-users-title-cont .joined-users-title {
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
  }
  #root .joined-users-cont .joined-zero-conteiner {
    width: 2120px;
    height: 820px;
    border-radius: 14px;
  }
  #root .joined-users-cont .joined-zero-conteiner .img-lightning {
    width: 124.03px;
    height: 200px;
    margin-top: 180px;
    margin-bottom: 40px;
  }
  #root .joined-users-cont .joined-zero-conteiner .text-joined-zero {
    font-weight: 500;
    font-size: 64px;
    line-height: 78px;
    margin-bottom: 40px;
  }
  #root .joined-users-cont .joined-zero-conteiner .button-join-giveaways {
    width: 442px;
    height: 100px;
    border-radius: 14px;
    padding: 31px 60px;
    gap: 10px;
  }
  #root .joined-users-cont .joined-zero-conteiner .button-join-giveaways p {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  .users-joined-cont {
    gap: 40px;
    margin-bottom: 40px;
  }
  .users-joined-cont .square-user {
    width: 320px;
    height: 286px;
    border-radius: 14px;
    padding: 0px 0px 24px 0px;
  }
  .users-joined-cont .square-user .you-or-no {
    margin-left: 8px;
    margin-top: 8px;
    width: 80px;
    height: 44px;
    padding: 0px 6px;
    border-radius: 8px;
  }
  .users-joined-cont .square-user .you-or-no .text-you-or-no {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
  }
  .users-joined-cont .square-user .user-info {
    margin-top: 48px;
  }
  .users-joined-cont .square-user .user-info .user-avatar {
    width: 120px;
    height: 120px;
    margin-bottom: 32px;
  }
  .users-joined-cont .square-user .user-info .user-nick {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  #root ion-modal.premium-modal.modal-steam-link {
    --width: 926px;
    --height: 960px;
    --border-radius: 14px;
  }
  #root .header-steam-link-modal {
    height: 92px;
    width: inherit;
    padding: 24px 30px;
  }
  #root .header-steam-link-modal .text-header-steam {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  #root .steam-link-modal-info {
    margin-top: 72px;
    padding: 0px 48px;
  }
  #root .steam-link-modal-info .img-steam-link-modal {
    width: 200px;
    height: 200px;
    margin-bottom: 40px;
  }
  #root .steam-link-modal-info .title-steam-link-modal {
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
  }
  #root .steam-link-modal-info .text-steam-link-modal {
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
  }
  #root .steam-link-modal-info .steam-link-input-cont {
    margin-top: 40px;
  }
  #root .steam-link-modal-info .steam-link-input-cont .text-upper-input-cont .text-upper-input {
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
  }
  #root .steam-link-modal-info .steam-link-input-cont .input-steam-link {
    margin-top: 16px;
    width: 830px;
    height: 100px;
    padding: 31px 32px;
    border-radius: 14px;
  }
  #root .steam-link-modal-info .steam-link-input-cont .input-steam-link:focus {
    outline: 3px solid #268a00;
  }
  #root .steam-link-modal-info .steam-link-input-cont .input-steam-link:hover {
    outline: 3px solid #268a00;
  }
  #root .steam-link-modal-info .btn-steam-link {
    width: 830px;
    height: 100px;
    margin-top: 48px;
    padding: 31px 60px;
    border-radius: 14px;
  }
  #root .steam-link-modal-info .btn-steam-link .text-in-btn-steam-link {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  #root ion-modal.premium-modal.modal-requirement {
    --width: 926px;
    --height: auto;
    --border-radius: 14px;
  }
  #root .header-requirement-modal {
    height: 92px;
    width: inherit;
    padding: 24px 30px;
    gap: 10px;
  }
  #root .header-requirement-modal .title-header-requirement-modal {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  #root .requirement-modal-cont {
    width: inherit;
    height: 636px;
  }
  #root .requirement-modal-cont .requirement-modal-info {
    margin-top: 72px;
  }
  #root .requirement-modal-cont .requirement-modal-info .img-requirement-modal {
    width: 182.85px;
    height: 200px;
  }
  #root .requirement-modal-cont .requirement-modal-info .title-requirement-modal {
    margin-top: 40px;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
  }
  #root .requirement-modal-cont .requirement-modal-info .text-requirement-modal {
    margin-top: 4px;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
  }
  #root .requirement-modal-cont .requirement-modal-info .button-requirement-modal {
    margin-top: 40px;
    width: 189px;
    height: 100px;
    padding: 31px 60px;
    border-radius: 14px;
  }
  #root .requirement-modal-cont .requirement-modal-info .button-requirement-modal .text-btn-requirement {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  .requirement-modal-no-tickets-cont {
    height: 834px;
    padding: 0px 40px;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info {
    margin-top: 72px;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .img-requirement-modal {
    width: 182.85px;
    height: 200px;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .title-requirement-no-tickets-modal {
    margin-top: 40px;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner {
    margin-top: 40px;
    width: 846px;
    height: 290px;
    padding: 32px;
    border-radius: 14px;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner .requirement-modal-no-tickets-first-yarus span {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner .requirement-modal-no-tickets-second-yarus {
    margin-top: 50px;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner .requirement-modal-no-tickets-second-yarus button {
    width: 298px;
    height: 100px;
    border-radius: 14px;
    padding: 31px 60px;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  #root ion-toast.toast-joined {
    --border-radius: 14px;
    padding: 24px;
    gap: 16px;
    --min-width: 615px;
    --width: 635px;
    --height: 235px;
  }
  ion-toast.toast-joined::part(header) {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 16px;
    margin-top: 24px;
  }
  ion-toast.toast-joined::part(message) {
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 24px;
  }
  ion-toast.toast-joined::part(icon) {
    width: 31.67px;
    height: 31.67px;
    margin-right: 20px;
    margin-top: 27px;
    margin-left: 27px;
  }
  ion-toast.toast-joined::part(button) {
    margin-top: 20px;
    margin-right: 20px;
  }
  #root .page-switch-cont {
    margin-top: 100px;
  }
  #root .page-switch-cont .page-swicth-show-more {
    width: 586px;
    height: 100px;
    padding: 28px 40px;
    border-radius: 14px;
    margin-bottom: 32px;
  }
  #root .page-switch-cont .page-swicth-show-more .el-in-btn-show-more .icon-btn-show-more {
    font-size: 120%;
    margin-right: 21.5px;
  }
  #root .page-switch-cont .page-swicth-show-more .el-in-btn-show-more .text-btn-show-more {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  #root .page-switch-cont .arr-btn-page-switch-cont .ellipsis {
    order: 2;
    width: 70px;
    height: 70px;
    border-radius: 14px;
  }
  #root .page-switch-cont .arr-btn-page-switch-cont .ellipsis.last-page {
    order: 1;
    margin-left: 8px;
  }
  #root .page-switch-cont .arr-btn-page-switch-cont .ellipsis.first-page {
    margin-left: 8px;
  }
  #root .page-switch-cont .arr-btn-page-switch-cont .focus-page-switch .arr-btn-page-switch {
    margin-left: 16px;
    order: 1;
    width: 70px;
    height: 70px;
    border-radius: 14px;
  }
  #root .page-switch-cont .arr-btn-page-switch-cont .focus-page-switch .arr-btn-page-switch .arr-btn-text {
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
  }
}
@media  ( max-width: 939px )  {
  .width-container .header-giveaways-container {
    margin-bottom: 45px;
  }
  .width-container .header-giveaways-container .header-giveaways .giveaways-page-title {
    margin-top: 45px;
    min-width: 282px;
    font-weight: 500;
    font-size: 38px;
    line-height: 42px;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont .text-sortyby {
    margin-top: 54px;
    margin-right: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    width: 58px;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont .giveaways-header-sorting {
    margin-top: 40px;
    min-width: 313px;
    gap: 4px;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont .giveaways-header-sorting .leader-board-header {
    gap: 4px;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont .giveaways-header-sorting .leader-board-header .leader-board-header-tab {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    font-family: "Rubik";
    font-style: normal;
  }
  .width-container .body-event-giveaways {
    height: 294px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .width-container .body-event-giveaways .onclick-joined {
    position: absolute;
    z-index: 1;
    width: calc( 100vw - 80px );
    height: 294px;
  }
  .width-container .body-event-giveaways .event-left-giveaways {
    margin-top: 16px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50vw;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways {
    color: var(--subscribe-text-description);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .timer-event-giveaways .time-color-one {
    font-size: 20px;
    line-height: 30px;
    color: var(--green);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .timer-event-giveaways .time-color-two {
    color: var(--subscribe-text-description);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .prize-value-giveaways {
    left: 0px;
    top: calc( 78px - 16px );
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .prize-value-giveaways .sum-prize {
    font-size: 20px;
    line-height: 30px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways {
    left: 0px;
    top: calc( 140px - 16px );
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways .cur-joined {
    font-size: 20px;
    line-height: 30px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 214px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways {
    display: flex;
    flex-direction: row;
    margin-bottom: 22px;
    z-index: 2;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways {
    width: 83px;
    height: 40px;
    border-radius: 8px;
    padding: 9px 24px;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--green);
    bottom: 0px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways:hover {
    background-color: var(--green-btn-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete {
    display: flex;
    flex-direction: row;
    position: relative;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body {
    width: 117px;
    height: 40px;
    background-color: var(--cancel-btn);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body .buttons-event-giveaways-complete-icon {
    margin-right: 11.44px;
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body .buttons-event-giveaways-complete-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled {
    opacity: 0.3;
    background-color: var(--cancel-btn);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled .buttons-event-giveaways-complete-icon {
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled .buttons-event-giveaways-complete-text {
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways {
    width: 40px;
    height: 40px;
    background-color: var(--cancel-btn);
    justify-content: center;
    align-items: center;
    padding: 9px;
    gap: 10px;
    border-radius: 8px;
    margin-left: 8px;
    margin-right: 24px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways:hover {
    background-color: var(--button-share-giveaways-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways:hover .icon-share-giveaways {
    color: var(--button-share-icon-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .icon-share-giveaways {
    width: 22px;
    height: 22px;
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .icon-share-giveaways:hover {
    color: var(--button-share-icon-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont {
    display: flex;
    top: 215px;
    left: 170px;
    flex-direction: column;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont .text-need-to-join {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--subscribe-text-description);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont .tickets-value {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--green);
  }
  .width-container .body-event-giveaways .event-right-giveaways {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    height: 210px;
    left: 206px;
    width: calc( 100vw - 105px - 206px);
    padding: 0px 9px 0px 0px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .border-stick {
    border: 0.5px solid var(--stick-giveaways);
    opacity: 0.3;
    margin-left: 12px;
    margin-right: 12px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes .image-prizes {
    max-height: 95px;
    margin-bottom: 16px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes .title-of-item {
    display: flex;
    color: var(--text-description);
    letter-spacing: 0.15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes .price-item {
    color: var(--orange);
    letter-spacing: 0.15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .width-container .stick-cont {
    position: absolute;
    width: 100vw;
  }
  .width-container .stick-cont .border-stick-down {
    position: relative;
    top: 210px;
    border: 0.5px solid var(--stick-giveaways);
    opacity: 0.3;
    width: calc( 100vw - 80px );
    height: 0.5px;
    left: -24px;
  }
}
@media  ( max-width: 699px) {
  .width-container .header-giveaways-container {
    margin-bottom: 32px;
  }
  .width-container .header-giveaways-container .header-giveaways {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .width-container .header-giveaways-container .header-giveaways .giveaways-page-title {
    margin-top: 32px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont .text-sortyby {
    margin-top: 16px;
    margin-right: 0px;
    width: 58px;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont .giveaways-header-sorting {
    margin-top: 2px;
  }
}
@media  ( max-width: 767px )  {
  .width-container .header-giveaways-container {
    margin-bottom: 45px;
  }
  .width-container .header-giveaways-container .header-giveaways .giveaways-page-title {
    margin-top: 45px;
    min-width: 282px;
    font-weight: 500;
    font-size: 38px;
    line-height: 42px;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont .text-sortyby {
    margin-top: 54px;
    margin-right: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    width: 58px;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont .giveaways-header-sorting {
    margin-top: 40px;
    min-width: 313px;
    gap: 4px;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont .giveaways-header-sorting .leader-board-header {
    gap: 4px;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont .giveaways-header-sorting .leader-board-header .leader-board-header-tab {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    font-family: "Rubik";
    font-style: normal;
  }
  .width-container .body-event-giveaways {
    height: 294px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .width-container .body-event-giveaways .onclick-joined {
    position: absolute;
    z-index: 1;
    width: calc( 100vw - 30px );
    height: 294px;
    left: 15px;
  }
  .width-container .body-event-giveaways .event-left-giveaways {
    margin-top: 16px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50vw;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways {
    color: var(--subscribe-text-description);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .timer-event-giveaways .time-color-one {
    font-size: 20px;
    line-height: 30px;
    color: var(--green);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .timer-event-giveaways .time-color-two {
    color: var(--subscribe-text-description);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .prize-value-giveaways {
    left: 0px;
    top: calc( 78px - 16px );
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .prize-value-giveaways .sum-prize {
    font-size: 20px;
    line-height: 30px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways {
    left: 0px;
    top: calc( 140px - 16px );
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways .cur-joined {
    font-size: 20px;
    line-height: 30px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont {
    display: flex;
    flex-direction: row;
    width: 400px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways {
    display: flex;
    flex-direction: row;
    margin-bottom: 22px;
    z-index: 2;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways {
    width: 83px;
    height: 40px;
    border-radius: 8px;
    padding: 9px 24px;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--green);
    bottom: 0px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways:hover {
    background-color: var(--green-btn-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete {
    display: flex;
    flex-direction: row;
    position: relative;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body {
    width: 117px;
    height: 40px;
    background-color: var(--cancel-btn);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body .buttons-event-giveaways-complete-icon {
    margin-right: 11.44px;
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body .buttons-event-giveaways-complete-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled {
    opacity: 0.3;
    background-color: var(--cancel-btn);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled .buttons-event-giveaways-complete-icon {
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled .buttons-event-giveaways-complete-text {
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways {
    width: 40px;
    height: 40px;
    background-color: var(--cancel-btn);
    justify-content: center;
    align-items: center;
    padding: 9px;
    gap: 10px;
    border-radius: 8px;
    margin-left: 8px;
    margin-right: 24px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways:hover {
    background-color: var(--button-share-giveaways-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways:hover .icon-share-giveaways {
    color: var(--button-share-icon-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .icon-share-giveaways {
    width: 22px;
    height: 22px;
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .icon-share-giveaways:hover {
    color: var(--button-share-icon-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont {
    display: flex;
    left: 195px;
    flex-direction: column;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont .text-need-to-join {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--subscribe-text-description);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont .tickets-value {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--green);
  }
  .width-container .body-event-giveaways .event-right-giveaways {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    height: 210px;
    left: 206px;
    width: calc( 100vw - 55px - 206px);
    padding: 0px 9px 0px 0px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .border-stick {
    border: 0.5px solid var(--stick-giveaways);
    opacity: 0.3;
    margin-left: 12px;
    margin-right: 12px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes .image-prizes {
    min-height: 20px;
    max-height: 85px;
    margin-bottom: 16px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes .title-of-item {
    display: flex;
    color: var(--text-description);
    letter-spacing: 0.15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes .price-item {
    color: var(--orange);
    letter-spacing: 0.15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .width-container .stick-cont {
    position: absolute;
    width: 100vw;
  }
  .width-container .stick-cont .border-stick-down {
    position: relative;
    top: 210px;
    border: 0.5px solid var(--stick-giveaways);
    opacity: 0.3;
    width: calc( 100vw - 30px);
    height: 0.5px;
    left: -24px;
  }
  .body-no-giveaways {
    width: 738px;
    height: 294px;
    border-radius: 8px;
  }
  .body-no-giveaways .img-no-giveaways {
    margin-top: 77px;
  }
  .body-no-giveaways .text-no-giveaways {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 77px;
  }
  .back-btn-joined {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 25px;
    margin-bottom: 25px;
    cursor: pointer;
  }
  .back-btn-joined svg {
    fill: var(--subscribe-text-description);
    margin-right: 10px;
  }
  .back-btn-joined .text-back-btn-joined {
    color: var(--subscribe-text-description);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
  .back-btn-joined:hover svg {
    fill: var(--address-title);
  }
  .back-btn-joined:hover .text-back-btn-joined {
    color: var(--address-title);
  }
  .joined-users-title-cont {
    margin-bottom: 24px;
    margin-top: 40px;
  }
  .joined-users-title-cont .joined-users-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: var(--section-message-text);
  }
  .joined-users-cont {
    margin-bottom: 120px;
  }
  .joined-users-cont .joined-zero-conteiner {
    width: 738px;
    height: 300px;
  }
  .joined-users-cont .joined-zero-conteiner .img-lightning {
    margin-top: 48px;
    margin-bottom: 24px;
  }
  .joined-users-cont .joined-zero-conteiner .text-joined-zero {
    font-size: 28px;
    line-height: 36px;
  }
  .joined-users-cont .joined-zero-conteiner .button-join-giveaways {
    margin-bottom: 48px;
  }
  .users-joined-cont .square-user {
    width: 156px;
    height: 128px;
    border-radius: 8px;
  }
  .users-joined-cont .square-user .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 22px;
  }
  .users-joined-cont .square-user .user-info .user-nick {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: var(--section-message-text);
  }
  .header-steam-link-modal {
    align-items: center;
  }
  .header-steam-link-modal .text-header-steam {
    font-size: 14px;
    line-height: 20px;
  }
  .steam-link-modal-info .title-steam-link-modal {
    font-size: 20px;
    line-height: 30px;
  }
  .header-requirement-modal .title-header-requirement-modal {
    font-size: 14px;
    line-height: 20px;
  }
  .requirement-modal-cont .requirement-modal-info .title-requirement-modal {
    font-size: 20px;
    line-height: 30px;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .title-requirement-no-tickets-modal {
    font-size: 20px;
    line-height: 30px;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner {
    width: 452px;
    height: 138px;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner .requirement-modal-no-tickets-first-yarus span {
    font-size: 14px;
    line-height: 20px;
  }
  .page-switch-cont {
    margin-top: 56px;
  }
  .page-switch-cont .arr-btn-page-switch-cont {
    margin-bottom: 120px;
  }
}
@media  ( max-width: 450px )  {
  .width-container .header-giveaways-container {
    margin-bottom: 32px;
  }
  .width-container .header-giveaways-container .header-giveaways {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .width-container .header-giveaways-container .header-giveaways .giveaways-page-title {
    margin-top: 32px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont .text-sortyby {
    margin-top: 16px;
    margin-right: 0px;
    width: 58px;
  }
  .width-container .header-giveaways-container .header-giveaways .sort-cont .giveaways-header-sorting {
    margin-top: 2px;
  }
  .width-container .body-event-giveaways {
    height: 407px;
    padding: 0px 0px 0px 0px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
  }
  .width-container .body-event-giveaways .onclick-joined {
    position: absolute;
    z-index: 1;
    width: calc( 100vw - 30px );
    height: 407px;
    left: 0px;
  }
  .width-container .body-event-giveaways .event-left-giveaways {
    width: 50vw;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways {
    display: flex;
    flex-direction: column;
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-left: 24px;
    line-height: 24px;
    color: var(--subscribe-text-description);
    width: 200px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .timer-event-giveaways .time-color-one {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--green);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .timer-event-giveaways .time-color-two {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15px;
    color: var(--subscribe-text-description);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-is-being-verified {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px 2px 8px;
    gap: 4px;
    background: rgba(238, 183, 134, 0.2);
    border-radius: 40px;
    margin-top: 0px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-is-being-verified .winner-text {
    font-family: "Rubik";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--orange);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-is-being-verified .winner-icon {
    width: 16px;
    height: 16px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    background: rgba(168, 208, 153, 0.2);
    border-radius: 40px;
    margin-top: 0px;
    position: relative;
    z-index: 4;
    cursor: pointer;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-event-text {
    color: var(--text-winner-giveaways);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-cont .avatar-winner {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    overflow: hidden;
    margin-left: 4px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-cont .nickname-winner {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 4px;
    color: var(--text-winner-giveaways);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event:hover .nickname-winner {
    color: var(--green);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .prize-value-giveaways {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 24px;
    top: 60px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .prize-value-giveaways .text-prize-value {
    font-style: normal;
    font-weight: 500;
    width: 89px;
    font-size: 14px;
    line-height: 24px;
    color: var(--subscribe-text-description);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .prize-value-giveaways .sum-prize {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--orange);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: calc( 100vw - 30px - 52px - 24px);
    width: 52px;
    top: 0px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways .text-joined-value {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--subscribe-text-description);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways .cur-joined {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--text-description);
    display: flex;
    justify-content: flex-end;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    top: 265px;
    left: 24px;
    height: 105px;
    width: calc( 100vw - 30px - 48px);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px;
    position: relative;
    width: calc( 100vw - 30px - 48px);
    z-index: 2;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways {
    width: calc( 100vw - 30px - 48px - 40px - 16px);
    min-width: 83px;
    height: 40px;
    border-radius: 8px;
    padding: 9px 24px;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--green);
    bottom: 0px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways:hover {
    background-color: var(--green-btn-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete {
    display: flex;
    flex-direction: row;
    position: relative;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body {
    width: calc( 100vw - 30px - 48px - 40px - 16px);
    min-width: 83px;
    height: 40px;
    background-color: var(--cancel-btn);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body .buttons-event-giveaways-complete-icon {
    width: 15px;
    height: 12px;
    margin-right: 11.44px;
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body .buttons-event-giveaways-complete-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled {
    opacity: 0.3;
    background-color: var(--cancel-btn);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled .buttons-event-giveaways-complete-icon {
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled .buttons-event-giveaways-complete-text {
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways {
    width: 40px;
    height: 40px;
    background-color: var(--cancel-btn);
    justify-content: center;
    align-items: center;
    padding: 9px;
    gap: 10px;
    border-radius: 8px;
    margin-left: 16px;
    margin-right: 0px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways:hover {
    background-color: var(--button-share-giveaways-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways:hover .icon-share-giveaways {
    color: var(--button-share-icon-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .icon-share-giveaways {
    width: 22px;
    height: 22px;
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .icon-share-giveaways:hover {
    color: var(--button-share-icon-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont {
    display: flex;
    flex-direction: column;
    margin-bottom: 21px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont .text-need-to-join {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--subscribe-text-description);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont .tickets-value {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--green);
  }
  .width-container .body-event-giveaways .event-right-giveaways {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 143px;
    left: 0px;
    height: 122px;
    width: calc( 100vw - 30px );
    padding: 0px 9px 0px 0px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .border-stick {
    border: 0.5px solid var(--stick-giveaways);
    opacity: 0.3;
    margin-left: 0px;
    margin-right: 12px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .border-stick:first-child {
    border: none;
    opacity: 0.3;
    margin-left: 0px;
    margin-right: 12px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes .image-prizes {
    max-height: 50px;
    margin-bottom: 12px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes .title-of-item {
    color: var(--text-description);
    letter-spacing: 0.15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes .price-item {
    color: var(--orange);
    letter-spacing: 0.15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .width-container .body-event-giveaways .stick-cont {
    position: absolute;
    width: 100vw;
  }
  .width-container .body-event-giveaways .stick-cont .border-stick-down {
    position: relative;
    top: 143px;
    border: 0.5px solid var(--stick-giveaways);
    opacity: 0.3;
    width: calc( 100vw - 30px);
    height: 0.5px;
    left: 0px;
  }
  .width-container .body-event-giveaways .stick-cont .border-stick-down2 {
    position: relative;
    top: 263px;
    border: 0.5px solid var(--stick-giveaways);
    opacity: 0.3;
    width: calc( 100vw - 30px);
    height: 0.5px;
    left: 0px;
  }
  .body-no-giveaways {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc( 100vw - 30px );
    height: 212px;
    border-radius: 14px;
    background: var(--levels-main-info-container-bg);
    border: 1px solid rgba(38, 138, 0, 0.2);
    box-shadow: 0px 44px 140px -20px rgba(0, 0, 0, 0.1);
    margin-bottom: 120px;
  }
  .body-no-giveaways .img-no-giveaways {
    height: 65px;
    margin-top: 40px;
    margin-bottom: 16px;
  }
  .body-no-giveaways .text-no-giveaways {
    display: flex;
    font-style: normal;
    margin-left: 40px;
    margin-right: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: var(--section-message-text);
    margin-bottom: 40px;
  }
  .back-btn-joined {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 25px;
    margin-bottom: 25px;
    cursor: pointer;
  }
  .back-btn-joined svg {
    fill: var(--subscribe-text-description);
    margin-right: 10px;
  }
  .back-btn-joined .text-back-btn-joined {
    color: var(--subscribe-text-description);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
  .back-btn-joined:hover svg {
    fill: var(--address-title);
  }
  .back-btn-joined:hover .text-back-btn-joined {
    color: var(--address-title);
  }
  .joined-users-title-cont {
    margin-bottom: 24px;
    margin-top: 32px;
  }
  .joined-users-title-cont .joined-users-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: var(--section-message-text);
  }
  .joined-users-cont .joined-zero-conteiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc( 100vw - 30px );
    height: 300px;
    border-radius: 8px;
    background-color: var(--header-background);
  }
  .joined-users-cont .joined-zero-conteiner .img-lightning {
    margin-top: 38px;
    margin-bottom: 24px;
    height: 80px;
  }
  .joined-users-cont .joined-zero-conteiner .text-joined-zero {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 24px;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
    color: var(--section-message-text);
  }
  .joined-users-cont .joined-zero-conteiner .button-join-giveaways {
    width: 209px;
    height: 40px;
    border-radius: 8px;
    padding: 9px 24px;
    gap: 10px;
    background-color: var(--green);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 38px;
  }
  .joined-users-cont .joined-zero-conteiner .button-join-giveaways p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }
  .joined-users-cont .joined-zero-conteiner .button-join-giveaways:hover {
    background-color: var(--green-btn-hover);
  }
  .users-joined-cont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .users-joined-cont .square-user {
    display: flex;
    flex-direction: column;
    width: 160px;
    height: 84px;
    border-radius: 8px;
    padding: 0px 0px 16px 0px;
    background-color: var(--header-background);
  }
  .users-joined-cont .square-user .you-or-no {
    margin-left: 4px;
    margin-top: 4px;
    width: 41px;
    height: 24px;
    padding: 0px 6px;
    position: absolute;
    background-color: var(--red);
    border-radius: 4px;
  }
  .users-joined-cont .square-user .you-or-no .text-you-or-no {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
  }
  .users-joined-cont .square-user .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
  }
  .users-joined-cont .square-user .user-info .user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 8px;
  }
  .users-joined-cont .square-user .user-info .user-nick {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: var(--section-message-text);
  }
  .users-joined-cont .square-user:hover .user-info .user-nick {
    color: var(--green);
  }
  ion-modal.premium-modal.modal-steam-link {
    --width: calc( 100vw - 24px);
    --height: auto;
    --border-radius: 8px;
    --background: var(--modal-body-standart-bg);
  }
  .header-steam-link-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 52px;
    width: inherit;
    background-color: var(--modal-header-bg);
    padding: 14px 20px;
  }
  .header-steam-link-modal .text-header-steam {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: var(--section-message-text);
  }
  .steam-link-modal-info {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 16px;
  }
  .steam-link-modal-info .img-steam-link-modal {
    margin-bottom: 24px;
    height: 80px;
  }
  .steam-link-modal-info .title-steam-link-modal {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--section-message-text);
    text-align: center;
  }
  .steam-link-modal-info .text-steam-link-modal {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--subscribe-text-description);
  }
  .steam-link-modal-info .steam-link-input-cont {
    margin-top: 24px;
  }
  .steam-link-modal-info .steam-link-input-cont .text-upper-input-cont .text-upper-input {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--subscribe-text-description);
  }
  .steam-link-modal-info .steam-link-input-cont .input-steam-link {
    margin-top: 6px;
    width: calc( 100vw - 32px - 24px );
    height: 50px;
    padding: 14px;
    border: 1px solid #61696b;
    box-shadow: 0 0 0 1px var(--input-border);
    gap: 10px;
    border-radius: 8px;
    background-color: var(--subscribe-input-bg);
  }
  .steam-link-modal-info .steam-link-input-cont .input-steam-link:focus {
    outline: 1px solid #268a00;
    border: none;
  }
  .steam-link-modal-info .steam-link-input-cont .input-steam-link:hover {
    outline: 1px solid #268a00;
    border: none;
  }
  .steam-link-modal-info .btn-steam-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc( 100vw - 32px - 24px );
    height: 50px;
    margin-top: 24px;
    background-color: var(--green);
    padding: 14px 30px;
    border-radius: 8px;
    margin-bottom: 24px;
  }
  .steam-link-modal-info .btn-steam-link .text-in-btn-steam-link {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }
  .steam-link-modal-info .btn-steam-link:disabled {
    opacity: 0.3;
  }
  .steam-link-modal-info .btn-steam-link:hover {
    background-color: var(--green-btn-hover);
  }
  ion-modal.premium-modal.modal-requirement {
    --width: calc( 100vw - 24px);
    --height: auto;
    --border-radius: 8px;
    --background: var(--modal-body-standart-bg);
  }
  .header-requirement-modal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 52px;
    width: inherit;
    padding: 14px 20px;
    gap: 10px;
    background-color: var(--modal-header-bg);
  }
  .header-requirement-modal .title-header-requirement-modal {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: var(--section-message-text);
  }
  .requirement-modal-cont {
    width: inherit;
    height: auto;
    padding: 0px 16px;
  }
  .requirement-modal-cont .requirement-modal-info {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .requirement-modal-cont .requirement-modal-info .img-requirement-modal {
    height: 80px;
  }
  .requirement-modal-cont .requirement-modal-info .title-requirement-modal {
    margin-top: 24px;
    font-weight: 500;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    color: var(--section-message-text);
  }
  .requirement-modal-cont .requirement-modal-info .text-requirement-modal {
    margin-top: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--subscribe-text-description);
  }
  .requirement-modal-cont .requirement-modal-info .button-requirement-modal {
    margin-top: 16px;
    width: 95px;
    height: 50px;
    padding: 14px 30px;
    background-color: var(--green);
    border-radius: 8px;
    margin-bottom: 32px;
  }
  .requirement-modal-cont .requirement-modal-info .button-requirement-modal .text-btn-requirement {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }
  .requirement-modal-cont .requirement-modal-info .button-requirement-modal:hover {
    background-color: var(--green-btn-hover);
  }
  .requirement-modal-no-tickets-cont {
    width: inherit;
    height: auto;
    padding: 0px 16px;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .img-requirement-modal {
    height: 80px;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .title-requirement-no-tickets-modal {
    margin-top: 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--section-message-text);
    text-align: center;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    width: calc( 100vw - 24px - 32px);
    height: 142px;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--modal-header-bg);
    margin-bottom: 24px;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner .requirement-modal-no-tickets-first-yarus {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner .requirement-modal-no-tickets-first-yarus span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: var(--section-message-text);
    text-align: center;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner .requirement-modal-no-tickets-second-yarus {
    margin-top: 16px;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner .requirement-modal-no-tickets-second-yarus button {
    width: 149px;
    height: 50px;
    border-radius: 8px;
    padding: 14px 30px 14px 30px;
    background-color: var(--green);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }
  .requirement-modal-no-tickets-cont .requirement-modal-no-tickets-info .requirement-modal-no-tickets-conteiner .requirement-modal-no-tickets-second-yarus button:hover {
    background-color: var(--green-btn-hover);
  }
  ion-toast.toast-joined {
    --background: var(--header-background);
    --border-radius: 8px;
    padding: 16px;
    gap: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    --width: 352px;
    color: var(--section-message-text);
    --height: 118px;
  }
  .green {
    color: var(--green);
  }
  ion-toast.toast-joined::part(header) {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15px;
    margin-bottom: 4px;
  }
  ion-toast.toast-joined::part(message) {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  ion-toast.toast-joined:font {
    color: #268a00;
  }
  ion-toast.toast-joined::part(container) {
    align-items: normal;
  }
  ion-toast.toast-joined::part(icon) {
    margin-top: 16px;
  }
  ion-toast.toast-joined::part(button) {
    margin-top: 6px;
  }
  .page-switch-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 46px;
  }
  .page-switch-cont .page-swicth-show-more {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc( 100vw - 32px );
    height: 50px;
    padding: 13px 20px;
    border-radius: 8px;
    background-color: var(--green);
    margin-bottom: 24px;
  }
  .page-switch-cont .page-swicth-show-more .el-in-btn-show-more {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .page-switch-cont .page-swicth-show-more .el-in-btn-show-more .icon-btn-show-more {
    font-size: 150%;
    margin-right: 13px;
    fill: #fff;
  }
  .page-switch-cont .page-swicth-show-more .el-in-btn-show-more .text-btn-show-more {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }
  .page-switch-cont .page-swicth-show-more:hover {
    background-color: var(--green-btn-hover);
  }
  .page-switch-cont .arr-btn-page-switch-cont {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;
  }
  .page-switch-cont .arr-btn-page-switch-cont .ellipsis {
    order: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: var(--page-switch-bg);
  }
  .page-switch-cont .arr-btn-page-switch-cont .ellipsis span {
    color: var(--section-message-text);
  }
  .page-switch-cont .arr-btn-page-switch-cont .ellipsis.last-page {
    order: 1;
    margin-left: 8px;
  }
  .page-switch-cont .arr-btn-page-switch-cont .ellipsis.first-page {
    margin-left: 8px;
  }
  .page-switch-cont .arr-btn-page-switch-cont .focus-page-switch.current .arr-btn-page-switch {
    background-color: var(--green);
  }
  .page-switch-cont .arr-btn-page-switch-cont .focus-page-switch.current .arr-btn-page-switch .arr-btn-text {
    color: var(--text-focus-switch);
  }
  .page-switch-cont .arr-btn-page-switch-cont .focus-page-switch.current .arr-btn-page-switch:hover {
    background-color: var(--green-btn-hover);
  }
  .page-switch-cont .arr-btn-page-switch-cont .focus-page-switch.current .arr-btn-page-switch:hover .arr-btn-text {
    color: var(--text-focus-switch);
  }
  .page-switch-cont .arr-btn-page-switch-cont .focus-page-switch .ellipsis-cont {
    display: flex;
    flex-direction: row;
  }
  .page-switch-cont .arr-btn-page-switch-cont .focus-page-switch .arr-btn-page-switch {
    margin-left: 8px;
    order: 1;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: var(--page-switch-bg);
  }
  .page-switch-cont .arr-btn-page-switch-cont .focus-page-switch .arr-btn-page-switch .arr-btn-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: var(--section-message-text);
  }
  .page-switch-cont .arr-btn-page-switch-cont .focus-page-switch .arr-btn-page-switch:hover .arr-btn-text {
    color: var(--green);
  }
  .page-switch-cont .arr-btn-page-switch-cont .focus-page-switch .arr-btn-page-switch.last-page {
    order: 2;
  }
}
@media ( max-width: 339px) {
  .width-container .body-event-giveaways .onclick-joined {
    width: calc( 100vw - 30px);
    left: 0px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways {
    display: flex;
    flex-direction: column;
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-left: 24px;
    line-height: 24px;
    color: var(--subscribe-text-description);
    width: 200px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .timer-event-giveaways {
    width: 150px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .timer-event-giveaways .time-color-one {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--green);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .timer-event-giveaways .time-color-two {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15px;
    color: var(--subscribe-text-description);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    background: rgba(168, 208, 153, 0.2);
    border-radius: 40px;
    margin-top: 0px;
    position: relative;
    z-index: 4;
    width: max-content;
    cursor: pointer;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-event-text {
    color: var(--text-winner-giveaways);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-cont .avatar-winner {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    overflow: hidden;
    margin-left: 4px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .event-time-giveaways .winner-event .winner-cont .nickname-winner {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 4px;
    color: var(--text-winner-giveaways);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: calc( 100vw - 30px - 52px - 24px);
    width: 52px;
    top: 60px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways .text-joined-value {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--subscribe-text-description);
  }
  .width-container .body-event-giveaways .event-left-giveaways .event-info-giveaways .joined-value-giveaways .cur-joined {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--text-description);
    display: flex;
    justify-content: flex-end;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    top: 265px;
    left: 24px;
    height: 105px;
    width: calc( 100vw - 30px - 48px);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px;
    position: relative;
    width: calc( 100vw - 30px - 48px);
    z-index: 2;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways {
    width: calc( 100vw - 30px - 48px - 40px - 16px);
    min-width: 83px;
    height: 40px;
    border-radius: 8px;
    padding: 9px 24px;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--green);
    bottom: 0px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways:hover {
    background-color: var(--green-btn-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-join-giveaways p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete {
    display: flex;
    flex-direction: row;
    position: relative;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body {
    width: calc( 100vw - 30px - 48px - 40px - 16px);
    min-width: 83px;
    height: 40px;
    background-color: var(--cancel-btn);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body .buttons-event-giveaways-complete-icon {
    width: 15px;
    height: 12px;
    margin-right: 11.44px;
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body .buttons-event-giveaways-complete-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled {
    opacity: 0.3;
    background-color: var(--cancel-btn);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled .buttons-event-giveaways-complete-icon {
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .buttons-event-giveaways-complete .buttons-event-giveaways-complete-body:disabled .buttons-event-giveaways-complete-text {
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways {
    width: 40px;
    height: 40px;
    background-color: var(--cancel-btn);
    justify-content: center;
    align-items: center;
    padding: 9px;
    gap: 10px;
    border-radius: 8px;
    margin-left: 16px;
    margin-right: 0px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways:hover {
    background-color: var(--button-share-giveaways-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .button-share-giveaways:hover .icon-share-giveaways {
    color: var(--button-share-icon-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .icon-share-giveaways {
    width: 22px;
    height: 22px;
    color: var(--button-share-icon);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .buttons-event-giveaways .icon-share-giveaways:hover {
    color: var(--button-share-icon-hover);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont {
    display: flex;
    flex-direction: column;
    margin-bottom: 21px;
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont .text-need-to-join {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--subscribe-text-description);
  }
  .width-container .body-event-giveaways .event-left-giveaways .buttons-and-need-to-join-cont .need-to-join-cont .tickets-value {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--green);
  }
  .width-container .body-event-giveaways .event-right-giveaways {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 143px;
    left: 0px;
    height: 122px;
    width: calc( 100vw - 30px );
    padding: 0px 9px 0px 0px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .border-stick {
    border: 0.5px solid var(--stick-giveaways);
    opacity: 0.3;
    margin-left: 0px;
    margin-right: 12px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .border-stick:first-child {
    border: none;
    opacity: 0.3;
    margin-left: 0px;
    margin-right: 12px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes .image-prizes {
    max-height: 50px;
    margin-bottom: 12px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes .title-of-item {
    color: var(--text-description);
    letter-spacing: 0.15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .width-container .body-event-giveaways .event-right-giveaways .show-prizes .price-item {
    color: var(--orange);
    letter-spacing: 0.15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .width-container .body-event-giveaways .stick-cont {
    position: absolute;
    width: 100vw;
  }
  .width-container .body-event-giveaways .stick-cont .border-stick-down {
    position: relative;
    top: 143px;
    border: 0.5px solid var(--stick-giveaways);
    opacity: 0.3;
    width: calc( 100vw - 30px);
    height: 0.5px;
    left: 0px;
  }
  .width-container .body-event-giveaways .stick-cont .border-stick-down2 {
    position: relative;
    top: 263px;
    border: 0.5px solid var(--stick-giveaways);
    opacity: 0.3;
    width: calc( 100vw - 30px);
    height: 0.5px;
    left: 0px;
  }
}
