/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --tableWidth:768px;
  --grey: #B7C5C1;
  --blue: #3975EA;
  --discord: #5865F2;
  --twitter: #2CAAE1;
  --telegram: #53A6E4;
  --instagram: #D53862;
  --green: #268A00;
  --orange: #DD6F0C;
  --red: #EB5757;
  --footer: #F2F2F2;
  --cancel-btn:#D7DEDC;
  --footer-top: #F9F9F9;
  --footer-links-color: #77878A;
  --footer-text:#B7C5C1;
  --footer-border: rgba(183,197,193,.2);
  --header-background: #FFFFFF;
  --header-box-shadow: 0px 4px 30px rgba(75, 90, 81, 0.25);
  --font-default: #1C2D31;
  --modal-bg: #FFFFFF;
  --text-winner-giveaways: #61696B;
  --main-bg: url(../images/bg.png);
  --balance-btn-bg: #F1F3F3;
  --banner-dot:#B2C0B2;
  --page-switch-bg:#F2F2F2;
  --dot-box-shadow: 0px 1px 6px rgba(127, 244, 174, 0.75);
  --pr-card-bg:#FFFFFF;
  --pr-card-br: #EBECED;
  --pr-card-bsh: 0px 4px 30px rgba(75, 90, 81, 0.25);
  --pr-checklist: #6E798C;
  --fav-bg: rgba(255, 255, 255, 0.3);
  --modal-close: #F7F7F7;
  --button-share-giveaways-hover: #D7DEDC;
  --button-share-icon: #61696B;
  --button-share-icon-hover: #268A00;
  --text-focus-switch: #FFFFFF;
  --modal-close-cross: #C1C1C1;
  --modal-close-circle: rgba(255, 255, 255, 0.8);
  --modal-auth-close-circle: #F2F2F2;
  --bg-event-giveaways: linear-gradient(110.45deg, #FFFFFF 34.87%, #E1FFDC 100%);
  --modal-grey: #8B9A96;
  --user-btn-bg: #F1F4F1;
  --prov-img: #B7C5C1;
  --modal-label: #8B9A96;
  --input-checked: url(../images/checked.svg);
  --select-downdrop: url(../images/downdrop-light.svg);
  --input-placeholder: rgba(28, 45, 49, .3);
  --modal-description: #b1b1b1;
  --cancel-btn-bg: #F6F6F6;
  --user-image-bg: #F1F3F3;
  --user-image-br: #D3D9D8;
  --user-image-svg: url(../images/user-image-light.svg);
  --warning-message-bg: #FDEEEE;
  --password-eye-opened: url(../images/eye-opened.svg);
  --password-eye-closed: url(../images/eye-closed.svg);
  --account-table-br: #E3E5E5;
  --sort-btn: #C6CACC;
  --action-btn-bg: #F1F3F3;
  --ion-checkbox-bg: #FFFFFF;
  --address-title: #61696B;
  --account-black: #000000;
  --deposites-bg: #F9FAF9;
  --cab-img: url(../images/account-tabs/cab-light.png);
  --contact-img: url(../images/account-tabs/contact-light.png);
  --games-img: url(../images/account-tabs/games-light.png);
  --multiple-img: url(../images/account-tabs/multiple-light.png);
  --verify-img: url(../images/account-tabs/verify-light.png);
  --close-img: url(../images/account-tabs/close-light.png);
  --deposit-img: url(../images/balance-tabs/deposit-light.png);
  --exchange-img: url(../images/balance-tabs/exchange-light.png);
  --buy-sell-crypto: url(../images/balance-tabs/buy-sell-crypto-light.svg);
  --withdraw-img: url(../images/balance-tabs/withdraw-light.png);
  --personal-img: url(../images/balance-tabs/personal-light.png);
  --loan-img: url(../images/balance-tabs/loan-light.png);
  --funds-img: url(../images/balance-tabs/funds-light.png);
  --arrow-st: url(../images/arrow-static-light.svg);
  --arrow-st-br: #8B9695;
  --pie-br: #F2F2F2;
  --tag-img-opacity: .6;
  --select-hover-bg: #f6faf6;
  --active-shadow: 0px 9px 30px rgba(127, 244, 174, 0.75);
  --active-opacity-bsh: 0px 9px 30px rgba(127, 244, 174, 0.75);
  --buttons-bg: rgba(255, 255, 255, 0.8);
  --table-bg: #F8F9F9;
  --flags-placeholder-color: #bcc1c2;
  --tournament-card-date-label: #61696B;
  --referral-field-color: #DDE2E1;
  --a-overview-img: url(../images/affiliate-tabs/overview-light.png);
  --a-commision-img: url(../images/affiliate-tabs/commision-light.png);
  --a-funds-img: url(../images/affiliate-tabs/funds-light.png);
  --a-reffered-users-img: url(../images/affiliate-tabs/reffered-users-light.png);
  --a-campaigns-img: url(../images/affiliate-tabs/campaigns-light.png);
  --note-container: #F1F3F3;
  --promo-btn-bg: #dbe2e0;
  --fullscreen-btn-bg: #e9eaeb;
  --fullscreen-svg: #7D8488;
  --edit-container-bg: #D2D9D7;
  --edit-active-svg: #268A00;
  --btn-disabled: #a5cc98;
  --premium-vip-container-bg: linear-gradient(262.13deg, #E1FFDC -0.41%, #FFFFFF 56.07%);
  --premium-vip-container-bonus-bg: linear-gradient(92.5deg, #FFFFFF 20.92%, #D0E7D8 98.49%);
  --premium-vip-rank-bg-level-0: radial-gradient(89.24% 70.36% at 50% 50%, #FFFFFF 0%, #FFDFD5 100%);
  --premium-vip-rank-bg-level-1: radial-gradient(89.24% 70.36% at 50% 50%, #FFFFFF 0%, #D3EEF3 100%);
  --premium-vip-rank-bg-level-2: radial-gradient(89.24% 70.36% at 50% 50%, #FFFFFF 0%, #D7E8F4 100%);
  --premium-vip-rank-bg-level-3: radial-gradient(89.24% 70.36% at 50% 50%, #FFFFFF 0%, #FFE0AD 100%);
  --premium-vip-rank-bg-level-4: radial-gradient(89.24% 70.36% at 50% 50%, #FFFFFF 0%, #CDF0FF 100%);
  --premium-vip-rank-bg-level-5: radial-gradient(89.24% 70.36% at 50% 50%, #FFFFFF 0%, #FFCABB 100%);
  --premium-vip-rank-bg-level-6: radial-gradient(89.24% 70.36% at 50% 50%, #FFFFFF 0%, #E3C6FF 100%);
  --premium-vip-rank-shadow-level-0: 0px 0px 44px -15px rgba(0, 0, 0, 0.2);
  --premium-vip-rank-shadow-level-1: 0px 0px 44px -15px rgba(0, 0, 0, 0.2);
  --premium-vip-rank-shadow-level-2: 0px 0px 44px -15px rgba(0, 0, 0, 0.2);
  --premium-vip-rank-shadow-level-3: 0px 0px 44px -15px rgba(0, 0, 0, 0.2);
  --premium-vip-rank-shadow-level-4: 0px 0px 44px -15px rgba(0, 0, 0, 0.2);
  --premium-vip-rank-shadow-level-5: 0px 0px 44px -15px rgba(0, 0, 0, 0.2);
  --premium-vip-rank-shadow-level-6: 0px 0px 44px -15px rgba(0, 0, 0, 0.2);
  --premium-vip-rank-border-level-0: rgba(255, 217, 202, 0.6);
  --premium-vip-rank-border-level-1: rgba(177, 226, 235, 0.6);
  --premium-vip-rank-border-level-2: rgba(176, 206, 232, 0.6);
  --premium-vip-rank-border-level-3: rgba(243, 219, 178, 0.6);
  --premium-vip-rank-border-level-4: rgba(197, 234, 255, 0.6);
  --premium-vip-rank-border-level-5: rgba(251, 186, 166, 0.6);
  --premium-vip-rank-border-level-6: rgba(227, 198, 255, 0.6);
  --premium-vip-rank-text-level-0: #CD8222;
  --premium-vip-rank-text-level-1: #22C3CD;
  --premium-vip-rank-text-level-2: #2389D1;
  --premium-vip-rank-text-level-3: #FFC971;
  --premium-vip-rank-text-level-4: #35B6FF;
  --premium-vip-rank-text-level-5: #FE7758;
  --premium-vip-rank-text-level-6: #964ADE;
  --progress-bar-bg: #F2F6F2;
  --progress-bar-active-bg-level-0: linear-gradient(180deg, #FFE4DB 0%, #FFB294 100%);
  --progress-bar-active-bg-level-1: linear-gradient(180deg, #DAF1F6 0%, #7ECCDC 100%); 
  --progress-bar-active-bg-level-2: linear-gradient(180deg, #DFEDF7 0%, #6E96B0 100%);
  --progress-bar-active-bg-level-3: linear-gradient(180deg, #FFE7BE 0%, #E3B973 100%);
  --progress-bar-active-bg-level-4: linear-gradient(180deg, #D1F2FF 0%, #46B6F5 100%);
  --progress-bar-active-bg-level-5: linear-gradient(180deg, #FFE4DE 0%, #FE7758 100%);
  --premium-vip-progress-center-bg-level-0: rgba(205, 130, 34, 0.1);
  --premium-vip-progress-center-bg-level-1: rgba(34, 195, 205, 0.1); 
  --premium-vip-progress-center-bg-level-2: rgba(6, 89, 165, 0.1);
  --premium-vip-progress-center-bg-level-3: rgba(203, 107, 9, 0.1);
  --premium-vip-progress-center-bg-level-4: rgba(53, 182, 255, 0.1);
  --premium-vip-progress-center-bg-level-5: rgba(254, 119, 88, 0.1);
  --premium-vip-progress-center-bg-level-6: rgba(150, 74, 222, 0.1);
  --premium-vip-modal-bg-level-0: radial-gradient(83.93% 68.52% at 50% 0%, #FFD2C2 0%, #FFFFFF 100%);
  --premium-vip-modal-bg-level-1: radial-gradient(83.93% 68.52% at 50% 0%, #D3EEF3 0%, #FFFFFF 100%);
  --premium-vip-modal-bg-level-2: radial-gradient(83.93% 68.52% at 50% 0%, #B5DBF7 0%, #FFFFFF 100%);
  --premium-vip-modal-bg-level-3: radial-gradient(83.93% 68.52% at 50% 0%, #FFD795 0%, #FFFFFF 100%); 
  --premium-vip-modal-bg-level-4: radial-gradient(83.93% 68.52% at 50% 0%, #BAEAFF 0%, #FFFFFF 100%);
  --premium-vip-modal-bg-level-5: radial-gradient(83.93% 68.52% at 50% 0%, #FFB6A1 0%, #FFFFFF 100%);
  --premium-vip-modal-bg-level-6: radial-gradient(83.93% 68.52% at 50% 0%, #E3C6FF 0%, #FFFFFF 100%);
  --player-modal-bg-level-0: radial-gradient(45.23% 100% at 100% 0%, #FFD2C2 0%, #FFFFFF 100%);
  --player-modal-bg-level-1: radial-gradient(45.23% 100% at 100% 0%, #D3EEF3 0%, #FFFFFF 100%);
  --player-modal-bg-level-2: radial-gradient(45.23% 100% at 100% 0%, #B5DBF7 0%, #FFFFFF 100%);
  --player-modal-bg-level-3: radial-gradient(45.23% 100% at 100% 0%, #FFD795 0%, #FFFFFF 100%); 
  --player-modal-bg-level-4: radial-gradient(45.23% 100% at 100% 0%, #BAEAFF 0%, #FFFFFF 100%);
  --player-modal-bg-level-5: radial-gradient(45.23% 100% at 100% 0%, #FFB6A1 0%, #FFFFFF 100%);
  --player-modal-bg-level-6: radial-gradient(45.23% 100% at 100% 0%, #E3C6FF 0%, #FFFFFF 100%);
  --player-info-pic-ellipse-mobile: rgba(19, 26, 28, 0.05);
  --player-modal-mobile-bg-level-0: radial-gradient(100.17% 86.38% at 100% 99.95%, #FFD2C2 0%, #FFFFFF 100%);
  --player-modal-mobile-bg-level-1: radial-gradient(100.17% 86.38% at 100% 99.95%, #D3EEF3 0%, #FFFFFF 100%);
  --player-modal-mobile-bg-level-2: radial-gradient(100.17% 86.38% at 100% 99.95%, #B5DBF7 0%, #FFFFFF 100%);
  --player-modal-mobile-bg-level-3: radial-gradient(100.17% 86.38% at 100% 99.95%, #FFD795 0%, #FFFFFF 100%);
  --player-modal-mobile-bg-level-4: radial-gradient(100.17% 86.38% at 100% 99.95%, #BAEAFF 0%, #FFFFFF 100%);
  --player-modal-mobile-bg-level-5: radial-gradient(100.17% 86.38% at 100% 99.95%, #FFB6A1 0%, #FFFFFF 100%);
  --player-modal-mobile-bg-level-6: radial-gradient(100.17% 86.38% at 100% 99.95%, #E3C6FF 0%, #FFFFFF 100%);
  --chat-player-bg-level-0: radial-gradient(62.5% 97.22% at 50% 50%, #FFFFFF 0%, #FFD2C2 100%);
  --chat-player-bg-level-1: radial-gradient(62.5% 97.22% at 50% 50%, #FFFFFF 0%, #D3EEF3 100%);
  --chat-player-bg-level-2: radial-gradient(62.5% 97.22% at 50% 50%, #FFFFFF 0%, #B5DBF7 100%);
  --chat-player-bg-level-3: radial-gradient(62.5% 97.22% at 50% 50%, #FFFFFF 0%, #FFD795 100%);
  --chat-player-bg-level-4: radial-gradient(62.5% 97.22% at 50% 50%, #FFFFFF 0%, #BAEAFF 100%);
  --chat-player-bg-level-5: radial-gradient(62.5% 97.22% at 50% 50%, #FFFFFF 0%, #FFB6A1 100%);
  --chat-player-bg-level-6: radial-gradient(62.5% 97.22% at 50% 50%, #FFFFFF 0%, #E3C6FF 100%);
  --campaign-header: #E4EBEB;
  --asterisk-color: #FF3317;
  --close-cr-modal-bg: #F6F6F6;
  --progress-badge-bg: #B7C5C1;
  --progress-badge-text-color: #1C2D31;
  --not-available-bg: #EB5757;
  --tag-img-fill: #FFFFFF;
  --tag-img-stroke: #7F9398;
  --stick-giveaways: #CACACA;
  --golden-text: #FFB614;
  --bonus-popover-bg: #FFFFFF;
  --ios-setup-content-bg: #FFFFFF;
  --dpopdown-img-opacity: 1;
  --green-btn-hover: #67AD4D;
  --green-btn-hover-opacity: #67ad4d94;
  --orange-btn-hover: #E48C3D;
  --cancel-btn-hover: #D7DEDC;
  --red-btn-hover: #EF7979;
  --socials-container: #A8D099;
  --social-color: #ffffff;
  --text-description: #61696B;
  --subscribe-container-bg: linear-gradient(180deg, #EBFFE8 0%, #FFFFFF 100%);
  --subscribe-text-description: #CACACA;
  --subscribe-input-bg: #ffffff;
  --section-message-bg: rgba(221, 111, 12, 0.1);
  --section-message-text: #61696B;
  --share-rainbow-text: linear-gradient(90deg, #1BAD29 27.8%, #7AAEDF 50.84%, #4951FF 71.6%);
  --premium-vip-progress-top: #424F54;
  --modal-header-bg: #F2F6F2;
  --modal-body-bg: linear-gradient(0.58deg, #FFFFFF 34.71%, #E1FFDC 100%);
  --modal-body-mobile-bg: linear-gradient(90deg, #FFFFFF 31.38%, #E1FFDC 100%);;
  --modal-body-shadow: none;
  --modal-body-bg-second: linear-gradient(240deg, #FFFFFF 34.87%, #E1FFDC 100%);
  --modal-body-mobile-bg-second: linear-gradient(45deg, #FFFFFF 31.38%, #E1FFDC 100%);
  --modal-body-standart-bg: #ffffff;
  --leader-board-shadow: 0px 44px 140px -20px rgba(0, 0, 0, 0.1);
  --btn-text-modal: #FFFFFF;
  --red-btn-hover-opaciti: #ef797980;
  --leader-board-border: #CACACA;
  --modal-gradient-text-opacity: .2;
  --modal-gradient-text-mobile-opacity: .2;
  --levels-main-info-container-bg: linear-gradient(0.58deg, #FFFFFF 34.71%, #E1FFDC 100%);
  --green-border: rgba(38, 138, 0, 0.2);
  --arrow-hover: #268A00;
  --levels-carousel-gradient: radial-gradient(50% 50% at 50% 50%, rgba(0, 181, 116, 0.6) 0%, rgba(242, 246, 242, 0) 100%);
  --subcategories-filter-select-border: #CACACA;
  --subcategories-item-bg: #F2F2F2;
  --best-win-bg: rgba(19, 26, 28, 0.05);
  --select-hover-bg: #F2F6F2;
  --ion-color-primary: #268A00;
  --live-chat-header-shadow: 0px 15px 50px rgba(0, 0, 0, 0.1);
  --live-chat-footer-shadow: 0px -16px 50px rgba(0, 0, 0, 0.1);
  --emoji-categories-filter-invert: invert(0%);
  --emoji-categories-title-bg: rgba(255, 255, 255, 0.95);
  --to-bottom-btn-bg: #D7DEDC;
  --scrollbar-thumb: #CACACA;  
  --user-menu-popover-body-border: #CACACA;
  --not-read: rgba(38, 138, 0, 0.08);
  --login-with-bg: #F2F6F2;
  --login-with-btn: #D7DEDC;
  --login-with-svg: #61696B;
  --input-border: #CACACA;
  --toast-shadow: 0px 15px 50px rgba(0, 0, 0, 0.1);
  /* react-toastify */
  --toastify-toast-width: 360px;
  --toastify-toast-min-height: 56px;
  --toastify-z-index: 50000;
  
}

body.dark {
  --grey: #61696B;
  --green: #268A00;
  --orange: #DD6F0C;
  --red: #EB5757;
  --blue: #3975EA;
  --discord: #5865F2;
  --twitter: #2CAAE1;
  --telegram: #53A6E4;
  --instagram: #D53862;
  --footer: #171F21;
  --cancel-btn: #424F54;
  --footer-top: #131A1C;
  --footer-links-color: #FFFFFF;
  --footer-text:#4E5E64;
  --footer-border: #27383E;
  --header-background: #1C2D31;
  --header-box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.49);
  --font-default: #ffffff;
  --modal-bg: #1F2E33;
  --text-winner-giveaways: #F2F2F2;
  --main-bg: url(../images/bg-dark.png);
  --balance-btn-bg: #162226;
  --banner-dot: #3B5660;
  --page-switch-bg:#424F54;
  --dot-box-shadow: 0px 1px 6px rgba(127, 244, 174, 0.75);
  --pr-card-bg:#1B2D30;
  --pr-card-br: #2A3D41;
  --pr-card-bsh: 0px 4px 30px rgba(75, 90, 81, 0.25);
  --pr-checklist: rgba(255, 255, 255, 0.5);
  --fav-bg: rgba(24,34,41, 0.3);
  --modal-close: #28393F;
  --button-share-giveaways-hover: #61696B;
  --bg-event-giveaways: linear-gradient(110.45deg, #0C100A 34.87%, #124200 100%);
  --button-share-icon: #CACACA;
  --button-share-icon-hover: #CACACA;
  --text-focus-switch: #CACACA;
  --modal-close-cross: #5D6A6D;
  --modal-close-circle: rgba(19, 26, 28, 0.5);
  --modal-auth-close-circle: rgba(19, 26, 28, 0.5);
  --modal-grey: #8B9A96;
  --user-btn-bg: #27373D;
  --prov-img: #283B3E;
  --modal-label: #61696B;
  --input-checked: url(../images/checked-dark.svg);
  --select-downdrop: url(../images/downdrop-dark.svg);
  --input-placeholder: rgba(255, 255, 255, .3);
  --modal-description: #3C4B50;
  --cancel-btn-bg: #28393F;
  --user-image-bg: #27383E;
  --user-image-br: #61696B;
  --user-image-svg: url(../images/user-image-dark.svg);
  --warning-message-bg: #303134;
  --password-eye-opened: url(../images/eye-opened-dark.svg);
  --password-eye-closed: url(../images/eye-closed-dark.svg);
  --account-table-br: #424E52;
  --sort-btn: #61696B;
  --action-btn-bg: #27383E;
  --ion-checkbox-bg: #304146;
  --address-title: #FFFFFF;
  --account-black: #FFFFFF;
  --deposites-bg: #27383E;
  --cab-img: url(../images/account-tabs/cab-dark.png);
  --contact-img: url(../images/account-tabs/contact-dark.png);
  --games-img: url(../images/account-tabs/games-dark.png);
  --multiple-img: url(../images/account-tabs/multiple-dark.png);
  --verify-img: url(../images/account-tabs/verify-dark.png);
  --close-img: url(../images/account-tabs/close-dark.png);
  --deposit-img: url(../images/balance-tabs/deposit-dark.png);
  --exchange-img: url(../images/balance-tabs/exchange-dark.png);
  --buy-sell-crypto: url(../images/balance-tabs/buy-sell-crypto.svg);
  --withdraw-img: url(../images/balance-tabs/withdraw-dark.png);
  --personal-img: url(../images/balance-tabs/personal-dark.png);
  --loan-img: url(../images/balance-tabs/loan-dark.png);
  --funds-img: url(../images/balance-tabs/funds-dark.png);
  --arrow-st: url(../images/arrow-static-dark.svg);
  --arrow-st-br: #EAE7D6;
  --pie-br: transparent;
  --tag-img-opacity: 0.4;
  --select-hover-bg: #1c3130;
  --active-shadow: 0px 9px 30px rgba(127, 244, 174, 0.75);
  --active-opacity-bsh: 0px 9px 30px rgba(127, 244, 174, 0.35);
  --buttons-bg: rgba(28, 45, 49, 0.9);
  --table-bg: #25353A;
  --flags-placeholder-color: #616c6f;
  --tournament-card-date-label: #ffffff;
  --referral-field-color: #1B2D30;
  --a-overview-img: url(../images/affiliate-tabs/overview.png);
  --a-commision-img: url(../images/affiliate-tabs/commision.png);
  --a-funds-img: url(../images/affiliate-tabs/funds.png);
  --a-reffered-users-img: url(../images/affiliate-tabs/reffered-users.png);
  --a-campaigns-img: url(../images/affiliate-tabs/campaigns.png);
  --note-container: #152325;
  --promo-btn-bg: #3E545C;
  --fullscreen-btn-bg: #314244;
  --fullscreen-svg: #ffffff;
  --edit-container-bg: #3D4B50;
  --edit-active-svg: #ffffff;
  --btn-disabled: #3D4B50;
  --premium-vip-container-bg: linear-gradient(82.06deg, #0C100A 43.88%, #124200 100%);
  --premium-vip-container-bonus-bg: #1B2D30; 
  --premium-vip-rank-bg-level-0: radial-gradient(52.59% 52.59% at 50% 50%, #693F00 0%, #0F0C08 100%);
  --premium-vip-rank-bg-level-1: radial-gradient(52.59% 52.59% at 50% 50%, #005D69 0%, #080C0F 100%);
  --premium-vip-rank-bg-level-2: radial-gradient(89.24% 70.36% at 50% 50%, #024373 0%, #08141C 100%);
  --premium-vip-rank-bg-level-3: radial-gradient(89.24% 70.36% at 50% 50%, #C4A548 0%, #231400 100%);
  --premium-vip-rank-bg-level-4: radial-gradient(89.24% 70.36% at 50% 50%, #4B88E8 0%, #06141F 100%);
  --premium-vip-rank-bg-level-5: radial-gradient(89.24% 70.36% at 50% 50%, #CB6345 0%, #130907 100%);
  --premium-vip-rank-bg-level-6: radial-gradient(89.24% 70.36% at 50% 50%, #6324A1 0%, #090110 100%);
  --premium-vip-rank-shadow-level-0: 0px 0px 74px -15px #694103;
  --premium-vip-rank-shadow-level-1: 0px 0px 74px -15px #015460;
  --premium-vip-rank-shadow-level-2: 0px 0px 74px -15px #075086;
  --premium-vip-rank-shadow-level-3: 0px 0px 74px -15px #917732;
  --premium-vip-rank-shadow-level-4: 0px 0px 74px -15px #3461A4;
  --premium-vip-rank-shadow-level-5: 0px 0px 74px -15px #913301;
  --premium-vip-rank-shadow-level-6: 0px 0px 74px -15px #6A1073;
  --premium-vip-rank-border-level-0: rgba(113, 93, 61, 0.6);
  --premium-vip-rank-border-level-1: rgba(1, 84, 96, 0.6);
  --premium-vip-rank-border-level-2: rgba(32, 84, 122, 0.6);
  --premium-vip-rank-border-level-3: rgba(140, 115, 45, 0.6);
  --premium-vip-rank-border-level-4: rgba(75, 112, 168, 0.6);
  --premium-vip-rank-border-level-5: rgba(135, 46, 26, 0.6);
  --premium-vip-rank-border-level-6: rgba(79, 36, 122, 0.6);
  --premium-vip-rank-text-level-0: #CD8222;
  --premium-vip-rank-text-level-1: #22C3CD;
  --premium-vip-rank-text-level-2: #2389D1; 
  --premium-vip-rank-text-level-3: #FFC971;
  --premium-vip-rank-text-level-4: #35B6FF;
  --premium-vip-rank-text-level-5: #FE7758;
  --premium-vip-rank-text-level-6: #964ADE;
  --progress-bar-bg: #424F54;
  --progress-bar-active-bg-level-0: linear-gradient(180deg, #CD8222 0%, #4B2A00 100%);
  --progress-bar-active-bg-level-1: linear-gradient(180deg, #22C3CD 0%, #03383C 100%);
  --progress-bar-active-bg-level-2: linear-gradient(180deg, #2389D1 0%, #062B47 100%);
  --progress-bar-active-bg-level-3: linear-gradient(180deg, #FFC971 0%, #472E01 100%); 
  --progress-bar-active-bg-level-4: linear-gradient(180deg, #35B6FF 0%, #00273D 100%);
  --progress-bar-active-bg-level-5: linear-gradient(180deg, #FE7758 0%, #651300 100%);
  --premium-vip-progress-center-bg-level-0: rgba(205, 130, 34, 0.1);
  --premium-vip-progress-center-bg-level-1: rgba(34, 195, 205, 0.1);
  --premium-vip-progress-center-bg-level-2: rgba(35, 137, 209, 0.1);
  --premium-vip-progress-center-bg-level-3: rgba(255, 201, 113, 0.1);
  --premium-vip-progress-center-bg-level-4: rgba(53, 182, 255, 0.1);
  --premium-vip-progress-center-bg-level-5: rgba(254, 119, 88, 0.1);
  --premium-vip-progress-center-bg-level-6: rgba(150, 74, 222, 0.1);
  --premium-vip-modal-bg-level-0: radial-gradient(83.93% 68.52% at 50% 0%, #693F00 0%, #0F0C08 100%);
  --premium-vip-modal-bg-level-1: radial-gradient(83.93% 68.52% at 50% 0%, #005D69 0%, #080C0F 100%);
  --premium-vip-modal-bg-level-2: radial-gradient(83.93% 68.52% at 50% 0%, #024373 0%, #04111A 100%);
  --premium-vip-modal-bg-level-3: radial-gradient(83.93% 68.52% at 50% 0%, #C3A548 0%, #231400 100%); 
  --premium-vip-modal-bg-level-4: radial-gradient(83.93% 68.52% at 50% 0%, #4B88E8 0%, #06141F 100%);
  --premium-vip-modal-bg-level-5: radial-gradient(83.93% 68.52% at 50% 0%, #CB6345 0%, #130907 100%);
  --premium-vip-modal-bg-level-6: radial-gradient(83.93% 68.52% at 50% 0%, #6324A1 0%, #090110 100%);
  --player-modal-bg-level-0: radial-gradient(45.23% 100% at 100% 0%, #693F00 0%, #080C0F 100%, #0F0C08 100%);
  --player-modal-bg-level-1: radial-gradient(45.23% 100% at 100% 0%, #005D69 0%, #080C0F 100%);
  --player-modal-bg-level-2: radial-gradient(45.23% 100% at 100% 0%, #024373 0%, #04111A 100%);
  --player-modal-bg-level-3: radial-gradient(45.23% 100% at 100% 0%, #C3A548 0%, #231400 100%);
  --player-modal-bg-level-4: radial-gradient(45.23% 100% at 100% 0%, #4B88E8 0%, #06141F 100%);
  --player-modal-bg-level-5: radial-gradient(45.23% 100% at 100% 0%, #CB6345 0%, #130907 100%);
  --player-modal-bg-level-6: radial-gradient(45.23% 100% at 100% 0%, #6324A1 0%, #090110 100%);
  --player-info-pic-ellipse-mobile: rgba(255, 255, 255, 0.05);
  --player-modal-mobile-bg-level-0: radial-gradient(100% 100% at 100% 100%, #693F00 0%, #080C0F 100%, #0F0C08 100%);
  --player-modal-mobile-bg-level-1: radial-gradient(100% 100% at 100% 100%, #005D69 0%, #080C0F 100%, #080C0F 100%); 
  --player-modal-mobile-bg-level-2: radial-gradient(100% 100% at 100% 100%, #024373 0%, #080C0F 100%, #04111A 100%);
  --player-modal-mobile-bg-level-3: radial-gradient(100% 100% at 100% 100%, #C3A548 0%, #080C0F 100%, #231400 100%);
  --player-modal-mobile-bg-level-4: radial-gradient(100% 100% at 100% 100%, #4B88E8 0%, #080C0F 100%, #06141F 100%);
  --player-modal-mobile-bg-level-5: radial-gradient(100% 100% at 100% 100%, #CB6345 0%, #080C0F 100%, #130907 100%);
  --player-modal-mobile-bg-level-6: radial-gradient(100% 100% at 100% 100%, #6324A1 0%, #080C0F 100%, #090110 100%);
  --chat-player-bg-level-0: radial-gradient(62.5% 97.22% at 50% 50%, #693F00 0%, #0F0C08 100%);
  --chat-player-bg-level-1: radial-gradient(62.5% 97.22% at 50% 50%, #005D69 0%, #0F0C08 100%, #080C0F 100%);
  --chat-player-bg-level-2: radial-gradient(62.5% 97.22% at 50% 50%, #024373 0%, #04111A 100%);
  --chat-player-bg-level-3: radial-gradient(62.5% 97.22% at 50% 50%, #C3A548 0%, #231400 100%);
  --chat-player-bg-level-4: radial-gradient(62.5% 97.22% at 50% 50%, #4B88E8 0%, #06141F 100%);
  --chat-player-bg-level-5: radial-gradient(62.5% 97.22% at 50% 50%, #CB6345 0%, #130907 100%);
  --chat-player-bg-level-6: radial-gradient(62.5% 97.22% at 50% 50%, #6324A1 0%, #090110 100%);
  --campaign-header: #32454B;
  --asterisk-color: #A0335C;
  --close-cr-modal-bg: #3D4B50;
  --progress-badge-bg: #ffffff; 
  --progress-badge-text-color: #111827;
  --not-available-bg: #9C0000;
  --tag-img-fill: #1C2D31;
  --stick-giveaways: #424F54;
  --tag-img-stroke: #7F9398;
  --golden-text: #FDD77E;
  --bonus-popover-bg: #162226;
  --ios-setup-content-bg: #27373D;
  --dpopdown-img-opacity: .5;
  --green-btn-hover: #67AD4D;
  --green-btn-hover-opacity: #67ad4d94;
  --orange-btn-hover: #E48C3D;
  --cancel-btn-hover: #61696B;
  --red-btn-hover: #EF7979;
  --red-btn-hover-opaciti: #ef797980;
  --socials-container: #27383E;
  --social-color: #A8D099;
  --text-description: #61696B;
  --subscribe-container-bg: linear-gradient(180deg, #0C100A 0%, #0E2107 100%);
  --subscribe-text-description: #61696B;
  --subscribe-input-bg: #171F21;
  --section-message-bg: rgba(221, 111, 12, 0.15);
  --share-rainbow-text: linear-gradient(90deg, #1BAD29 27.8%, #7AAEDF 50.84%, #4951FF 71.6%);
  --section-message-text: #CACACA;
  --premium-vip-progress-top: #CACACA;
  --premium-vip-progress-center-bg: rgba(34, 195, 205, 0.1);
  --modal-header-bg: #1C2D31;
  --modal-body-bg: linear-gradient(0.58deg, #0C100A 34.71%, #124200 100%);
  --modal-body-mobile-bg: linear-gradient(90deg, #0C100A 31.38%, #124200 100%);
  --modal-body-shadow: 0px 44px 140px -20px rgba(0, 0, 0, 0.1);
  --modal-body-bg-second: linear-gradient(240deg, #0C100A 34.87%, #124200 100%);
  --modal-body-mobile-bg-second: linear-gradient(45deg, #0C100A 31.38%, #124200 100%);
  --modal-body-standart-bg: #19252C;
  --leader-board-shadow: 0px 44px 140px -20px rgba(0, 0, 0, 0.1);
  --leader-board-border: #424F54;
  --modal-gradient-text-opacity: .5;
  --btn-text-modal: #FFFFFF;
  --modal-gradient-text-mobile-opacity: 1;
  --levels-main-info-container-bg: linear-gradient(110.45deg, #0C100A 34.87%, #124200 100%);
  --green-border: rgba(38, 138, 0, 0.2);
  --arrow-hover: #CACACA;
  --levels-carousel-gradient: radial-gradient(50% 50% at 50% 50%, rgba(0, 181, 116, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  --subcategories-filter-select-border: transparent;
  --subcategories-item-bg: #27383E;
  --best-win-bg: rgba(255, 255, 255, 0.1);
  --select-hover-bg: #19252C;
  --ion-color-primary: #268A00;
  --live-chat-header-shadow: 0px 15px 50px rgba(0, 0, 0, 0.2);
  --live-chat-footer-shadow: 0px -15px 50px rgba(0, 0, 0, 0.2);
  --emoji-categories-filter-invert: invert(100%);
  --emoji-categories-title-bg: rgb(23, 31, 33, 0.95);
  --to-bottom-btn-bg: #424F54;
  --scrollbar-thumb: #27383E;
  --user-menu-popover-body-border: #19252C;
  --not-read: rgba(38, 138, 0, 0.08);
  --login-with-bg: #171F21;
  --login-with-btn: #27383E;
  --login-with-svg: #D7DEDC;
  --input-border: #171F21;
  --toast-shadow: 0px 15px 50px rgba(0, 0, 0, 0.1);
  
}
