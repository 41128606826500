.homepage {
  background: var(--main-bg);
  background-color: #F2F7F2;
  background-repeat: repeat;
  width: 100%;
  height: auto;
  flex-direction: column;
}
.banners-small {
  width: 100%;
  height: 100%;
  max-width: 520px;
  background: var(--pr-card-bg);
}
.banners-small .pr-info {
  padding-top: 7px
}
.banners-small .carousel-root,
.banners-small .carousel,
.banners-small .slider-wrapper,
.banners-small .slider,
.banners-small .slide,
.small-banner-single {
  height: 100%;
}
.banners-small .slide {
  background: var(--pr-card-bg);
}
.pr-info {
  background: var(--pr-card-bg);
}
.err-window {
  height: 100%;
  background: var(--main-bg);
  display: flex;
  font-family: Rubik, sans-serif;
  font-weight: bold;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.err-window p {
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
  color: var(--green);
}
.dark .homepage {
  background-color: #172229;
}

.homepage .header-container, .homepage .footer-container {
  flex-grow: 0;
}

.homepage .width-container {
  flex-grow: 1;
}

.page-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--font-default);
}

.page-title.jackpot {
  margin-right: auto;
}

.width-container {
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;
  padding: 0 40px;
}

.jackpots-margin {
  width: 100%;
  margin-top: 50px;
}

.promoted-card {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  background: var(--pr-card-bg);
  box-shadow: var(--pr-card-bsh);
}

.promoted-card:first-child {
  margin-right: 10px;
}

.promoted-card.big {
  margin-top: 50px;
  margin-bottom: 50px;
  background: none;
  box-shadow: none;
}

.promoted-card.big .big-banner {
  margin: 0;
}

.promoted-card.big .big-banner .btn-cnt {
  height: calc(100% + 34px);
}

.promoted {
  margin-top: 40px;
  justify-content: space-between;
}

.pr-info {
  height: 50px;
  align-items: center;
}

.pr-info p {
  margin: 0;
  margin-top: -7px;
  margin-left: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.pr-img {
  height: auto;
  border-radius: 3px 3px 0px 0px;
}

.promoted-card .filter-btn {
  cursor: pointer;
  opacity: 1;
  margin: 0 auto;
  z-index: 10;
  justify-content: center;
  align-items: center;
  width: 164.74px;
  height: 46.66px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid var(--green);
  color: var(--green);
  background: var(--buttons-bg);
  transition: all .3s ease-out;
}

.promoted-card .filter-btn:hover, .promoted-card .filter-btn:active, .promoted-card .filter-btn:focus {
  background: var(--green);
  color: #ffffff;
  box-shadow: var(--active-shadow);
}

.btn-cnt {
  width: 100%;
  opacity: 0;
  z-index: 10;
  transition: .3s;
  height: calc(100% - 55px);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.promoted-card img {
  transition: .3s;
}

.promoted-card:hover .btn-cnt {
  opacity: 1
}

.promoted-card:hover img {
  opacity: .3
}

.pr-info span {
  margin-left: auto;
  margin-right: 30px;
  margin-top: -10px;
  color: var(--green);
  text-shadow: 0px 0px 7px rgba(139, 212, 168, 0.17);
  font-size: 24px;
  line-height: 42px;
  font-family: 'Pacifico', cursive;
}

.pr-info .pr-info-label {
  margin-left: auto;
  margin-right: 30px;
  margin-top: -10px;
  align-items: center;
  color: var(--green);
  text-shadow: 0px 0px 7px rgba(139, 212, 168, 0.17);
  font-size: 24px;
  line-height: 42px;
  font-family: 'Pacifico', cursive;
}

.pr-info .pr-info-label img {
  margin-top: 5px;
  margin-left: 5px;
}

.recommended-block {
  margin-top: 50px;
  margin-bottom: 10px;
  flex-direction: column;
}

.top-of-the-page {
  margin-top: 32px;
  margin-bottom: 22px;
}

.rec-games-block {
  width: 100%;
  perspective: 1000px;
  perspective-origin: center 100%;
  justify-content: space-between;
}

.rec-card {
  -webkit-animation: moverec;
  animation: moverec;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: .3s;
  flex: 0 0 235px;
  position: relative;
}

.rec-card.not-available .not-available-text {
  height: 155px;
}

.rec-card.not-available .rec-card-img,
.rec-card.not-available .rec-card-name,
.rec-card.not-available .rec-card-provider {
  opacity: .15;
}

.rec-card.not-available .playnow-btn {
  background: transparent;
}

.rec-card.not-available .playnow-btn:hover {
  box-shadow: none;
}

@-webkit-keyframes moverec {
  0% {
    opacity: 0.5;
    transform: translateZ(100px)
    /* rotate(1deg); */
  }
  100% {
    opacity: 1;
    transform: translateZ(0)
    /* rotate(0deg); */
  }
}

@keyframes moverec {
  0% {
    opacity: 0.5;
    transform: translateZ(100px)
    /* rotate(1deg); */
  }
  100% {
    opacity: 1;
    transform: translateZ(0)
    /* rotate(0deg); */
  }
}

.rec-card img {
  width: 100%;
  /* margin-top: 10px; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 155px;
  border-radius: 3px;
}

.rec-card>p {
  width: 100%;
  text-align: left;
  margin-bottom: 7px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--font-default);
  padding: 0 30px;
}

.rec-card span {
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 14px;
  line-height: 17px;
  color: var(--font-default);
  opacity: .3;
  mix-blend-mode: normal;
  margin-bottom: 20px;
  padding: 0 30px;
}

.playnow-btn {
  background: var(--green);
  width: 100%;
  opacity: 0;
  height: 43.5px;
  transition: .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease-out;
}

.playnow-btn p {
  width: auto;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.rec-card:hover {
  border: 1px solid var(--green);
}

.rec-card .playnow-btn {
  margin-top: auto;
}

.rec-card:hover .playnow-btn {
  opacity: 1;
}

.playnow-btn:hover {
  box-shadow: var(--active-opacity-bsh);
}

.big-banner {
  margin-top: 50px;
  margin-bottom: 50px;
}

.games-block {
  width: 100%;
  flex-direction: column;
}

.games-container {
  perspective: 1500px;
  perspective-origin: center 100%;
  width: 100%;
  /* justify-content: space-between;
  flex-wrap: wrap; */
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  /* grid-template-columns: repeat(auto-fill,minmax(245px,1fr)); */
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 20px !important;
}

.more-games {
  cursor: pointer;
  width: 100%;
  max-width: 183.42px;
  margin: 25px auto;
  border: 1px solid var(--green);
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-out;
}

.more-games p {
  color: var(--green);
}

.more-games:hover, .more-games:active, .more-games:focus {
  background-color: var(--green);
  box-shadow: var(--active-opacity-bsh);
}

.more-games:hover p, .more-games:active p, .more-games:focus p {
  color: #ffffff;
}

.arrows {
  margin-left: auto;
}

.arrows div {
  transition: .3s;
  width: 28px;
  cursor: pointer;
  height: 28px;
  border-radius: 50%;
  margin-left: 10px;
  transition: all .3s ease-out;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
}

.arrows div span {
  background: url(../images/arrow.svg);
  width: 7px;
  height: 11px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.arrows .left span {
  filter: brightness(0.5);
  transform: rotate(180deg);
}

.arrows .right span {
  filter: brightness(0.5);
}

.arrows .left, .arrows .right {
  background: transparent;
  border: 1px solid #8B9695
}

.arrows div:hover {
  box-shadow: var(--active-opacity-bsh);
}

.arrows .green {
  background: var(--green);
  border: none;
}

.arrows .green span {
  filter: none;
}

.games-block .title-w-filter,
.games-page-container .title-w-filter {
  margin-bottom: 32px;
  padding: 0;
  justify-content: space-between;
}

.games-block .title-w-filter .page-title,
.games-page-container .title-w-filter .page-title {
  margin: 0;
  font-size: 44px;
  line-height: 50px;
  color: var(--section-message-text);
}

.title-w-filter {
  padding: 16px 0;
  align-items: center;
}

.title-w-filter .page-title {
  min-width: fit-content;
  margin-right: 10px;
}

.title-w-filter ion-searchbar {
  width: 250px;
  min-width: 250px;
  height: 48px;
  margin: 0;
  /* margin-left: auto; */
}

.title-w-filter ion-searchbar .searchbar-input-container input {
  height: 48px;
}

.filter-segment {
  padding-left: 10px;
  padding-right: 10px;
  /* margin-left: auto; */
  margin-left: 10px;
  background: var(--header-background);
  box-shadow: 0px 4px 30px rgba(75, 90, 81, 0.25);
  border-radius: 3px;
  height: 48px;
  width: 100%;
  /* max-width: 617px; */
}

.filter-segment ion-segment-button {
  height: 44px;
  --width: 50px;
  text-transform: none;
  --color: transparent;
  --background-focused: transparent;
  --color-checked: transparent;
  --background-checked: transparent;
  --color-focused: transparent;
  --background-hover: transparent
}

.filter-segment ion-segment-button img {
  min-width: 20px;
  width: 20px;
  margin-right: 7px;
}

ion-segment-button p {
  font-weight: 500;
  font-size: 14px;
  transition: .3s;
  line-height: 19px;
  color: var(--filter-color-disabled)
}

ion-segment-button.segment-button-checked p {
  color: green
}

ion-segment-button::part(indicator-background) {
  background: var(--green);
  box-shadow: 0px 1px 6px rgba(127, 244, 174, 0.75);
  height: 2.59px;
  margin-top: -1px;
}

ion-segment-button::part(indicator) {
  height: 3px;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none;
}

.carousel .control-next.control-arrow:hover:before {
  border-left-color: var(--green);
}

.carousel .control-prev.control-arrow:hover:before {
  border-right-color: var(--green);
}

/* LeaderBoard */
.leader-board-container.affiliate-table {
  margin-top: 100px;
  border-radius: 8px;
  background: var(--header-background);
  box-shadow: var(--leader-board-shadow);
}

.leader-board-container.account-table .leader-board-header {
  height: 70px;
  padding: 0 25px;
  gap: 8px;
  justify-content: flex-start;
  border-bottom: 1px solid var(--leader-board-border);
}

.leader-board-header-tab {
  padding: 6px 12px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--section-message-text);
  cursor: pointer;
}

.leader-board-header-tab.current {
  background: var(--subcategories-item-bg);
  color: var(--green);
}

.leader-board-header-tab:hover {
  background: var(--subcategories-item-bg);
}

.leader-board-table {
  min-height: 665px;
  padding: 0 25px 25px;
}

.table-swiper {
  max-height: 550px;
}

.leader-board-container .leader-board-table .leader-board-table-head div {
  width: 18.4%;
  height: 90px;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: var(--subscribe-text-description);
}

.leader-board-container .leader-board-table .leader-board-table-head div.small {
  width: 8%;
}

.leader-board-container .leader-board-table .leader-board-table-head div.align-right {
  justify-content: flex-end;
}

.leader-board-container .leader-board-table div.swiper-slide {
  justify-content: flex-start;
  border-radius: 8px;
}

.leader-board-container .leader-board-table .swiper-slide.colored {
  background: var(--user-image-bg);
}

.leader-board-container .leader-board-table .swiper-slide .address-title {
  width: 18.4%;
  display: flex;
  padding: 15px;
  justify-content: flex-start;
}

.leader-board-container .leader-board-table .swiper-slide .address-title.ten {
  width: 8%;
}

.leader-board-container .leader-board-table .swiper-slide div.address-title:last-of-type {
  justify-content: flex-end;
}

.leader-board-container .leader-board-table .swiper-slide .address-title span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.leader-board-container .leader-board-table .swiper-slide .address-title span.disabled {
  color: var(--subscribe-text-description);
}

.leader-board-container .leader-board-table .swiper-slide .address-title img {
  width: 22px;
  height: 22px;
}

.leader-board-container .leader-board-table .swiper-slide .address-title .first-img img {
  margin: 0 8px 0 0;
}

.leader-board-container .leader-board-table .swiper-slide .address-title .with-img {
  justify-content: flex-start;
}

.leader-board-container .leader-board-table .swiper-slide .address-title .last-img img {
  margin: 0 0 0 8px;
}










.leader-board-container .leader-board-table thead tr {
  height: 90px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  color: var(--subscribe-text-description);
}

.leader-board-container .leader-board-table thead tr th {
  padding: 15px;
  text-align: left;
}

.leader-board-container .leader-board-table thead tr th:last-of-type {
  text-align: right;
}

.leader-board-container .leader-board-table tbody tr td:first-of-type {
  border-radius: 8px 0 0 8px;
}

.leader-board-container .leader-board-table tbody tr td:last-of-type {
  border-radius: 0 8px 8px 0;
}

.leader-board-container .leader-board-table tbody tr td {
  padding: 15px;
  text-align: left;
}

/* .leader-board-container .leader-board-table tbody tr:nth-child(2n) {
  animation: next;
  animation-duration: .3s;
  transform: translateY(55px);
  transition: .3s;
}

.leader-board-container .leader-board-table tbody tr:not(.el-transition-enter-done) {
  animation: next;
  animation-duration: .3s;
  transform: translateY(55px);
  transition: .3s;
} */

/* @keyframes next {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(55px);
  }
} */

.el-transition-enter {
  opacity: 0;
}
.el-transition-enter-active {
  opacity: 1;
  transition: opacity .3s ease-in;
}
/* .el-transition-enter-done {
  transform: translateY(55px);
  transition: all .3s;
} */
.el-transition-exit {
  opacity: 1;
}
.el-transition-exit-active {
  opacity: 0;
  transition: opacity .3s ease-in;
}


/* .el-transition-enter {
  opacity: 0.01;
}

.el-transition-enter.el-transition-enter-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.el-transition-leave {
  opacity: 1;
}

.el-transition-leave.el-transition-leave-active {
  opacity: 0.01;
  transition: opacity .3s ease-in;
}

.el-transition-appear {
  opacity: 0.01;
}

.el-transition-appear.el-transition-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
} */


.leader-board-container .leader-board-table tbody tr td div {
  justify-content: left;
}

.leader-board-container .leader-board-table tbody tr td span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.leader-board-container .leader-board-table tbody tr td.twenty {
  width: 18.4%;
  max-width: 172px;
  min-width: 172px;
}

.leader-board-container .leader-board-table tbody tr td.ten {
  width: 8%;
  max-width: 75px;
  min-width: 75px;
}

.leader-board-container .leader-board-table tbody tr td img {
  width: 22px;
  height: 22px;
}

.leader-board-container .leader-board-table tbody tr td .first-img img {
  margin: 0 8px 0 0;
}

.leader-board-container .leader-board-table tbody tr td .with-img {
  justify-content: flex-start;
}

.leader-board-container .leader-board-table tbody tr td .with-img span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* .leader-board-container .leader-board-table tbody tr td .with-img span.green {
  color: var(--green);
} */

.leader-board-container .leader-board-table tbody tr td:last-of-type div {
  justify-content: flex-end;
}

.leader-board-container .leader-board-table tbody tr td .last-img img {
  margin: 0 0 0 8px;
}