.game-page-width-container {
  flex-direction: column;
}

.game-container {
  width: 100%;
  max-width: 1060px;
  /* width: auto; */
  /* min-width: 70%; */
  /* height: 70vh; */
  margin-top: 30px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  background: var(--pr-card-bg);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
}

.game-center-wrapper {
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  padding-top: 56.3%;
}

.game-center {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  display: block;
  z-index: 10;
}

.game-header {
  height: 50px;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
}

.game-header-logo {
  width: auto;
  height: 80%;
  cursor: pointer;
}

.game-header-logo img {
  width: auto;
  height: 100%;
  filter: contrast(.1) grayscale(1);
}

.game-header .close-gme {
  position: inherit;
}

.game-canvas {
  width: 100%;
  height: 100%;
  /* height: 70vh; */
  /* height: calc(100% - 105px); */
}

.game-canvas-header {
  width: 100%;
  padding: 0 50px;
  display: flex;
  align-items: center;
  height: 75px;
  z-index: 10;
  justify-content: space-between;
}

.btn-logo-link-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text-logo-link {
font-size: 250px;
margin-right: 24px;
}

.text-logo-link.no-fullscreen {
  font-size: 180px;
}

.btn-logo-link.no-fullscreen {
  width: 147px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.btn-logo-link {
  width: 157px;
  height: 50px;
  border-radius: 8px;
  padding: 14px 30px 14px 30px;
  background-color: var(--green);
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  overflow: hidden;
}

.btn-logo-link:hover {
  background-color: var(--green-btn-hover);
}


.btn-logo-link::after {
  content: "";
  display: block;
  width: 20px;
  height: 200px;
  opacity: .4;
  filter: blur(5px);
  margin-left: 50px;
  background: #fff;
  left: -10px;
  top: -100px;
  z-index: 1;
  transform: rotate(45deg);
  position: absolute;
  animation: movingFlare1 2s linear infinite;
}

.btn-logo-link::before {
  content: "";
  display: block;
  width: 20px;
  height: 200px;
  opacity: .4;
  filter: blur(5px);
  margin-left: 50px;
  background: #fff;
  left: -60px;
  top: -100px;
  z-index: 1;
  transform: rotate(45deg);
  position: absolute;
  animation: movingFlare2 2s linear infinite;
}
 
@keyframes movingFlare1 {
  0% {
    left: -10px;
    margin-left: 0px;
  }
  60% {
    left: 130%;
    margin-left: 80px;
  }
  100% {
    left: 130%;
    margin-left: 80px;
  }
}

@keyframes movingFlare2 {
  0% {
    left: -50px;
    margin-left: 0px;
  }
  60% {
    left: 80%;
    margin-left: 80px;
  }
  100% {
    left: 130%;
    margin-left: 80px;
  }
}

.game-footer {
  height: 55px;
  padding: 0 20px;
  align-items: center;
}

.game-footer-like-btn {
  width: 70px;
  height: 40px;
  margin-left: auto;
  background: var(--fullscreen-btn-bg );
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
}

.game-footer-like-btn span {
  margin-left: 10px;
  font-weight: 500;
}

.game-footer-fullscreen-btn {
  width: 40px;
  height: 40px;
  margin-left: 30px;
  background: var(--fullscreen-btn-bg );
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
}

.game-footer-like-btn svg,
.game-footer-fullscreen-btn svg {
  fill: var(--fullscreen-svg);
}

.game-footer-like-btn.active span {
  color: var(--orange);
}

.game-footer-like-btn.active svg {
  fill: var(--orange);
}
