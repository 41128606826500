#root {
  /* @media screen and (min-width: 768px) and (max-width: 1439px) {
        .header-container .balance-block {
          display: none;
        }
      } */
  /* 4K */
}
@media screen and (max-width: 767px) {
  #root {
    /* Header */
    /* Menu */
    /* Footer */
    /* .footer-container-socials a svg {
          fill: var(--green) !important;
        } */
    /* Homepage */
    /* .carousel-root.big-banner {
          margin-left: -15px;
          margin-right: -15px;
        } */
    /* LeaderBoard */
    /* Games */
    /* .games-page-container .title-w-filter {
          display: block;
        } */
    /* .slot-tag p {
          font-size: 9px;
          line-height: 11px;
        } */
    /* Tournaments */
    /* Bonuses */
    /* Providers */
    /* .premium .premium-vip-progress-container {
          height: auto;
          align-items: center;
        } */
    /* How To Charge Modal */
    /* Game page */
    /* .game-center {
          position: inherit;
        } */
    /* Crash page */
  }
  #root .ion-page ion-content.page {
    --offset-top: -50px !important;
  }
  #root .header-container {
    width: 100%;
    height: 50px;
    padding: 0 3px 0 0;
    justify-content: center;
    position: relative;
  }
  #root .menu-btn-container {
    width: 60px;
    height: 100%;
    margin-left: 0px;
  }
  #root .header-container .lang-field {
    margin-left: auto;
    margin-right: 0px;
  }
  #root .header-container ion-item.lang-field-item {
    margin-left: auto;
    margin-right: 0px;
  }
  #root .err-window {
    font-size: 0.9rem;
  }
  #root .header-container .balance-block,
  #root .header-container .login-btn,
  #root .header-container .unlogged,
  #root .hline {
    display: none;
  }
  #root .header-container .logged {
    margin-right: 12px;
  }
  #root .userpic.header-userpic {
    min-width: 30px;
    width: 30px;
    height: 30px;
    margin-left: 0;
  }
  #root .notification-container {
    min-width: 30px;
    width: 30px;
    height: 30px;
    margin-left: 12px;
  }
  #root .notification-container svg {
    width: 18px;
    height: 18px;
  }
  #root .notification-container ion-badge {
    top: -3px;
    right: -6px;
    font-size: 10px;
  }
  #root .logo-link {
    width: 118px;
    height: 30px;
    margin: 0 auto;
    position: absolute;
    /* background: url(../images/logo-link-header.svg); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  #root ion-toggle.daymode-toggle {
    margin-left: 0;
    margin-right: 0;
  }
  #root ion-toggle.daymode-toggle.mr-a {
    margin-right: 0;
  }
  #root .user-popover.user-menu {
    --offset-y: 10px;
  }
  #root .user-popover.user-menu .popover-content,
  #root .user-popover.user-menu::part(content) {
    width: 336px;
    max-width: 96%;
    /* top: 50px !important; */
  }
  #root .user-menu-popover-header .username {
    font-size: 14px;
    line-height: 20px;
  }
  #root .user-menu-popover-body-item {
    padding: 12px;
  }
  #root .user-menu-popover-body-item .premium-vip-rank {
    min-width: inherit;
    margin-right: 12px;
  }
  #root .user-menu-popover-progress-container .premium-vip-progress-top {
    font-size: 14px;
    line-height: 20px;
  }
  #root .user-menu-popover-body-item-title {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 20px;
  }
  #root ion-item.header-balance {
    width: 100%;
    margin: 0 0 12px;
    --background: var(--balance-btn-bg) !important;
  }
  #root ion-item.header-balance .balance-select {
    padding-left: 42px !important;
  }
  #root .user-popover .balance-div {
    width: 100%;
    margin: 0 0 12px;
  }
  #root .user-popover .balance-div img {
    margin-left: 12px;
    margin-right: 8px;
  }
  #root .user-popover .balance-div.bonus svg {
    margin-right: 12px;
  }
  #root .user-popover a.balance-btn {
    height: 50px;
    padding: 14px 20px;
    background: var(--green);
    border-radius: 8px;
    justify-content: center;
    position: relative;
  }
  #root .user-menu-popover-body a.balance-btn p {
    margin-left: 10px;
    color: var(--btn-text-modal);
  }
  #root .user-menu-popover-body-item .gift-img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  #root .user-menu-popover-body-item.promo-item .user-menu-popover-body-item-label {
    font-size: 14px;
  }
  #root .user-menu-popover-body-item.grid {
    grid-template-columns: 1fr;
    gap: 20px 0;
  }
  #root .user-menu-popover-body .user-menu-popover-body-item p a {
    font-size: 14px;
    line-height: 20px;
  }
  #root .user-menu-popover-body-item.logout-item .user-menu-popover-body-item-label {
    font-size: 14px;
  }
  #root .user-popover.bonus-popover::part(content) {
    margin-top: 0;
  }
  #root .banners-small {
    width: 100%;
    max-width: 100%;
  }
  #root .banners-small .pr-info {
    padding-top: 0px;
    margin-top: 5px;
  }
  #root ion-menu::part(container) {
    --height: calc(100% - 50px);
    margin-top: 50px;
  }
  #root .only-mob-menu {
    padding-bottom: 25px;
    /* margin: 0 auto 30px; */
    border-bottom: 1px solid var(--menu-hr);
  }
  #root .footer-container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    background-color: var(--footer);
    margin-top: 25px;
  }
  #root .footer-container-top {
    height: 35px;
  }
  #root .footer-container-top img {
    width: 75px;
    height: auto;
    padding: 5px 10px;
  }
  #root .footer-container-body {
    padding: 20px 0 0;
  }
  #root .footer-container-body-top {
    margin-bottom: 30px;
  }
  #root .footer-container-body-top > img {
    display: none;
  }
  #root .footer-container-socials {
    max-width: 70%;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
  }
  #root .footer-container-socials a {
    width: 35px;
    height: 35px;
    margin: 0;
  }
  #root .footer-container-socials a svg {
    width: 60%;
    height: 60%;
  }
  #root .footer-container-socials a:first-child {
    margin-left: 0;
  }
  #root .footer-container-links {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
  #root .footer-container-links div {
    margin-left: auto;
    margin-bottom: 20px;
  }
  #root .footer-container-links div:nth-child(odd) {
    width: 45%;
    margin-left: 0;
    margin-right: 5%;
  }
  #root .footer-container-links div:nth-child(even) {
    width: 50%;
  }
  #root .footer-container-links div a {
    margin-top: 0;
    font-size: 12px;
    line-height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #root .footer-container-links div a.help {
    margin-top: 0;
  }
  #root .footer-container-body-bottom {
    margin-left: 15px;
    margin-right: 15px;
  }
  #root .footer-text-content {
    font-size: 10px;
    line-height: 16px;
    padding: 20px 0;
  }
  #root .footer-logos-bottom {
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  #root .footer-logos-bottom > div {
    width: auto;
    padding: 0;
  }
  #root .footer-logos-bottom .logos-bottom-left {
    display: flex;
    width: 30%;
  }
  #root .footer-logos-bottom .eighteen {
    width: 60px;
    height: 60px;
    background-position: center;
    /* margin-right: 15px; */
  }
  #root .footer-logos-bottom .im-casino {
    width: 60px;
    height: 60px;
    margin: 0 auto;
  }
  #root .logo-bottom-right a {
    display: flex !important;
  }
  #root .footer-logos-bottom .logos-bottom-center {
    width: auto;
  }
  #root .footer-logos-bottom .ridotto {
    width: 70px;
    height: auto;
    margin: 0 auto;
  }
  #root .footer-logos-bottom .logo-bottom-right {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding-right: 0;
  }
  #root .footer-logos-bottom .logo-bottom-right > * {
    flex: 1 1 33%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #root .logo-bottom-right a:first-child {
    /* margin-right: 15px; */
  }
  #root .footer-logos-bottom .to-gov {
    width: 100%;
    height: 100%;
    background-position: center;
    margin: 0;
  }
  #root .footer-valid > div {
    margin: 0 auto;
  }
  #root .footer-valid * {
    height: 60px !important;
  }
  #root .footer-logos-bottom .to-kkk {
    width: 100%;
    height: 100%;
    margin: 0;
    background-position: center;
  }
  #root .footer-copyright {
    font-size: 10px;
    padding: 15px 0;
  }
  #root .width-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  #root .homepage-title {
    display: none;
  }
  #root .promoted-card.big {
    margin-left: -15px;
    margin-right: -15px;
  }
  #root .promoted-card.big .btn-cnt {
    opacity: 1;
  }
  #root .promoted {
    /* width: 91%; */
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
  }
  #root .promoted-card {
    margin-bottom: 25px;
  }
  #root .promoted-card:first-child {
    margin-right: 0;
  }
  #root .pr-img img {
    width: 100%;
    height: auto;
  }
  #root .btn-cnt {
    height: calc(100% - 50px);
  }
  #root .pr-info {
    height: 45px;
  }
  #root .recommended-block {
    /* width: 91%; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    margin-bottom: 0;
  }
  #root .page-title {
    font-size: 18px;
    line-height: 21px;
  }
  #root .back-button {
    margin-top: 32px;
  }
  #root .big-banner {
    margin-bottom: 20px;
  }
  #root .tournament-single-header {
    display: block;
  }
  #root .tournament-single-header .page-title.top-of-the-page {
    margin-bottom: 15px;
  }
  #root .tournament-single-header .tournament-date {
    margin-top: 0;
  }
  #root .tournament-single-btns-container {
    display: block;
  }
  #root .tournament-single-prize-block {
    margin-bottom: 15px;
    justify-content: space-between;
  }
  #root .recommended-block .arrows {
    display: none;
  }
  #root .rec-games-block {
    overflow: scroll;
  }
  #root .rec-card {
    /* flex: 0 0 140px; */
    margin-right: 10px;
  }
  #root .rec-card img {
    width: 100%;
    /* margin-top: 10px; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 155px;
    border-radius: 3px;
  }
  #root .rec-card p {
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 17px;
  }
  #root .rec-card span {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 14px;
  }
  #root .rec-card > p,
  #root .rec-card span {
    padding: 0 8px;
  }
  #root .rec-card .playnow-btn {
    width: 100%;
    height: 46.5px;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border-radius: 4px;
  }
  #root .rec-card:hover .playnow-btn {
    border-radius: 0 0 4px 4px;
  }
  #root .playnow-btn p {
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
  }
  #root .jackpot-btn-container {
    width: 75px;
    height: 20px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: absolute;
    bottom: 42px;
    right: calc(50% - 38px);
  }
  #root .jackpot-btn-container p {
    font-size: 10px;
    line-height: 12px;
  }
  #root .jackpot-btn-container p img {
    height: 15px;
  }
  #root .games-block .title-w-filter,
  #root .games-page-container .title-w-filter {
    margin-bottom: 24px;
    flex-wrap: nowrap;
  }
  #root .homepage .games-block .title-w-filter .page-title,
  #root .homepage .games-page-container .title-w-filter .page-title {
    font-size: 28px;
    line-height: 34px;
  }
  #root .games-block .title-w-filter ion-searchbar,
  #root .games-page-container .title-w-filter ion-searchbar {
    width: inherit;
    max-width: 65%;
    min-width: inherit;
    margin-bottom: 0;
    margin-left: 10px;
  }
  #root .filter-segment {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-bottom: 10px;
    border-radius: 3px;
    width: 100%;
    max-width: 767px;
  }
  #root .filter-segment ion-segment-button {
    height: 47px;
    --width: 50px;
  }
  #root .games-container {
    display: grid;
    /* grid-template-columns: repeat(2, minmax(138px, 1fr)); */
    grid-template-columns: repeat(auto-fill, minmax(137px, 1fr));
    grid-gap: 15px !important;
  }
  #root .game-preview {
    width: 100%;
    height: 100px;
    /* height: auto; */
    /* min-height: 100px; */
    /* height: fit-content; */
    /* max-height: max-content; */
    /* object-fit: cover; */
    /* max-height: 180px; */
    /* overflow: hidden; */
    margin-bottom: auto;
  }
  #root .game-card .game-info {
    height: 48px;
    position: relative;
    padding-left: 7px;
    padding-right: 7px;
    justify-content: center;
  }
  #root .game-card .game-info p {
    font-size: 12px;
    line-height: 14px;
    margin: 0;
    max-width: 100%;
    margin-bottom: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #root .game-card .game-info span {
    font-size: 10px;
    line-height: 12px;
    max-width: 70%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #root .game-card .provider-logo {
    width: 40px;
    height: auto;
    right: 3px;
    bottom: 3px;
  }
  #root .fav-block {
    background: var(--fav-bg);
    width: 20px;
    height: 20px;
  }
  #root .fav {
    width: 10px;
    height: 9px;
  }
  #root .btn-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 48px);
  }
  #root .game-card .not-available-text {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  #root .play,
  #root .demo {
    max-width: 66%;
  }
  #root .leader-board-container .leader-board-table {
    min-height: 571px;
  }
  #root .table-swiper {
    max-height: 500px;
  }
  #root .leader-board-container.account-table .leader-board-header {
    justify-content: center;
  }
  #root .leader-board-container.account-table.affiliate-table .leader-board-table .leader-board-table-head div,
  #root .leader-board-container.account-table.affiliate-table .leader-board-table .swiper-slide .address-title {
    width: 50%;
  }
  #root .leader-board-container.account-table.affiliate-table .leader-board-table .leader-board-table-head div {
    height: 55px;
  }
  #root .games-page-container .page-title {
    /* margin-top: 25px; */
    /* margin-bottom: 25px; */
    margin-top: 0;
    margin-bottom: 10px;
  }
  #root .games-page-container .categories-filter-container {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }
  #root .games-page-container ion-segment {
    margin-top: 0;
    margin-bottom: 10px;
  }
  #root .categories-filter.animate .categories-filter-container,
  #root .categories-filter.animate .swiper-container {
    animation: 0.6s leftFilter ease;
  }
  @keyframes leftFilter {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-30px);
    }
    100% {
      transform: translateX(0);
    }
  }
  #root .categories-filter-container {
    overflow: auto;
    /* width: 100%; */
    /* height: 55px; */
    /* height: auto; */
    margin-bottom: 24px;
  }
  #root .categories-filter-container.slots-page {
    /* height: 55px; */
    height: auto;
  }
  #root .categories-filter-container .slot-tag {
    min-width: 84px;
  }
  #root .subcategories-filter-container {
    margin-bottom: 24px;
  }
  #root .categories-filter .subcategories-filter-container .swiper,
  #root .categories-filter .subcategories-filter-container .swiper-container {
    margin-bottom: 24px;
  }
  #root .slot-tag {
    /* min-width: 60px; */
    padding-left: 10px;
    padding-right: 10px;
    /* height: 35px; */
    /* margin-left: 10px; */
    /* z-index: 99; */
  }
  #root .slot-tag:first-child {
    margin-left: 0;
  }
  #root .tag-img {
    /* margin-top: -18px; */
    margin-top: 0;
  }
  #root .tournaments-container {
    /* grid-template-columns: repeat(1, minmax(100%, 1fr)); */
    /* grid-template-columns: repeat(auto-fill, minmax(290px, 335px)); */
    /* justify-content: center; */
  }
  #root .tournament-card {
    overflow: hidden;
    flex-direction: column;
    border: none;
  }
  #root .tournament-top {
    width: 100%;
    /* height: 268px; */
    min-height: 268px;
    height: auto;
    overflow: hidden;
    border: none;
  }
  #root .tournament-card:hover {
    /* border: 2px solid var(--green); */
    border: none;
  }
  #root .tournament-card:hover .tournament-top {
    /* border-bottom: 2px solid var(--green); */
    border: none;
  }
  #root .tournament-preview {
    width: 100%;
  }
  #root .tournament-card .btn-container {
    width: 100%;
    height: calc(100% - 160px);
  }
  #root .tournament-card .tournament-info {
    height: 165px;
    padding: 30px 25px 10px 25px;
  }
  #root .tournament-card .tournament-date span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: initial;
  }
  #root .tournament-card .prize-block {
    margin-top: 10px;
  }
  #root .tournament-card .prize-btn {
    padding: 6px 25px;
  }
  #root .tournament-count {
    position: absolute;
    top: calc(100% - 183px);
    /* left: 13px; */
  }
  #root .tournament-count span {
    padding: 8px 28px;
  }
  #root .bonuses-container {
    /* grid-template-columns: repeat(1, minmax(100%, 1fr)); */
  }
  #root .bonus-card {
    border: none;
  }
  #root .bonus-top {
    width: 100%;
    /* height: 268px; */
    height: auto;
    overflow: hidden;
    /* border: 2px solid transparent; */
    border: none;
  }
  #root .bonus-card:hover {
    /* border: 2px solid var(--green); */
    border: none;
  }
  #root .bonus-card:hover .bonus-top {
    /* border-bottom: 2px solid var(--green); */
    border: none;
  }
  #root .bonus-preview {
    width: 100%;
  }
  #root .bonus-card .btn-container {
    width: 100%;
    height: calc(100% - 80px);
  }
  #root .bonus-card .bonus-info {
    height: 80px;
    border: none;
    /* padding: 0 28px; */
  }
  #root .providers-block {
    grid-template-columns: repeat(2, minmax(139px, 1fr));
    grid-gap: 15px !important;
  }
  #root .prov-card {
    /* max-width: 400px; */
  }
  #root .prov-card .prov-img {
    width: 100%;
    height: 100px;
    /* min-height: 100px; */
    /* height: auto; */
    margin-bottom: auto;
  }
  #root .prov-img img {
    width: 35%;
    height: auto;
  }
  #root .prov-card .prov-discription {
    height: 50px;
    padding: 12px 8px 10px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  #root .prov-card .prov-name {
    font-size: 12px;
    line-height: 14px;
  }
  #root .prov-card .prov-count {
    font-size: 10px;
    line-height: 12px;
    margin-top: 2px;
  }
  #root .prov-card .playnow-btn {
    height: 50px;
  }
  #root .affiliate-fields-container .no-entries-container {
    margin-top: 30px;
  }
  #root .balance-title.affiliate-title {
    margin-bottom: 20px;
  }
  #root .affiliate-content .refferal-link-field {
    height: auto;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
  }
  #root .refferal-link-field svg {
    min-width: 16px;
  }
  #root .affiliate-content .affiliate-content-note-container {
    margin-top: 20px;
  }
  #root .no-entries-container .no-entries-label.big {
    font-size: 16px;
    line-height: 19px;
  }
  #root .no-entries-container .no-entries-label {
    font-size: 14px;
    line-height: 16px;
  }
  #root .deposites-container.exchange {
    margin-right: 0;
  }
  #root .balance-fields-deposites-container .rates-container {
    min-width: inherit;
    width: 100%;
  }
  #root .balance-fields-deposites-container .rates-body-item-name {
    max-width: 100px;
  }
  #root .balance-fields-container .calculated {
    margin-top: 15px;
    margin-left: 0;
    font-size: 14px;
    line-height: 17px;
  }
  #root .balance-fields-container .calculated span {
    opacity: 0.5;
  }

  #root .premium-vip-container {
    height: 510px;
    padding: 25px;
    justify-content: center;
    align-items: flex-start;
  }
  #root .premium-vip-container.home-page {
    margin-top: 20px;
    margin-bottom: 70px;
  }
  #root .premium-vip-content {
    width: 100%;
  }
  #root .premium-vip-content.premium {
    width: 100%;
  }
  #root .mobile-wrapper-premium-vip-content-top {
    margin-bottom: 25px;
    justify-content: space-between;
  }
  #root .mobile-wrapper-premium-vip-content-top .premium-vip-content-top {
    width: 50%;
  }
  #root .premium-vip-content-top {
    margin-bottom: 0;
    flex-direction: column;
    justify-content: center;
  }
  #root .premium-vip-content-top .userpic {
    width: 44px;
    height: 44px;
    margin: 0;
    margin-bottom: 8px;
  }
  #root .premium-vip-title {
    margin-bottom: 15px;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
  }
  #root .premium .premium-vip-title {
    font-size: 14px;
    line-height: 20px;
  }
  #root .premium-vip-rank {
    width: 140px;
    min-width: 140px;
    height: 140px;
    padding: 10px;
    margin: 0;
    /* flex-direction: row; */
    /* justify-content: center; */
    /* align-items: center; */
  }
  #root .promo-vip-rank-pic {
    width: 90px;
    height: 90px;
    margin: 0;
  }
  #root .promo-vip-rank-label {
    font-size: 18px;
    line-height: 24px;
  }
  #root .premium-vip-rank .info-icon {
    width: 20px;
  }
  #root .premium .premium-vip-progress-top {
    width: 100%;
    font-size: 20px;
    line-height: 30px;
  }
  #root .premium-vip-progress-top.greetings {
    width: auto;
    margin-bottom: 25px;
    align-items: center;
  }
  #root .premium .premium-vip-progress-center {
    margin-top: 15px;
    margin-bottom: 20px;
    align-self: center;
  }
  #root .premium-vip-progress-center-item img {
    width: 20px;
    margin-right: 6px;
  }
  #root .premium-vip-progress-center-item span {
    font-size: 14px;
    /* line-height: 20px; */
  }
  #root .premium .premium-vip-progress-bottom {
    flex-direction: column;
  }
  #root .premium .premium-vip-progress-bottom-item:first-of-type {
    margin-bottom: 10px;
    margin-right: 0;
  }
  #root .premium .premium-vip-progress-bottom-item {
    justify-content: center;
  }
  #root .premium-vip-bg-image {
    width: 260px;
    height: 260px;
    bottom: -90px;
    right: inherit;
  }
  #root .table-adaptive-row.center {
    justify-content: center;
  }
  #root .balance-fields-wrapper.w100 .input-container {
    margin-left: 0;
    margin-right: 0;
  }
  #root .affiliate-content-top .funds-withdraw-btn {
    width: 100%;
    max-width: inherit;
  }
  #root .section-message .section-message-big-text {
    text-align: left;
  }
  #root .title-w-filter .page-title.jackpot {
    align-self: flex-start;
  }
  #root .title-w-filter ion-searchbar {
    /* width: 100%; */
    /* width: 190px; */
    /* min-width: 190px; */
    margin-left: auto;
    margin-bottom: 10px;
    /* order: 1; */
    /* align-self: flex-start; */
  }
  #root .terms.ios-app-setup .page-title {
    margin-bottom: 10px;
  }
  #root .ios-app-setup .ios-app-setup-subtitle {
    font-size: 16px !important;
  }
  #root .ios-app-setup .ios-app-setup-content {
    min-height: 505px;
    margin-top: 100px;
    padding: 205px 10px 30px;
    justify-content: center;
  }
  #root .ios-app-setup .ios-app-setup-content-left {
    width: 100%;
  }
  #root .ios-app-setup .ios-app-setup-content-right {
    width: 292px;
    height: 296px;
    top: -100px;
    right: inherit;
  }
  #root .ios-app-setup .step-item {
    font-size: 16px !important;
  }
  #root .ios-app-setup .ios-app-setup-content-title {
    margin-bottom: 15px;
    font-size: 18px !important;
  }
  #root .ios-app-setup .ios-app-setup-content-description {
    font-size: 16px !important;
  }
  #root .ios-app-setup .ios-app-setup-content-btns {
    margin-top: 20px;
  }
  #root .cashback-success-modal .mod-confirm .modal-data {
    padding: 35px 20px;
  }
  #root .cashback-success-modal .cashback-success-modal-left {
    width: 100%;
    max-width: 262px;
    margin-bottom: 25px;
  }
  #root .cashback-success-modal-left .cashback-amount-top {
    font-size: 28px;
  }
  #root .cashback-success-modal-left .cashback-amount-bottom {
    font-size: 16px;
  }
  #root .cashback-success-modal-right .promo-modal-title {
    margin-bottom: 10px;
    font-size: 20px;
  }
  #root .cashback-success-modal-right .promo-modal-text {
    font-size: 16px;
  }
  #root .how-to-charge-modal .modal-header {
    height: 50px;
    font-size: 18px;
    line-height: 24px;
  }
  #root .how-to-charge-modal .modal-block {
    padding: 24px;
  }
  #root .how-to-charge-modal .modal-block-bottom {
    margin-right: 0;
  }
  #root .how-to-charge-modal .modal-block-bottom-title {
    margin-bottom: 16px;
    align-items: center;
    font-size: 22px;
    line-height: 28px;
  }
  #root .how-to-charge-modal .modal-block-bottom-title .modal-block-top-img {
    width: 32px;
    margin-right: 16px;
  }
  #root .how-to-charge-modal button.btn,
  #root .how-to-charge-modal button.btn-with-icon {
    height: 50px;
  }
  #root .how-to-charge-modal .modal-block ion-item.header-balance {
    max-width: 450px;
  }
  #root .game-container {
    min-width: 90%;
    height: 85vh;
  }
  #root .game-center-wrapper {
    height: 100%;
    height: calc(100% - 105px);
    padding-top: 0;
  }
  #root .game-canvas {
    height: 100%;
  }
  #root .game-container .game-header-title {
    max-width: 200px;
  }
  #root .crashpage .crash-video-container {
    margin-bottom: 10px;
  }
  #root .crashpage .crash-video-title {
    width: 100%;
    min-width: inherit;
    padding: 0 5px;
    margin-bottom: 40px;
    position: inherit;
    font-size: 26px;
    line-height: 32px;
  }
  #root .crashpage .crash-game-container {
    min-height: 510px;
    padding: 25px;
    pointer-events: none;
  }
  #root .crashpage .crash-game-container-left {
    min-width: 125px;
    width: 125px;
    position: absolute;
    top: 25px;
    left: 10px;
  }
  #root .crashpage .crash-game-container-center {
    width: 100%;
    margin: 0;
    margin-top: 200px;
  }
  #root .crashpage .crash-game-container-right {
    width: 220px;
    min-width: 220px;
    position: absolute;
    right: 0;
    top: 25px;
  }
  #root .crashpage .crash-game-big-planet {
    width: 200px;
    height: 200px;
    top: -75px;
    left: -45px;
  }
  #root .crashpage .crash-game-small-planet {
    width: 100px;
    height: 100px;
    right: -50px;
    bottom: inherit;
  }
  #root .crashpage .crash-game-container.yellow .crash-game-small-planet {
    width: 180px;
    right: -90px;
  }
  #root .crashpage .crash-game-title {
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 28px;
  }
  #root .crashpage .crash-game-description {
    font-size: 16px;
    line-height: 24px;
  }
  #root .crashpage .crash-subscribe-container {
    height: 346px;
    padding: 30px 25px 25px;
    flex-direction: column;
  }
  #root .crashpage .crash-subscribe-left {
    flex-direction: column;
  }
  #root .crashpage .crash-subscribe-left svg {
    height: 70px;
    margin-right: 0;
    margin-bottom: 16px;
  }
  #root .crashpage .crash-subscribe-title {
    font-size: 22px;
    line-height: 28px;
    text-align: center;
  }
  #root .crashpage .crash-subscribe-description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  #root .crashpage .crash-subscribe-right {
    width: 100%;
  }
  #root .crashpage .crash-subscribe-right .input-container {
    max-width: 100%;
  }
  #root .crashpage .crash-subscribe-right .input-container input {
    height: 50px;
  }
}

@media screen and (max-width: 1021px) {
  #root .balance-fields-container .max-min-deposit-cont {
    top: 220px;
  }
}

@media screen and (max-width: 450px) {
  #root .prov-img img {
    width: 55%;
    height: auto;
  }

#root .game-container .game-header {
 padding: 0px 5px 0px 10px;
 /* height: 65px; */
}



  #root .game-container .btn-logo-link-cont .text-logo-link.no-fullscreen {
    font-size: 130px;
    margin-right: 12px;
  }
  #root .game-container .btn-logo-link-cont .btn-logo-link.no-fullscreen {
    height: 35px;
    width: 110px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }
}
@media screen and (max-width: 1599px) {
  #root .header-container .balance-block {
    justify-content: center;
  }
  #root .header-container .balance-block .balance-div.bonus:not(.second),
  #root .header-container .balance-block .balance-div.freespin {
    display: none;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1399px) {
  #root .header-container {
    padding-left: 13px;
  }
  #root .menu-btn-container {
    margin-right: 13px;
  }
}
@media screen and (min-width: 1400px) {
  #root .menu-btn-container {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  #root {
    /* Header */
    /* .menu-btn-container ion-menu-button::part(native) {
          padding: 0;
          width: 24px;
          height: 24px;
        } */
    /* Footer */
    /* .footer-container-socials a svg {
          fill: var(--green) !important;
        } */
    /* .footer-container-links div:first-child {
          margin-left: 0;
        } */
    /* .footer-logos-bottom .im-casino {
          width: 41px;
          height: 41px;
        } */
    /* .logo-bottom-right a:first-child {
          margin-right: 50%;
        } */
    /* Homepage */
    /* .promoted-card:first-child {
          margin-right: 10px;
        } */
    /* .games-block .title-w-filter {
          margin-bottom: 20px;
        } */
    /* .jackpots-margin {
          margin-top: 40px;
        } */
    /* Games */
    /* .games-page-container ion-segment {
          margin-top: 20px;
          margin-bottom: 10px;
        } */
    /* .categories-filter-container {
          overflow: auto;
          width: 100%;
          height: auto;
          padding-top: 5px;
          padding-bottom: 5px;
        } */
    /* .categories-filter-container.slots-page {
          width: 100%;
          height: auto;
        } */
    /* .slot-tag p {
          font-size: 14px;
          line-height: 17px;
        } */
    /* Tournaments */
    /* .tournament-top {
          width: 100%;
          min-height: 268px;
          height: auto;
          overflow: hidden;
          border: none;
        } */
    /* Bonuses */
    /* Providers */
    /* .premium-vip-progress-bottom {
          justify-content: space-between;
        } */
    /* Game page */
    /* .game-container {
          min-width: 75%;
          height: 85vh;
        } */
    /* Crash page */
    /* .crashpage .crash-video-container video {
          height: 387px;
        } */
  }
  #root .header-container {
    width: 100%;
    height: 75px;
    padding: 0 22px 0 0;
    justify-content: start;
  }
  #root .menu-btn-container {
    width: 55px;
    min-width: 55px;
    height: 100%;
    margin: 0;
  }
  #root .logo-link {
    /* background: url(../images/logo-link-header.svg); */
    width: 157.45px;
    min-width: 157.45px;
    height: 40px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  #root ion-toggle.daymode-toggle {
    margin-left: 5px;
    /* margin-right: 0; */
  }
  #root .unlogged {
    margin-left: auto;
    /* margin-right: 22px; */
  }
  #root .header-container .lang-field {
    margin-right: 10px;
  }
  #root .login-btn,
  #root .register-btn {
    height: 40px;
  }
  #root .logged {
    margin-left: auto;
    /* margin-right: 22px; */
  }
  #root .back-button {
    margin-top: 32px;
  }
  #root ion-item.header-balance {
    min-width: 200px;
    width: 200px;
  }
  #root .hline {
    margin-left: 10px;
    margin-right: 10px;
  }
  #root .user-popover.user-menu .popover-content,
  #root .user-popover.user-menu::part(content) {
    width: 362px;
  }
  #root .user-menu-popover-header .username {
    font-size: 14px;
    line-height: 20px;
  }
  #root .user-menu-popover-body-item {
    padding: 12px;
  }
  #root .user-menu-popover-body-item .premium-vip-rank {
    margin-right: 12px;
  }
  #root .user-menu-popover-progress-container .premium-vip-progress-top {
    font-size: 14px;
    line-height: 20px;
  }
  #root .user-menu-popover-body-item-title {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 20px;
  }
  #root .user-menu-popover-body-item .gift-img {
    margin-right: 8px;
  }
  #root .user-menu-popover-body-item.promo-item .user-menu-popover-body-item-label {
    font-size: 14px;
  }
  #root .user-menu-popover-body-item.grid {
    gap: 24px 5px;
  }
  #root .user-menu-popover-body .user-menu-popover-body-item p a,
  #root .user-menu-popover-body-item.logout-item .user-menu-popover-body-item-label {
    font-size: 14px;
    line-height: 20px;
  }
  #root .footer-container {
    width: 100%;
    margin: 0 auto;
  }
  #root .footer-container-top {
    height: 65px;
  }
  #root .footer-container-top img {
    width: 95px;
    height: auto;
    /* padding: 5px 10px; */
  }
  #root .footer-container-body {
    padding-top: 20px;
    padding-left: 65px;
    padding-right: 50px;
  }
  #root .footer-container-body-top {
    margin-bottom: 20px;
  }
  #root .footer-container-socials a {
    width: 35px;
    height: 35px;
    margin-left: 15px;
  }
  #root .footer-container-socials a svg {
    width: 60%;
    height: 60%;
  }
  #root .footer-container-links {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  #root .footer-container-links div {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 20px; */
    /* margin-right: 70px; */
    margin-left: 0;
    margin-bottom: 20px;
  }
  #root .footer-container-links div:nth-child(odd) {
    width: 45%;
    margin-left: 0;
    margin-right: 5%;
  }
  #root .footer-container-links div:nth-child(even) {
    width: 50%;
  }
  #root .footer-container-links div a {
    margin-top: 0;
    font-size: 14px;
    line-height: 40px;
  }
  #root .footer-container-links div a.help {
    margin-top: 0;
  }
  #root .footer-logos-bottom {
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  #root .footer-logos-bottom > div {
    /* width: 27%; */
    padding: 0;
  }
  #root .footer-logos-bottom .logos-bottom-left {
    display: flex;
  }
  #root .footer-logos-bottom .eighteen {
    /* width: 41px; */
    /* height: 41px; */
    /* margin-right: 50%; */
  }
  #root .footer-logos-bottom .logos-bottom-center {
    text-align: center;
    width: auto;
  }
  #root .footer-logos-bottom .ridotto {
    width: 80px;
    height: auto;
  }
  #root .footer-logos-bottom .logo-bottom-right {
    height: 100%;
    display: flex;
    /* -webkit-box-pack: end; */
    /* -ms-flex-pack: end; */
    /* justify-content: flex-end; */
    padding-right: 0;
  }
  #root .footer-logos-bottom .to-gov {
    /* width: 46px; */
    /* height: 46px; */
  }
  #root .footer-logos-bottom .to-kkk {
    /* width: 40px; */
    /* height: 46px; */
    margin-left: 0;
    margin-right: 0;
  }
  #root .footer-copyright {
    font-size: 10px;
    padding: 15px 0;
  }
  #root .width-container {
    padding-left: 40px;
    padding-right: 40px;
  }
  #root .page-title {
    font-size: 20px;
    line-height: 24px;
  }
  #root .pr-info {
    height: 40px;
  }
  #root .pr-info p {
    margin-left: 20px;
  }
  #root .pr-info span {
    margin-right: 20px;
    font-size: 20px;
    line-height: 35px;
  }
  #root .btn-cnt {
    height: calc(100% - 40px);
  }
  #root .recommended-block {
    margin-top: 40px;
    margin-bottom: 0;
  }
  #root .arrows div {
    width: 20px;
    height: 20px;
    margin-left: 7px;
  }
  #root .arrows div span {
    width: 5px;
    height: 8px;
  }
  #root .rec-card {
    /* flex: 0 0 160px; */
    flex: inherit;
    /* width: 24%; */
    width: 32%;
  }
  #root .rec-card p {
    font-size: 16px;
    line-height: 19px;
  }
  #root .rec-card span {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 14px;
  }
  #root .rec-card > p,
  #root .rec-card span {
    padding: 0 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #root .rec-card .playnow-btn {
    width: 100%;
    height: 46.5px;
    opacity: 1;
    border-radius: 4px;
  }
  #root .rec-card:hover .playnow-btn {
    border-radius: 0 0 4px 4px;
  }
  #root .playnow-btn p {
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
  }
  #root .big-banner {
    margin-top: 40px;
    margin-bottom: 0;
  }
  #root .games-block {
    margin-top: 20px;
    margin-bottom: 25px;
  }
  #root .filter-segment {
    width: 100%;
    /* max-width: 520px; */
    margin-left: 0;
  }
  #root .games-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(215px, 1fr));
    grid-gap: 20px !important;
  }
  #root .game-card {
    max-width: 300px;
  }
  #root .game-preview {
    width: 100%;
    /* height: 100px; */
    /* height: auto; */
    /* min-height: 100px; */
    /* height: fit-content; */
    /* max-height: max-content; */
    /* object-fit: cover; */
    /* max-height: 180px; */
    /* overflow: hidden; */
    margin-bottom: auto;
    /* width: 245px; */
    /* height: 174px; */
  }
  #root .btn-container {
    width: 100%;
    height: calc(100% - 76px);
  }
  #root .game-card .game-info {
    height: 76px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    justify-content: flex-start;
  }
  #root .game-card .game-info p {
    font-size: 16px;
    line-height: 19px;
  }
  #root .game-card .game-info span {
    font-size: 14px;
    line-height: 17px;
  }
  #root .game-card .provider-logo {
    width: 50px;
    height: auto;
    right: 5px;
    bottom: 5px;
  }
  #root .fav-block {
    width: 30px;
    height: 30px;
  }
  #root .fav {
    width: 15px;
    height: 14px;
  }
  #root .more-games {
    max-width: 170px;
  }
  #root .jackpot-btn-container {
    width: 115px;
    height: 30px;
    bottom: 66px;
    right: calc(50% - 58px);
  }
  #root .jackpot-btn-container p {
    font-size: 14px;
    line-height: 17px;
  }
  #root .games-page-container .page-title {
    /* margin-top: 30px; */
    /* margin-bottom: 25px; */
    margin-top: 0;
    margin-bottom: 10px;
  }
  #root .games-page-container .categories-filter-container {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }
  #root .games-page-container ion-segment {
    margin: 0;
  }
  #root .slot-tag {
    /* height: 30px; */
    margin-left: 10px;
  }
  #root .slot-tag:first-child {
    margin-left: 0;
  }
  #root .tag-img {
    margin-top: 0;
  }
  #root .tournaments-container {
    /* grid-template-columns: repeat(2, minmax(335px, 1fr)); */
    /* grid-template-columns: repeat(auto-fill, 336px); */
    /* justify-content: center; */
  }
  #root .tournament-card {
    overflow: hidden;
    flex-direction: column;
    border: none;
  }
  #root .tournament-card:hover {
    /* border: 2px solid var(--green); */
    border: none;
  }
  #root .tournament-card:hover .tournament-top {
    /* border-bottom: 2px solid var(--green); */
    border: none;
  }
  #root .tournament-preview {
    width: 100%;
    height: 306px;
  }
  #root .tournament-card .btn-container {
    width: 100%;
    height: calc(100% - 185px);
  }
  #root .tournament-card .tournament-info {
    height: 185px;
    padding: 30px 10px 10px 25px;
  }
  #root .tournament-card .tournament-date span {
    max-width: 70%;
    width: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: initial;
  }
  #root .tournament-card .tournament-date span:first-child {
    margin-right: 0px;
  }
  #root .tournament-card .prize-block {
    margin-top: auto;
  }
  #root .tournament-count {
    top: calc(100% - 205px);
    /* left: 10px; */
  }
  #root .bonuses-container {
    /* grid-template-columns: repeat(2, minmax(331px, 1fr)); */
  }
  #root .bonus-card {
    border: none;
  }
  #root .bonus-top {
    width: 100%;
    /* height: 308px; */
    /* height: auto; */
    overflow: hidden;
    /* border: 2px solid transparent; */
    border: none;
  }
  #root .bonus-card:hover {
    /* border: 2px solid var(--green); */
    border: none;
  }
  #root .bonus-card:hover .bonus-top {
    /* border-bottom: 2px solid var(--green); */
    border: none;
  }
  #root .bonus-preview {
    width: 100%;
    height: 308px;
  }
  #root .bonus-preview p {
    height: 100%;
  }
  #root .bonus-preview p img {
    height: 100%;
  }
  #root .bonus-card .btn-container {
    width: 100%;
    height: calc(100% - 90px);
  }
  #root .bonus-card .bonus-info {
    height: 90px;
    border: none;
  }
  #root .bonus-card .bonus-info p {
    margin-bottom: 0;
  }
  #root .providers-block {
    grid-template-columns: repeat(3, minmax(214px, 1fr));
    grid-gap: 20px !important;
  }
  #root .prov-card {
    max-width: 400px;
  }
  #root .prov-card .prov-img {
    width: 100%;
    /* height: 100px; */
    /* min-height: 100px; */
    /* height: auto; */
    max-height: 155px;
    margin-bottom: auto;
  }
  #root .prov-img img {
    width: 60%;
    height: auto;
  }
  #root .prov-card .prov-discription {
    height: 75px;
    padding: 20px 10px 10px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  #root .prov-card .prov-name {
    font-size: 16px;
    line-height: 19px;
  }
  #root .prov-card .prov-count {
    font-size: 14px;
    line-height: 17px;
    margin-top: 3px;
  }
  #root .prov-card .playnow-btn {
    height: 75px;
  }
  #root .list-provs {
    margin-bottom: 20px;
  }
  #root .balance-title.affiliate-title {
    margin-bottom: 30px;
  }
  #root .premium-vip-container {
    height: 230px;
    padding: 15px;
  }
  #root .premium-vip-container.home-page {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  #root .promo-vip-rank-pic {
    width: 120px;
    height: 120px;
  }
  #root .premium-vip-rank {
    width: 164px;
    margin-right: 15px;
  }
  #root .promo-vip-rank-label {
    font-size: 20px;
    line-height: 30px;
  }
  #root .premium-vip-content {
    width: 50%;
  }
  #root .premium-vip-content.premium {
    width: 53%;
  }
  #root .premium-vip-content-top {
    margin-bottom: 15px;
  }
  #root .premium-vip-content-top .userpic {
    width: 28px;
    height: 28px;
  }
  #root .premium-vip-title {
    font-size: 14px;
    line-height: 20px;
  }
  #root .premium-vip-progress-top {
    font-size: 20px;
    line-height: 30px;
  }
  #root .premium .premium-vip-progress-top,
  #root .premium .premium-vip-progress-bar {
    width: 80%;
  }
  #root .premium-vip-progress-center {
    margin-top: 15px;
  }
  #root .premium-vip-progress-center-item img {
    width: 20px;
    margin-right: 6px;
  }
  #root .premium-vip-progress-center-item span {
    font-size: 14px;
    /* line-height: 20px; */
  }
  #root .premium-vip-progress-bottom-item:first-of-type {
    margin-right: 24px;
  }
  #root .premium-vip-bg-image {
    width: 260px;
    height: 260px;
    /* top: -6%; */
    right: -35px;
  }
  #root .balance-fields-wrapper.w100 {
    justify-content: space-between;
  }
  #root .balance-fields-wrapper.w100 .input-container {
    width: 48%;
    max-width: inherit;
    margin-right: 0;
  }
  #root .title-w-filter .page-title.jackpot {
    margin-top: 0;
    margin-bottom: 10px;
  }
  #root .title-w-filter ion-searchbar {
    margin-left: auto;
    margin-bottom: 10px;
    min-width: 185px;
  }
  #root .crashpage .crash-content {
    margin-bottom: 50px;
  }
  #root .crashpage .crash-video-title {
    width: 100%;
    font-size: 38px;
    line-height: 42px;
  }
  #root .crashpage .crash-game-container {
    /* height: 516px; */
    min-height: 560px;
    padding: 35px;
    pointer-events: none;
  }
  #root .crashpage .crash-game-container-left {
    min-width: 190px;
    width: 190px;
    position: absolute;
    top: 35px;
  }
  #root .crashpage .crash-game-container-center {
    width: 100%;
    margin: 0;
    margin-top: auto;
  }
  #root .crashpage .crash-game-container-right {
    width: 370px;
    right: 50px;
    position: absolute;
    top: 35px;
  }
  #root .crashpage .crash-game-small-planet {
    right: -100px;
    bottom: inherit;
  }
  #root .crashpage .crash-game-container.yellow .crash-game-small-planet {
    width: 360px;
    right: -180px;
  }
  #root .crashpage .crash-game-title {
    font-size: 28px;
    line-height: 36px;
  }
  #root .crashpage .crash-subscribe-container {
    height: 346px;
    padding: 35px;
    flex-direction: column;
  }
  #root .crashpage .crash-subscribe-left {
    flex-direction: column;
  }
  #root .crashpage .crash-subscribe-left svg {
    margin-right: 0;
    margin-bottom: 16px;
  }
  #root .crashpage .crash-subscribe-title {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
  }
  #root .crashpage .crash-subscribe-description {
    text-align: center;
  }
  #root .crashpage .crash-subscribe-right {
    width: 80%;
    align-self: center;
  }
  #root .crashpage .crash-subscribe-right .input-container {
    max-width: 100%;
  }
  #root .promo-modal-title-text {
    font-size: 20px;
    line-height: 30px;
  }
  #root .promo-modal-title-info {
    font-size: 14px;
    line-height: 20px;
  }
  #root .promo-modal-title-info img {
    width: 20px;
    height: 20px;
    margin: 0 6px;
  }
  #root .аchieves-item {
    font-size: 14px;
    line-height: 20px;
  }
  #root .аchieves-item svg {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
}
@media screen and (min-width: 1141px) {
  #root .premium-horizontal-container .premium-money-container {
    width: 40%;
    max-width: 40%;
  }
  #root .premium-horizontal-container .premium-checklist {
    width: 60%;
    max-width: 60%;
  }
}
@media screen and (max-width: 1140px) {
  #root {
    /* Premium */
  }
  #root .premium-vertical-container,
  #root .premium-horizontal-container {
    display: grid;
    grid-template-columns: repeat(2, 257px);
    justify-content: center;
    grid-gap: 10px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  #root .premium-horizontal-container .premium-money-container,
  #root .premium-horizontal-container .premium-checklist {
    padding: 0;
  }
  #root .premium-horizontal-container .premium-checklist {
    -moz-columns: 1;
    columns: 1;
  }
  #root .premium-vertical-container {
    margin: 0 auto 10px;
  }
  #root .premium-horizontal-container {
    margin: 0 auto 50px;
  }
  #root .premium-horizontal-block {
    flex-direction: column;
    margin-bottom: 0;
  }
  #root .premium-horizontal-left {
    margin-bottom: 23px;
    border-bottom: 2px solid var(--pr-card-br);
  }
  #root .premium-horizontal-right {
    flex-direction: column;
    align-items: flex-start;
  }
  #root .premium-horizontal-container .premium-checklist {
    padding-left: 30px;
  }
  #root .premium-horizontal-title {
    min-width: 100%;
    padding: 25px 80px 12px 0;
    text-align: left;
  }
  #root .special-benefits-container {
    padding: 32px;
  }
  #root .premium-platinum .premium-horizontal-left,
  #root .premium-diamond .premium-horizontal-left,
  #root .premium-exclusive .premium-horizontal-left {
    background-image: none;
  }
  #root .premium-platinum,
  #root .premium-diamond,
  #root .premium-exclusive {
    background-repeat: no-repeat;
  }
  #root .premium-platinum {
    background-image: url(../images/premium-icons/premium-platinum.svg);
    background-position: right 30px top 0;
  }
  #root .premium-diamond {
    background-image: url(../images/premium-icons/premium-diamond.svg);
    background-position: right 30px top 20px;
  }
  #root .premium-exclusive {
    background-image: url(../images/premium-icons/premium-exclusive.svg);
    background-position: right 30px top 13px;
  }
}
@media screen and (max-width: 1024px) {
  #root {
    /* Menu */
    /* Home */
    /* .slot-tag p {
          font-size: 16px;
          line-height: 26px;
        } */
    /* LeaderBoard */
    /* Auth modal */
    /* Register final modal */
    /* Forget password modal */
    /* Logout modal */
    /* Cookie */
    /* Forbidden modal */
    /* player-info-modal */
    /* live-chat-rules-modal */
    /* bingo-coin-modal */
    /* Cashback Success Modal */
    /* How To Charge Modal */
    /* Account Details  */
    /* Personal Info */
    /* Games Limit */
    /* Multiple Logins */
    /* Verify Account */
    /* Statistic */
    /* Balance */
    /* History */
    /* Static Pages */
    /* Providers */
    /* Premium */
    /* .levels-page .modal-data {
          width: 50%;
        } */
    /* Game page */
    /* .game-canvas {
          height: calc(100% - 105px);
        } */
  }
  #root .img-container {
    display: none;
  }
  #root .mod-window::part(content),
  #root ion-modal.logout-modal.mod-window.auto-height::part(content),
  #root ion-modal.login-modal.mod-window.auto-height::part(content),
  #root ion-modal.register-modal.mod-window.auto-height::part(content) {
    max-width: 475px;
  }
  #root .login-modal .mod-container,
  #root .register-modal .mod-container {
    flex-direction: column;
  }
  #root .mod-container .login-btn {
    margin-top: 110px;
  }
  #root .register-modal::part(content) {
    min-height: 750px;
  }
  #root .menu-link p,
  #root .menu-link a {
    font-size: 14px;
    line-height: 20px;
  }
  #root .games-block .title-w-filter .page-title,
  #root .games-page-container .title-w-filter .page-title {
    font-size: 38px;
    line-height: 42px;
  }
  #root .games-block .title-w-filter ion-searchbar {
    margin-bottom: 0;
  }
  #root .subcategories-filter-container {
    flex-wrap: wrap;
  }
  #root .subcategories-filter-container .swiper,
  #root .subcategories-filter-container .swiper-container {
    margin-bottom: 32px;
  }
  #root .cust-select.providers-select {
    width: 100%;
  }
  #root .leader-board-container.affiliate-table {
    margin-bottom: 120px;
  }
  #root .leader-board-table {
    min-height: 656px;
    padding: 0 16px 16px;
  }
  #root .leader-board-container .leader-board-table .leader-board-table-head div,
  #root .leader-board-container .leader-board-table .swiper-slide .address-title {
    width: 33%;
  }
  #root .leader-board-container .leader-board-table .leader-board-table-head div.center,
  #root .leader-board-container .leader-board-table .swiper-slide .address-title.center {
    justify-content: center;
  }
  #root .leader-board-container .leader-board-table .swiper-slide .address-title img {
    width: 18px;
    height: 18px;
  }
  #root .auth-modal.mod-window.auto-height::part(content) {
    max-width: 370px;
  }
  #root .auth-modal-left {
    display: none;
  }
  #root .auth-modal .auth-modal-socials span {
    font-size: 14px;
    line-height: 20px;
  }
  #root .reg-final-modal .reg-final-modal-title {
    font-size: 20px;
    line-height: 30px;
  }
  #root .forget-modal.auth-modal .modal-header {
    font-size: 14px;
    line-height: 20px;
  }
  #root .forget-modal .forget-modal-top {
    margin-bottom: 16px;
  }
  #root .forget-modal .forget-modal-top img {
    margin-bottom: 8px;
  }
  #root .forget-modal .forget-modal-title {
    padding: 0 40px;
    font-size: 20px;
    line-height: 30px;
  }
  #root .logout-modal .modal-data {
    padding: 35px 0;
  }
  #root .cookie-window::part(content) {
    max-width: 725px;
  }
  #root .mod-confirm .modal-data {
    padding: 35px;
    /* padding-bottom: 0; */
  }
  #root .mod-confirm-wrapper .btn {
    margin-right: 35px;
  }
  #root .forbidden-window::part(content) {
    max-width: 725px;
  }
  #root .forbidden-window .mod-confirm .modal-data {
    padding: 35px;
  }
  #root .player-info-modal.how-to-charge-modal.mod-window.premium-modal::part(content) {
    max-width: 510px;
  }
  #root .player-name {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 20px;
  }
  #root .player-joined {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 20px;
  }
  #root .player-best-win {
    font-size: 14px;
    line-height: 24px;
  }
  #root .player-info-modal .promo-modal-title-text {
    font-size: 28px;
    line-height: 36px;
  }
  #root .player-info-modal .player-favorite-games-block {
    flex-direction: column;
  }
  #root .player-favorite-games-title {
    font-size: 20px;
    line-height: 30px;
  }
  #root .live-chat-rules-modal.how-to-charge-modal.mod-window.premium-modal::part(content) {
    max-width: 556px;
  }
  #root .live-chat-rules-modal .modal-block {
    flex-direction: column;
  }
  #root .live-chat-rules-modal .promo-modal-title {
    font-size: 20px;
    line-height: 30px;
  }
  #root .live-chat-rules-modal .promo-modal-text {
    font-size: 14px;
    line-height: 20px;
  }
  #root .bingo-coin-modal .modal-body {
    padding: 45px 0;
    flex-direction: row;
  }
  #root .bingo-coin-modal .modal-block {
    padding: 8px;
    flex-direction: column;
    /* align-items: center; */
  }
  #root .bingo-coin-modal .modal-block-top {
    /* width: auto; */
    width: 220px;
    order: inherit;
  }
  #root .bingo-coin-modal .modal-block-bottom {
    margin-right: 0;
  }
  #root .bingo-coin-modal .modal-block-bottom-title.gradient {
    font-size: 38px;
    line-height: 42px;
  }
  #root .bingo-coin-modal .modal-block-bottom-description.align-center {
    font-size: 18px;
    line-height: 26px;
  }
  #root .modal-block-labels {
    margin-bottom: 24px;
  }
  #root .bingo-coin-modal .modal-block-bottom-title {
    font-size: 20px;
    line-height: 30px;
  }
  #root .bingo-coin-modal .modal-block-top-img {
    width: 30px;
    margin-right: 8px;
  }
  #root .bingo-coin-modal .modal-block-bottom-description {
    margin-bottom: 0;
  }
  #root .cashback-success-modal.mod-window.auto-height::part(content) {
    max-width: 475px;
  }
  #root .cashback-success-modal-content {
    flex-direction: column;
  }
  #root .cashback-success-modal-left {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
  #root .cashback-success-modal-right {
    align-items: center;
  }
  #root .cashback-success-modal .promo-modal-text {
    margin-top: 0;
    text-align: center;
  }
  #root .cashback-success-modal .promo-modal-form-buttons {
    margin-top: 25px;
  }
  #root .cashback-success-modal .promo-modal-form-buttons .save-btn {
    width: auto;
    min-width: 125px;
    padding: 0 35px;
  }
  #root .modal-header {
    font-size: 20px;
    line-height: 30px;
  }
  #root .modal-body {
    padding: 0;
    flex-direction: column;
    border: none;
    background: var(--modal-body-mobile-bg);
  }
  #root .modal-block {
    width: 100%;
    padding: 30px;
    flex-direction: row;
    border-right: none;
    border-bottom: 1px solid #268a0033;
  }
  #root .modal-block-top {
    width: 170px;
    min-width: 170px;
    margin-bottom: 0;
    order: 1;
  }
  #root .modal-block-top-img {
    width: 100%;
  }
  #root .modal-block-bottom {
    min-height: inherit;
    margin-right: 55px;
  }
  #root .modal-block-bottom .gradient-number {
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 30px;
    line-height: 100%;
    background: linear-gradient(180deg, #1c4806 -17.88%, #1f7300 121.01%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: var(--modal-gradient-text-mobile-opacity);
  }
  #root .modal-block-bottom-title {
    margin-bottom: 8px;
    font-size: 28px;
    line-height: 36px;
  }
  #root .modal-block-bottom-description {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 20px;
  }
  #root .modal-block ion-item.header-balance {
    max-width: 285px;
  }
  #root .account-header-tab .img-center-account {
    height: 24px;
    background-size: 20px;
    margin-top: -12px;
    margin-bottom: 0;
  }
  #root .account-container-header:not(.hist-header) .account-header-tab p {
    font-size: 14px;
  }
  #root .account-fields-container {
    background: none;
  }
  #root .warning-message {
    left: 30px;
    top: 18px;
    display: none;
    /* мешает */
  }
  #root .referral-container {
    width: 100%;
    max-width: inherit;
    position: relative;
    top: 0;
    left: 0;
  }
  #root .referral-body-field {
    max-width: inherit;
  }
  #root .account-fields-container.games-limit {
    flex-direction: column;
  }
  #root .games-limit-right {
    margin-left: 0;
    margin-top: 54px;
  }
  #root .device-title div:first-child {
    font-size: 18px;
  }
  #root .verification-steps {
    flex-direction: column;
  }
  #root .step-two {
    margin-top: 45px;
  }
  #root .stats p {
    font-size: 20px;
  }
  #root .pie-container {
    padding: 0 8px;
  }
  #root .account-header-tab .img-center-balance {
    height: 24px;
    background-size: auto 20px;
    margin-top: -12px;
    margin-bottom: 0;
  }
  #root .balance-fields-container {
    background: none;
  }
  #root .balance-fields-deposites-container {
    flex-wrap: wrap;
  }
  #root .balance-fields-deposites-container .text-container {
    width: 100%;
    margin-top: 0;
    margin-bottom: 30px;
    order: -1;
  }
  #root .balance-fields-deposites-item.loan {
    margin-top: 30px;
  }
  #root .loan-request-header {
    font-size: 20px;
  }
  #root .balance-fund-top {
    flex-direction: column;
  }
  #root .balance-level-compliance,
  #root .balance-fund-bottom {
    margin-top: 35px;
  }
  #root .balance-title {
    font-size: 20px;
    margin-bottom: 0;
  }
  #root .rates-container {
    min-width: 350px;
    margin-top: 15px;
  }
  #root .warning-message.verify-message {
    display: block;
    margin-top: 25px;
  }
  #root .warning-message-btn {
    margin-left: 30px;
  }
  #root .img-center-history {
    height: 24px;
    background-size: auto 20px;
    margin-top: -12px;
    margin-bottom: 0;
  }
  #root .hist-header.account-container-header p {
    margin-top: 8px;
  }
  #root .terms * {
    font-size: 14px !important;
  }
  #root .terms.faq.width-container {
    margin-top: 0;
  }
  #root .terms.faq .page-title {
    font-size: 20px !important;
  }
  #root .terms span {
    font-size: 14px !important;
  }
  #root .terms h3 {
    font-size: 20px !important;
  }
  #root .terms.ios-app-setup .page-title {
    font-size: 24px !important;
  }
  #root .ios-app-setup-subtitle {
    font-size: 20px !important;
  }
  #root .ios-app-setup-content {
    min-height: 335px;
    height: auto;
    padding: 25px 35px;
    margin-top: 140px;
  }
  #root .ios-app-setup-content-left {
    width: 52%;
  }
  #root .ios-app-setup-content-steps {
    margin-bottom: 25px;
    /* gap: 15px; */
  }
  #root .step-item {
    padding: 6px 18px;
    font-size: 16px !important;
    line-height: 19px;
  }
  #root .ios-app-setup-content-title {
    font-size: 20px !important;
  }
  #root .ios-app-setup-content-description {
    font-size: 16px !important;
  }
  #root .ios-app-setup-content-btns button {
    max-width: 146px;
  }
  #root .ios-app-setup-content-right {
    width: 410px;
    height: 415px;
    right: -16px;
    bottom: -30px;
  }
  #root .providers-block {
    grid-gap: 20px !important;
  }
  #root .table-adaptive {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    padding: 20px;
    background: var(--user-image-bg);
  }
  #root .table-adaptive-row {
    margin-top: 35px;
    display: flex;
  }
  #root .table-adaptive-row:first-child {
    margin-top: 0;
  }
  #root .table-adaptive-row-item .table-adaptive-row-item-value.with-img {
    justify-content: flex-start;
    align-items: center;
  }
  #root .table-adaptive-row .table-adaptive-row-item-value.center {
    justify-content: flex-start;
  }
  #root .table-adaptive-row-item {
    width: 50%;
  }
  #root .table-adaptive-row-item-value {
    margin-bottom: 8px;
  }
  #root .table-adaptive-row-item-value img {
    margin-right: 5px;
  }
  #root .table-adaptive-row-item-value.center {
    justify-content: flex-start;
  }
  #root .table-adaptive-row-item-title {
    font-size: 14px;
    line-height: 17px;
    opacity: 0.5;
  }
  #root .promo-modal-form-buttons .cancel-btn {
    display: none;
  }
  #root .create-promo .promo-modal-form-buttons .cancel-btn {
    display: inherit;
  }
  #root .promo-modal-form-buttons .save-btn {
    width: 100%;
  }
  #root .create-promo .promo-modal-form-buttons .save-btn {
    width: 158px;
  }
  #root .affiliate-content .affiliate-content-top {
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  #root .affiliate-content-top-item-label {
    font-size: 14px;
    line-height: 17px;
  }
  #root .affiliate-campaign-item-body .affiliate-content-top.small {
    flex-wrap: wrap;
  }
  #root .affiliate-campaign-item-body .affiliate-content-top.small .affiliate-content-top-item {
    min-width: 33%;
    max-width: 33%;
    margin-right: 0;
    margin-bottom: 10px;
    align-items: flex-start;
  }
  #root .affiliate-campaign-table-item .affiliate-campaign-item-body {
    max-height: 230px;
  }
  #root .title-w-filter {
    flex-wrap: wrap;
  }
  #root .levels-main-info-container {
    margin: 40px 0 100px;
    padding: 40px;
  }
  #root .small-ring-bg {
    width: 225px;
    height: 240px;
    bottom: 200px;
    filter: blur(7px);
  }
  #root .big-ring-bg {
    width: 555px;
    height: 555px;
    right: -250px;
    bottom: 0;
    background-size: contain;
  }
  #root .levels-main-info-container-title {
    width: 100%;
    margin-bottom: 60px;
    font-size: 38px;
    line-height: 42px;
  }
  #root .levels-main-info-container-body-item {
    margin-bottom: 40px;
  }
  #root .body-item-title {
    font-size: 20px;
    line-height: 30px;
  }
  #root .levels-second-info-container-title {
    font-size: 38px;
    line-height: 42px;
  }
  #root .levels-carousel-title {
    font-size: 38px;
    line-height: 42px;
  }
  #root .levels-carousel-description {
    width: 82%;
  }
  #root .levels-terms-title {
    font-size: 38px;
    line-height: 42px;
  }
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
  #root {
    /* User menu and Notifications */
  }
  #root .user-popover.user-menu {
    --max-height: 100%;
  }
  #root .user-popover.user-menu::part(content) {
    top: 10px !important;
    bottom: 10px !important;
  }
  #root .user-menu-popover-body {
    max-height: calc(100vh - 72px);
  }
  #root .notification-popover-body {
    max-height: calc(100vh - 195px);
  }
}
@media screen and (orientation: landscape) {
  #root .giphy-container {
    max-height: 50vmin;
  }
}
@media screen and (max-width: 1070px) {
  #root .leader-board-container .leader-board-table tbody tr td.twenty {
    width: 18.4%;
    max-width: 157px;
    min-width: 157px;
  }
  #root .leader-board-container .leader-board-table tbody tr td.ten {
    /* width: 8%; */
    max-width: 80px;
    min-width: 80px;
  }
}

@media screen and (min-width: 3400px) {
  #root .balance-fields-wrapper.from-four-k {
    display: flex;
    flex-direction: column;
  }
  #root .balance-fields-container .max-min-deposit-cont {
    min-width: 753px;
    height: 128px;
    top: 134px;
    right: 74px;
    padding: 24px 24px 24px 28px;
  }
  #root .balance-fields-container .max-min-deposit-cont .text-man-min-dep {
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    margin-left: 84px;
    margin-right: 8px;
 }
 
 #root .balance-fields-container .max-min-deposit-cont .text-man-min-deps {
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
 }
 
 #root .balance-fields-container .max-min-deposit-cont .max-min-deposit-icon {
    width: 45px;
    height: 45px;
    position: absolute;
 }

}

@media screen and (max-width: 767px) {
  #root {
    /* Auth modal */
    /* ion-modal.auth-modal.auto-height #ion-react-wrapper {
          max-height: 90vh;
        } */
    /* Register final modal */
    /* Forget password modal */
    /* player-info-modal */
    /* live-chat-rules-modal */
    /* bingo-coin-modal */
    /* Login */
    /* Logout modal */
    /* .modal-data .input-container .ReactFlagsSelect-module_selectOptions__3LNBJ {
          top: -20px !important;
         } */
    /* Forgot pass */
    /* Sign Up */
    /* .promo-field {
          margin-bottom: -70px;
        } */
    /* .input-container .date-container ion-select {
          width: 33%;
        } */
    /* .deposite-buttons {
          margin-top: 11px;
        } */
    /* Cookie */
    /* Forbidden modal */
    /* Account Details */
    /* Avatars */
    /* Personal Info */
    /* Multiple Logins */
    /* .account-table {
            overflow-x: auto;
         }
         .account-table th {
            font-size: 14px;
         }*/
    /* Verify Account */
    /*Account Closure*/
    /* Balance */
    /* Statisic */
    /* History */
    /* Premium */
    /* .levels-carousel {
          gap: 0;
        } */
    /* Premium collapse*/
    /* .premium-vertical-title span,
         .premium-horizontal-title span {
            width: 12px;
            height: 100%;
            position: relative;
         } */
    /* .premium-vertical-title span::after,
         .premium-horizontal-title span::after {
            content: '';
            width: 12px;
            height: 7px;
            position: absolute;
            top: 7px;
            left: 8px;
            background-image: url('../images/arrow-up.svg');
            background-repeat: no-repeat;
            transition: .3s;
         } */
    /* .premium-vertical-block.open .premium-vertical-title span::after,
         .premium-horizontal-block.open .premium-horizontal-title span::after {
            transform: rotate(180deg);
         } */
    /* Static pages */
  }
  #root .auth-modal .auth-modal-right {
    width: 100%;
    min-width: inherit;
  }
  #root .auth-modal-inputs-container .input-container input {
    height: 50px;
    font-size: 16px;
  }
  #root .auth-modal .auth-modal-body .login-btn {
    height: 50px;
    font-size: 16px;
  }
  #root .auth-modal .auth-modal-inputs-container .input-container ion-item.ion-item-select {
    max-height: inherit;
  }
  #root .auth-modal .auth-modal-inputs-container .input-container.select ion-select {
    height: 50px;
    font-size: 16px;
  }
  #root .auth-modal-body .modal-buttons .cancel-btn {
    min-width: auto;
  }
  #root .auth-modal-body .modal-buttons .save-btn {
    min-width: auto;
    max-width: inherit;
  }
  #root .auth-modal .policy-checkbox span {
    font-size: 14px;
  }
  #root ion-toast.toast {
    --width: auto;
    --min-width: inherit;
    --white-space: inherit;
  }
  #root .reg-final-modal .reg-final-modal-title {
    font-size: 18px;
    line-height: 24px;
  }
  #root .reg-final-modal .reg-final-modal-description {
    font-size: 16px;
    line-height: 24px;
  }
  #root .forget-modal.auth-modal .modal-data {
    padding: 0;
  }
  #root .forget-modal.auth-modal .forget-modal-body {
    padding: 32px 16px 16px;
  }
  #root .forget-modal.auth-modal .forget-modal-title {
    padding: 0;
    font-size: 18px;
    line-height: 24px;
  }
  #root .forget-modal.auth-modal .forget-modal-description {
    font-size: 16px;
    line-height: 24px;
  }
  #root .forget-modal.auth-modal .auth-modal-inputs-container {
    margin-bottom: 16px;
  }
  #root .player-info-modal .player-info-block {
    padding: 34px;
  }
  #root .player-info-block.level-0 {
    background: var(--player-modal-mobile-bg-level-0);
  }
  #root .player-info-block.level-1 {
    background: var(--player-modal-mobile-bg-level-1);
  }
  #root .player-info-block.level-2 {
    background: var(--player-modal-mobile-bg-level-2);
  }
  #root .player-info-block.level-3 {
    background: var(--player-modal-mobile-bg-level-3);
  }
  #root .player-info-block.level-4 {
    background: var(--player-modal-mobile-bg-level-4);
  }
  #root .player-info-block.level-5 {
    background: var(--player-modal-mobile-bg-level-5);
  }
  #root .player-info-block.level-6 {
    background: var(--player-modal-mobile-bg-level-6);
  }
  #root .player-info-block-item {
    width: 100%;
    padding: 0;
    border: none;
  }
  #root .player-info-block-item.item-right {
    width: auto;
    margin-bottom: 16px;
    padding: 10px 14px;
    flex-direction: row;
    background: var(--player-info-pic-ellipse-mobile);
    border-radius: 96px;
  }
  #root .player-level-pic {
    width: 38px;
    height: 38px;
    margin-right: 8px;
    margin-bottom: 0;
  }
  #root .player-info-modal .promo-modal-title-text {
    font-size: 22px;
    line-height: 28px;
  }
  #root .player-info-modal .player-favorite-games-block {
    padding: 0;
  }
  #root .player-favorite-games-title {
    font-size: 18px;
    line-height: 24px;
  }
  #root .player-info-modal .levels-carousel-arrows {
    gap: 16px;
  }
  #root .live-chat-rules-modal .modal-header {
    font-size: 14px;
    line-height: 20px;
  }
  #root .live-chat-rules-modal .modal-block {
    padding: 0;
  }
  #root .live-chat-rules-modal .promo-modal-title {
    font-size: 18px;
    line-height: 24px;
  }
  #root .live-chat-rules-modal .promo-modal-text {
    margin-bottom: 12px;
  }
  #root .bingo-coin-modal .modal-header {
    font-size: 16px;
    line-height: 22px;
  }
  #root .bingo-coin-modal .modal-body {
    padding: 40px 0 0 0;
    flex-direction: column;
    background: var(--modal-body-mobile-bg-second);
  }
  #root .bingo-coin-modal .modal-block {
    width: 100%;
    padding: 0 16px;
  }
  #root .bingo-coin-modal .modal-block-top {
    width: 200px;
    margin-bottom: 15px;
  }
  #root .bingo-coin-modal .modal-block-bottom-title.gradient {
    margin-bottom: 8px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .bingo-coin-modal .modal-block-bottom-description.align-center {
    font-size: 16px;
    line-height: 24px;
  }
  #root .bingo-coin-modal .modal-block.labels {
    padding: 24px;
  }
  #root .bingo-coin-modal .modal-block-bottom-title {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;
  }
  #root .how-to-charge-modal .modal-block-bottom-title .modal-block-top-img {
    width: 24px;
    margin-right: 8px;
  }
  #root .mod-window::part(content) {
    min-height: 430px;
    max-width: 375px;
  }
  #root .premium-modal::part(content) {
    margin: 0;
  }
  #root .promo-modal-title-text {
    font-size: 28px;
    line-height: 34px;
  }
  #root .promo-modal-title-info {
    font-size: 14px;
    line-height: 20px;
  }
  #root .promo-modal-title-info img {
    width: 20px;
    height: 20px;
    margin: 0 6px;
  }
  #root .аchieves-item {
    font-size: 14px;
    line-height: 20px;
  }
  #root .аchieves-item svg {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
  #root .promo-window::part(content) {
    min-height: 300px;
  }
  #root .promo-window .mod-confirm .modal-data {
    padding: 30px 20px 20px;
  }
  #root .promo-modal-form-buttons .save-btn {
    max-width: inherit;
  }
  #root .create-promo .promo-modal-form-buttons {
    flex-direction: column;
  }
  #root .create-promo .promo-modal-form-buttons .cancel-btn,
  #root .create-promo .promo-modal-form-buttons .save-btn {
    width: 100%;
    max-width: inherit;
    margin-right: 0;
  }
  #root .create-promo .promo-modal-form-buttons .cancel-btn {
    margin-top: 10px;
    order: 1;
  }
  #root .tournament-detail-cms {
    width: 100%;
    margin: 0 auto;
  }
  #root .logout-modal .modal-data {
    padding: 35px;
  }
  #root .modal-data,
  #root .mod-confirm .modal-data,
  #root .modal-data,
  #root .mod-confirm.create-promo .modal-data {
    padding-left: 18px;
    padding-right: 18px;
  }
  #root .modal-title {
    margin-top: 30px;
    margin-bottom: 3px;
    font-size: 12px;
  }
  #root .create-promo .promo-modal-title {
    font-size: 18px;
    line-height: 21px;
  }
  #root .modal-description {
    font-size: 18px;
    margin-bottom: 35px;
  }
  #root .input-container {
    margin-top: 11px;
  }
  #root .input-container > span {
    font-size: 11px;
    line-height: normal;
  }
  #root .input-container input,
  #root ion-select,
  #root .field.country {
    height: 40px;
    padding-left: 13px;
    font-size: 12px;
    /* margin-top: 4px; */
  }
  #root .input-container-item {
    height: 40px;
  }
  #root .input-container-item input {
    height: 100%;
    margin: 0;
    padding-left: 35px;
  }
  #root .input-container-item .bc-icon {
    width: 16px;
  }
  #root .withdraw-amount-input-container {
    height: 40px;
  }
  #root .withdraw-amount-input-container .icon-container {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
  #root .withdraw-amount-input-container .max-btn {
    font-size: 12px;
    line-height: 14px;
  }
  #root .ReactFlagsSelect-module_label__27pw9 {
    font-size: 12px !important;
  }
  #root .ReactFlagsSelect-module_selectValue__152eS,
  #root .ReactFlagsSelect-module_selectOption__3pcgW {
    padding: 0 !important;
  }
  #root .ReactFlagsSelect-module_selectValue__152eS {
    font-size: 12px !important;
  }
  #root .ReactFlagsSelect-module_selectOptions__3LNBJ {
    top: 30px !important;
  }
  #root .modal-data .input-container.input-lang .ReactFlagsSelect-module_selectOptions__3LNBJ {
    top: 30px !important;
  }
  #root .input-container input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 12px;
  }
  #root .input-container input::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 12px;
  }
  #root .input-container input:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 12px;
  }
  #root .input-container input:-moz-placeholder {
    /* Firefox 18- */
    font-size: 12px;
  }
  #root ion-select {
    font-size: 12px;
  }
  #root .remember-me {
    margin-top: 15px;
  }
  #root .remember-me span,
  #root .forgot-password {
    font-size: 12px;
  }
  #root .mod-container .btn {
    height: 40px;
    font-size: 13px;
  }
  #root .mod-container .login-btn {
    margin-top: 48px;
  }
  #root .mod-container .forgot-btn {
    margin-top: 75px;
  }
  #root .modal-question-mob {
    font-size: 12px;
    color: #979797;
  }
  #root .forgot-disclaimer {
    font-size: 11px;
  }
  #root .register-modal::part(content) {
    min-height: 530px;
  }
  #root .progress-bar {
    order: -1;
    margin-top: 17px;
  }
  #root .register-modal .modal-title {
    margin-top: 0;
    margin-bottom: 0;
  }
  #root .register-modal .modal-description {
    margin-bottom: 0;
    line-height: normal;
  }
  #root .flag-select__options {
    top: 32px;
  }
  #root .flag-select__option.flag-select__option--placeholder {
    font-size: 12px;
  }
  #root .field.country button {
    padding-left: 0;
  }
  #root ion-select.gender,
  #root .deposite-container .input-container.input-lang {
    width: 100%;
  }
  #root .input-container ion-item.ion-item-select {
    max-height: 40px;
    --inner-padding-start: 0;
  }
  #root .input-container ion-item.ion-item-select.gender {
    width: 100%;
  }
  #root .input-container ion-item.ion-item-select::part(native) {
    min-height: 40px;
  }
  #root .number-container .ccode {
    max-width: 86px;
  }
  #root input.zcode {
    max-width: 105px;
  }
  #root .deposite-container .input-container:not(.input-lang),
  #root .deposite-container .input-container.deposit-daily {
    margin-left: 0;
    margin-right: 0;
    width: 48%;
  }
  #root .deposite-container .input-container.deposit-currency {
    margin-right: 1%;
  }
  #root .register-modal .btn {
    margin-top: 0;
  }
  #root .btn-back span {
    font-size: 13px;
  }
  #root .modal-buttons .btn {
    max-width: 132px;
  }
  #root .bit.btn.only-btn {
    margin-top: 68px;
  }
  #root .modal-buttons {
    /* margin-top: 25px; */
    margin-bottom: 15px;
  }
  #root .policy-checkbox {
    margin-top: 7px;
  }
  #root .deposite-container + .policy-checkbox {
    margin-top: 13px;
  }
  #root .policy-checkbox span {
    font-size: 10px;
    line-height: normal;
  }
  #root .cookie-window::part(content) {
    min-height: 300px;
  }
  #root .modal-content {
    font-size: 12px;
  }
  #root .cookie-window .modal-description {
    margin-bottom: 50px;
  }
  #root .mod-confirm-wrapper .btn {
    margin-right: 18px;
  }
  #root .forbidden-window::part(content) {
    min-height: 250px !important;
  }
  #root .forbidden-window .mod-confirm .modal-data {
    padding: 35px 20px 10px;
  }
  #root .forbidden-window .forbidden-modal-logo {
    width: 50%;
  }
  #root .account-container-header {
    height: auto;
    flex-wrap: wrap;
    box-shadow: none;
  }
  #root .account-header-tab {
    min-width: 33.3333%;
    height: 32px;
    margin-bottom: 20px;
    background: var(--header-background);
  }
  #root .account-header-tab:nth-child(2n + 1) {
    border-radius: 3px 0 0 3px;
  }
  #root .account-header-tab:nth-child(2n) {
    border-radius: 0 3px 3px 0;
  }
  #root .account-container-header .account-header-tab p {
    font-size: 11px !important;
  }
  #root .avatars-container {
    grid-template-columns: repeat(4, 1fr);
  }
  #root .pick-avatar {
    width: 100%;
    height: auto;
  }
  #root .account-container {
    margin-bottom: 0;
  }
  #root .account-header-tab .img-center-account {
    height: 22px;
    background-size: 18px;
    margin-top: -10px;
  }
  #root .account-fields-container,
  #root .account-verification,
  #root .account-closure {
    min-height: auto;
    padding: 18px 30px;
  }
  #root .account-table {
    min-height: auto;
    padding: 0;
  }
  #root .personal-footer {
    height: auto;
  }
  #root .account-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  #root .games-limit .account-title {
    max-width: 350px;
    margin: 0 auto 20px;
  }
  #root .account-container .deposite-container {
    margin: 0 auto;
  }
  #root .account-fields-container .input-container,
  #root .balance-fields-container .input-container,
  #root .deposites-container {
    margin-left: auto;
    margin-right: auto;
  }
  #root .balance-fields-wrapper {
    max-width: 100%;
  }
  #root .balance-fields-wrapper:first-child {
    margin-bottom: 25px;
  }
  #root .balance-fields-wrapper .input-container.select {
    margin-right: 0;
    margin-bottom: 10px;
  }
  #root .balance-fields-wrapper .input-container.comission {
    margin-left: 0;
    margin-right: 0;
  }
  #root .balance-fields-wrapper .input-container.address {
    margin-bottom: 25px;
  }
  #root .input-container .body-field {
    height: auto;
    padding: 12px;
  }
  #root .input-container.address .body-field {
    font-size: 12px;
    line-height: 14px;
  }
  #root .input-container.address .body-field span {
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    overflow: hidden;
  }
  #root .input-container.address .body-field svg {
    margin-left: 10px;
  }
  #root .balance-fields-wrapper .qr {
    width: 140px;
    height: 140px;
  }
  #root .qr-label {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
    font-size: 12px;
    line-height: 14px;
  }
  #root .field.currency::before {
    background-size: 85%;
    background-position: center;
  }
  #root .user-image,
  #root .pick-avatar-default {
    margin: 0 auto;
    order: -1;
    text-align: center;
  }
  #root .user-image .avatar-title {
    margin: 0 auto;
    margin-bottom: 10px;
  }
  #root .pick-avatar-default {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  #root .account-footer-btns {
    padding: 25px 30px;
    flex-wrap: wrap;
  }
  #root .change-password {
    order: 3;
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
  #root .cancel-btn,
  #root .save-btn,
  #root .save-btn.save-psword {
    max-width: 100px;
    min-width: 48.5%;
    margin-left: 0;
  }
  #root .cancel-btn {
    margin-right: 3%;
  }
  #root .save-btn {
    margin-right: 0;
  }
  #root .games-limit > div {
    max-width: none;
  }
  #root .games-limit-right {
    margin-top: 28px;
  }
  #root .games-limit-right-bottom .account-title {
    margin-top: 28px;
  }
  #root .account-table-multiple thead {
    display: none;
  }
  #root .account-table-multiple tr {
    display: block;
    position: relative;
    padding-top: 5px;
    background-color: var(--table-bg);
    border-bottom: 4px solid var(--header-background);
  }
  #root .account-table-multiple td {
    display: block;
    width: 100%;
    padding: 5px 45% 5px 15px !important;
    border-bottom: none;
  }
  #root .account-table-multiple td.current-device {
    display: none;
  }
  #root .account-table-multiple td.action-btn {
    padding: 0 !important;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 40%;
    height: 100%;
  }
  #root .action-btn div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e24f4f;
    color: #fff;
    border-radius: 0;
  }
  #root .device-title div:first-child {
    font-size: 15px;
  }
  #root .device-title {
    font-size: 13px;
  }
  #root .address-title,
  #root .action-btn div {
    font-size: 14px;
  }
  #root .referral-container {
    box-shadow: 0px 4px 30px rgba(75, 90, 81, 0.25);
  }
  #root .referral-body-item-bottom {
    flex-direction: column;
  }
  #root .referral-body-field {
    margin-right: 0;
    margin-bottom: 10px;
  }
  #root .referral-body-btn {
    width: 100%;
  }
  #root .account-description {
    font-size: 13px;
  }
  #root .step-title {
    font-size: 14px;
  }
  #root .account-verification {
    margin-bottom: 20px;
  }
  #root .account-verification .account-footer-btns {
    width: calc(100% + 60px);
    margin-left: -30px;
  }
  #root .thumbs-container {
    width: 100% !important;
  }
  #root .account-closure-content p {
    font-size: 13px;
  }
  #root .save-btn.delete {
    min-width: 100%;
    margin-left: 0;
  }
  #root .account-header-tab .img-center-balance {
    height: 22px;
    background-size: auto 18px;
    margin-top: -10px;
    margin-bottom: 0;
  }
  #root .balance-fields-container {
    min-height: auto;
    padding: 18px 30px;
    flex-direction: column;
  }
  #root .balance-fund-left {
    margin-right: 0;
  }
  #root .deposites-container {
    padding-left: 18px;
    padding-right: 18px;
  }
  #root .balance-fields-deposites-item {
    width: 100%;
  }
  #root .balance-fields-deposites-container .deposites-container {
    min-width: inherit;
  }
  #root .balance-level-compliance,
  #root .balance-fund-bottom {
    margin-top: 25px;
  }
  #root .deposite-line div {
    font-size: 14px;
  }
  #root .deposite-line {
    padding-bottom: 15px;
  }
  #root .loan-request-header {
    max-width: 350px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 25px;
  }
  #root .balance-title {
    font-size: 18px;
  }
  #root .warning-message.verify-message .warning-message-content {
    flex-direction: column;
    align-items: flex-start;
  }
  #root .statistics-page {
    padding: 23px 17px;
  }
  #root .pie-container {
    width: 100%;
    margin-bottom: 50px;
    padding: 0;
  }
  #root .stats p {
    font-size: 18px;
  }
  #root .stats .save-btn {
    display: none;
  }
  #root .pies-container .pie {
    min-width: 100%;
  }
  #root .account-header-tab {
    min-width: 50%;
  }
  #root .account-header-tab .img-center-history {
    height: 22px;
    background-size: auto 18px;
    margin-top: -10px;
    margin-bottom: 0;
  }
  #root .hist-header.account-container-header p {
    margin-top: 0;
  }
  #root .levels-main-info-container {
    margin: 50px 0 100px;
    padding: 25px;
  }
  #root .levels-main-info-container-title {
    margin-bottom: 50px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .levels-main-info-container-body {
    width: 100%;
  }
  #root .body-item-image {
    width: 35px;
    height: 35px;
  }
  #root .body-item-title {
    font-size: 18px;
    line-height: 24px;
  }
  #root .body-item-description {
    font-size: 16px;
    line-height: 24px;
  }
  #root .levels-second-info-container-title {
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .levels-page .modal-block-bottom {
    margin-right: 0;
  }
  #root .levels-page .modal-block-top-img {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }
  #root .levels-page .modal-block-bottom-title {
    margin-bottom: 16px;
    align-items: center;
    font-size: 22px;
    line-height: 28px;
  }
  #root .levels-carousel-top {
    margin-bottom: 10px;
  }
  #root .levels-carousel-title {
    font-size: 28px;
    line-height: 34px;
  }
  #root .levels-carousel-description {
    width: 100%;
    margin-bottom: 25px;
  }
  #root .levels-page .modal-data {
    /* width: 100%; */
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  #root .levels-terms-title {
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .levels-terms li {
    background-position: 0 8px;
    font-size: 16px;
    line-height: 24px;
  }
  #root .premium-vertical-title,
  #root .premium-horizontal-title {
    font-size: 18px;
  }
  #root .premium-checklist li {
    font-size: 14px;
  }
  #root .special-benefits-container {
    padding: 18px;
  }
  #root .premium-vertical-container,
  #root .premium-horizontal-container {
    grid-template-columns: repeat(auto-fill, 257px);
  }
  #root .premium-vertical-title,
  #root .premium-horizontal-title {
    display: flex;
    align-items: center;
  }
  #root .premium-vertical-title img,
  #root .premium-horizontal-title img {
    display: inherit;
    margin-left: 8px;
    transition: 0.3s;
  }
  #root .premium-vertical-block.open .premium-vertical-title img,
  #root .premium-horizontal-block.open .premium-horizontal-title img {
    transform: rotate(180deg);
  }
  #root .premium-vertical-block .premium-vertical-bottom,
  #root .premium-horizontal-block .premium-horizontal-right {
    display: none;
    /* opacity: 0; */
    /* height: 0; */
    transition: 0.3s;
  }
  #root .premium-vertical-block.open .premium-vertical-bottom,
  #root .premium-horizontal-block.open .premium-horizontal-right {
    display: block;
    /* opacity: 1; */
    /* height: auto; */
  }
  #root .premium-vertical-block:not(.open) .premium-vertical-title,
  #root .premium-horizontal-block:not(.open) .premium-horizontal-title {
    padding-bottom: 0;
  }
  #root .premium-vertical-top,
  #root .premium-horizontal-left {
    border-bottom: none;
  }
  #root .premium-vertical-block.open .premium-vertical-top,
  #root .premium-horizontal-block.open .premium-horizontal-left {
    border-bottom: 2px solid var(--pr-card-br);
  }
  #root .premium-horizontal-block:not(.open) .premium-horizontal-left {
    margin-bottom: 0;
  }
  #root .terms * {
    font-size: 14px !important;
  }
  #root .terms.faq .page-title {
    font-size: 18px !important;
  }
  #root .terms .paragraph-left svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
  #root .terms.faq .paragraph-content {
    margin-left: 0;
  }
  #root .terms h3 {
    font-size: 18px !important;
  }
  #root .terms:not(.faq) span {
    font-size: 12px !important;
  }
  #root .terms .h3-smaller {
    font-size: 14px;
    margin: 16px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #root .terms .paragraph {
    padding: 0 38px 0 18px;
    max-height: 50px;
  }
  #root .terms .paragraph .h3-smaller::after {
    border: none;
    top: 10px;
    right: 5px;
  }
  #root .terms p img {
    max-width: 100%;
    height: auto;
  }
  #root .table-adaptive {
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-right: -30px;
  }
  #root .affiliate-content .affiliate-content-top {
    margin-bottom: 10px;
    align-items: flex-start;
  }
  #root .affiliate-content .affiliate-content-top .affiliate-content-top-item {
    min-width: 50%;
    max-width: 50%;
    margin-bottom: 20px;
    align-items: flex-start;
  }
  #root .affiliate-campaign-table {
    margin-left: -30px;
    margin-right: -30px;
  }
  #root .affiliate-campaign-item-body .affiliate-content-top.small .affiliate-content-top-item {
    min-width: 50%;
    max-width: 50%;
  }
  #root .affiliate-campaign-table-item .affiliate-campaign-item-body {
    max-height: 370px;
  }
  #root .refferal-link-field.contrast-bg {
    max-width: 100%;
  }
  #root .refferal-link-field svg {
    margin-left: 10px;
  }
  #root .affiliate-campaign-item-referral .referral-body-item-bottom .delete-btn {
    margin-top: 10px;
    margin-left: 0;
    align-self: flex-start;
  }
}

@media screen and (max-width: 450px) {
  #root .balance-fields-container {
    padding: 18px 30px 18px 30px;
}

  #root .balance-fields-container .max-min-deposit-cont {
    top: 20px;
    left: 30px;
    min-width: 268px;
    padding: 16px;
    margin-left: 0px;
  }

  #root .balance-fields-container .max-min-deposit-cont .text-man-min-dep {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-right: 3px;
    margin-left: 0px;
 }
 
 #root .balance-fields-container .max-min-deposit-cont .text-man-min-deps {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
 }
 
  #root .balance-fields-container .max-min-deposit-cont .max-min-deposit-icon {
    width: 0px;
    height: 0px;
    position: relative;
 }
}

@media screen and (max-width: 320px) {
  #root .mod-confirm-wrapper .btn {
    width: auto;
    margin: 45px 18px 0;
  }
}
@media screen and (max-width: 1439px) {
  #root .width-container-right-desktop {
    display: none;
  }
  #root .width-container-right-adaptive {
    margin-bottom: 20px;
  }
  #root .width-container-right-adaptive .general-information-container {
    margin-bottom: 20px;
  }
  #root .width-container-right-adaptive .premium-right-banner img {
    width: 100%;
  }
}
@media screen and (min-width: 1440px) {
  #root .width-container-right-adaptive {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  #root {
    /* .games-page-container ion-searchbar {
          margin-top: 68px;
          margin-bottom: 40px;
        } */
  }
  #root .bonus-popover .popover-content,
  #root .bonus-popover::part(content) {
    width: auto;
  }
}
@media screen and (max-width: 480px) {
  #root {
    /* Toast from tostify */
  }
  #root .custom-toast {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media screen and (min-width: 3400px) {
  #root {
    /* .width-container ion-searchbar.search-game-field {
          border: 3px solid transparent;
        } */
    /* Header */
    /* .unlogged {
          margin-right: 60px;
        } */
    /* ion-item.ion-item-select ion-select:hover::part(icon) {
          content: url('../images/downdrop-active.svg') !important;
        } */
    /* .user-menu-popover-body-top .lang-field {
          height: 88px;
        } */
    /* .logged {
          margin-right: 60px;
        } */
    /* Notifications */
    /* Menu */
    /* .search-game-container ion-searchbar.search-game-field {
          margin-top: 40px;
          margin-bottom: 75px;
        } */
    /* Footer */
    /* Chat */
    /* Home page */
    /* Premium-vip */
    /* Promoted */
    /* Recommended */
    /* GameCard */
    /* Games pages */
    /* Game page */
    /* .game-canvas {
          height: calc(100% - 210px);
        } */
    /* Tournaments */
    /* Bonuses */
    /* Premium */
    /* Providers */
    /* Account */
    /* Balance */
    /* Statistics */
    /* History */
    /* Affiliate */
    /* LeaderBoard */
    /* Documents */
    /* .terms:not(.faq) img {
          width: 1248px;
          height: auto;
        } */
    /* ios-app-setup  */
    /* Crash page */
    /* Modals */
    /* Auth modal */
    /* Register final modal */
    /* Forget password modal */
    /* Premium Modal */
    /* Premium Modal */
    /* Cookie Modal */
    /* player-info-modal */
    /* live-chat-rules-modal */
    /* bingo-coin-modal */
    /* cashback-success-modal */
  }
  #root * {
    font-size: 32px;
  }
  /* #root .giveaways-content {
    font-size: 12px;
  } */

  #root h4 {
    font-size: 40px !important;
  }
  #root ion-select {
    --padding-start: 32px;
    border-radius: 14px;
  }
  #root ion-select::part(icon) {
    display: none !important;
  }
  #root ion-select::part(text),
  #root ion-select::part(placeholder) {
    padding-right: 50px;
    background-image: url("../images/dropdown.svg");
    background-position: center right 30px;
    background-repeat: no-repeat;
    background-size: 25px auto;
  }
  #root ion-select.balance-select::part(text),
  #root ion-select.balance-select::part(placeholder) {
    background-position: center right 15px;
  }
  #root ion-select.lang-field::part(text) {
    padding-right: 50px;
    background-position: center right 15px;
  }
  #root .popover-content.sc-ion-popover-md,
  #root ion-popover::part(content) {
    min-width: 500px;
    border-radius: 14px;
  }
  #root ion-list.sc-ion-select-popover,
  #root ion-list.sc-ion-select-popover-md {
    max-height: 660px;
  }
  #root ion-list.sc-ion-select-popover,
  #root .sc-ion-select-popover-h ion-list.sc-ion-select-popover,
  #root .sc-ion-select-popover-h ion-list.sc-ion-select-popover-md {
    border-radius: 14px;
  }
  #root ion-select-popover ion-list ion-item {
    height: 90px;
    display: flex;
    align-items: center;
  }
  #root ion-select-popover ion-list ion-item::part(native) {
    height: 100%;
    background-size: auto 48px;
  }
  #root .balance-select-option::part(native) {
    padding-left: 55px;
    background-position: 20px;
    background-size: 48px;
  }
  #root ion-select-popover ion-radio {
    width: 40px;
    height: 40px;
  }
  #root ion-spinner {
    width: 56px;
    height: 56px;
  }
  #root ion-loading.sc-ion-loading-md-h {
    --max-width: 600px;
  }
  #root .loading-hidden .loading-wrapper {
    width: 600px;
    background-position: center;
  }
  #root .loading-hidden .spinner-dots.sc-ion-loading-md {
    top: 80px;
  }
  #root ion-toast {
    --min-width: 1400px;
  }
  #root ion-toast::part(message) {
    line-height: 48px;
  }
  #root .width-container ion-searchbar.search-game-field.searchbar-has-focus {
    /* border: 3px solid var(--green); */
    box-shadow: 0 0 0 3px var(--green);
  }
  #root .bc-icon {
    width: 48px;
  }
  #root .header-container {
    height: 150px;
  }
  #root .menu-btn-container {
    display: none;
  }
  #root .logo-link {
    width: 390px;
    height: 100px;
    /* margin-left: 3%; */
  }
  #root .daymode-toggle {
    width: 112px;
    max-width: 112px;
    height: 64px;
    margin-left: 48px;
    margin-right: 50px;
    --handle-width: 53px;
    --handle-height: 53px;
    --border-radius: 60px;
    --handle-spacing: 5px;
  }
  #root .daymode-toggle::before {
    width: 36px;
    height: 36px;
    top: 26px;
    left: 25px;
    background: url("../images/day-4k.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  #root .daymode-toggle.toggle-checked::after {
    width: 36px;
    height: 36px;
    top: 26px;
    left: inherit;
    right: 24px;
    background: url("../images/night-4k.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  #root .promo-button {
    height: 70px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .balance-div {
    min-width: 535px;
    width: 535px;
    height: 100px;
    margin-right: 32px;
    border-radius: 14px;
  }
  #root .balance-block .balance-div.bonus,
  #root .balance-block .balance-div.freespin {
    min-width: 300px;
  }
  #root .balance-div img {
    width: 38px;
    height: 38px;
    margin-left: 25px;
  }
  #root .balance-div.second img {
    height: 48px;
  }
  #root .balance-div .safe-img,
  #root .balance-div .freespin-img {
    width: auto;
  }
  #root .balance-div p {
    margin-left: 27px;
    font-size: 28px;
    line-height: 33px;
  }
  #root .balance-div .dropdown-img {
    width: 25px;
  }
  #root .balance-block .balance-div.bonus .dropdown-img,
  #root .balance-block .balance-div.freespin .dropdown-img {
    margin-right: 30px;
  }
  #root .balance-div.bonus.second svg,
  #root .balance-div.bonus svg,
  #root .balance-div.freespin svg {
    width: 40px;
    height: auto;
    margin-right: 30px;
  }
  #root ion-item.header-balance {
    min-width: 535px;
    width: 535px;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
    margin-right: 32px;
  }
  #root ion-item.header-balance::part(native) {
    height: 100px;
    max-height: 100px;
    min-height: 100px;
  }
  #root ion-item.header-balance img {
    width: 38px;
    min-width: 38px;
    /* height: 48px; */
    left: 25px;
  }
  #root .balance-select {
    padding-left: 90px !important;
    border-radius: 14px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .balance-block a {
    /* min-width: 266px; */
    /* max-width: 266px; */
    height: 100px;
    padding: 28px 40px;
    border-radius: 14px;
  }
  #root .balance-block a img {
    width: 44px;
  }
  #root .balance-block a p {
    margin-top: 2px;
    margin-left: 16px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .user-popover .info-content {
    padding: 30px;
  }
  #root .info-content-left {
    margin-right: 20px;
  }
  #root .info-content-top-label {
    margin-bottom: 12px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .info-content-title {
    margin-bottom: 30px;
    font-size: 48px;
    line-height: 57px;
  }
  #root .info-content-balance-item {
    margin-bottom: 30px;
  }
  #root .info-content-balance-left {
    font-size: 28px;
    line-height: 33px;
  }
  #root .info-content-balance-right {
    min-width: 200px;
  }
  #root .info-content-balance-right img {
    margin-left: 40px;
    margin-right: 10px;
  }
  #root .info-content-balance-right .bc-icon {
    width: 34px;
  }
  #root .info-content-balance-right .freespin {
    width: 50px;
  }
  #root .info-content-value {
    font-size: 28px;
    line-height: 33px;
  }
  #root .big-safe-img,
  #root .slot-machine {
    width: 382px;
  }
  #root .lang-field {
    width: auto;
    height: 80px;
    margin-left: 30px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .header-container .lang-field {
    margin-right: 65px;
  }
  #root ion-item.lang-field-item {
    width: 74px;
    margin-right: 65px;
  }
  #root ion-select.lang-field::part(icon),
  #root ion-item.ion-item-select ion-select::part(icon) {
    margin-right: 13px;
    display: block !important;
    content: url("../images/dropdown.svg");
  }
  #root ion-item.ion-item-select ion-select::part(icon) {
    width: 25px;
    height: 25px;
    margin-right: 20px;
    display: block !important;
    content: url("../images/dropdown.svg");
  }
  #root body.dark ion-select.lang-field::part(icon),
  #root body.dark ion-item.ion-item-select ion-select::part(icon) {
    content: url("../images/downdrop-dark.svg");
  }
  #root ion-select.lang-field.select-expanded::part(icon),
  #root ion-select.lang-field:hover::part(icon) {
    content: url("../images/downdrop-active.svg") !important;
  }
  #root ion-select.lang-field::part(text),
  #root ion-select.lang-field::part(placeholder),
  #root ion-item.ion-item-select ion-select::part(text),
  #root ion-item.ion-item-select ion-select::part(placeholder) {
    padding-right: 0 !important;
    background-image: none !important;
  }
  #root .login-btn,
  #root .register-btn {
    /* min-width: 266px; */
    height: 100px;
    margin: 0 16px;
    padding-left: 60px;
    padding-right: 60px;
    font-size: 32px;
    line-height: 38px;
    border-radius: 14px;
  }
  #root .header-container .login-btn {
    margin: 0 32px 0 0;
  }
  #root .user-btn {
    height: 88px;
    padding-left: 20px;
    padding-right: 20px;
  }
  #root .userpic {
    min-width: 60px;
    width: 60px;
    height: 60px;
  }
  #root .userpic.header-userpic {
    min-width: 100px;
    width: 100px;
    height: 100px;
    margin-left: 40px;
  }
  #root .userpic.header-userpic:hover {
    box-shadow: 0 0 0 6px var(--green);
  }
  #root .userpic svg {
    width: 40px;
    height: auto;
    top: 30px;
    left: -20px;
  }
  #root .header-username {
    max-width: 300px;
    margin-left: 20px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .user-popover.user-menu {
    --offset-y: 25px;
  }
  #root .user-popover.user-menu .popover-content,
  #root .user-popover.user-menu::part(content) {
    /* top: 150px !important; */
    width: 782px;
    border-radius: 14px;
  }
  #root .user-menu-popover-header {
    padding: 16px 30px;
  }
  #root .user-menu-popover-header-left:hover .userpic {
    box-shadow: 0 0 0 4px var(--green);
  }
  #root .user-menu-popover-header .userpic {
    width: 60px;
    height: 60px;
    margin-right: 12px;
  }
  #root .user-menu-popover-header .username {
    font-size: 32px;
    line-height: 38px;
  }
  #root .user-menu-popover .absolute-close-modal {
    width: 44px;
    height: 44px;
  }
  #root .user-menu-popover .absolute-close-modal svg {
    width: 21px;
    height: 21px;
  }
  #root .user-menu-popover-body {
    padding: 8px 32px 32px;
  }
  #root .user-menu-popover-body-top {
    margin-bottom: 32px;
  }
  #root .user-menu-popover-body-item {
    margin-bottom: 32px;
    padding: 24px;
    border-radius: 14px;
  }
  #root .user-menu-popover-body-item .premium-vip-rank {
    width: 100px;
    height: 100px;
    margin-right: 24px;
    padding: 10px;
    border-width: 2px;
  }
  #root .user-menu-popover-body-item .promo-vip-rank-pic {
    width: 76px;
    height: 76px;
  }
  #root .user-menu-popover-progress-container .premium-vip-progress-top {
    font-size: 32px;
    line-height: 38px;
  }
  #root .user-menu-popover-progress-container .premium-vip-progress-bar {
    height: 38px;
  }
  #root .user-menu-popover-body-item-title {
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .user-menu-popover-bonus-item {
    height: 100px;
    margin-bottom: 16px;
    padding: 0 24px;
    border-radius: 14px;
  }
  #root .user-menu-popover-body-item.grid {
    gap: 48px 10px;
  }
  #root .user-menu-popover-bonus-item img {
    width: 38px;
    height: 38px;
    margin-right: 16px;
  }
  #root .user-menu-popover-bonus-item .badge {
    padding: 6px 10px 5px;
    border-radius: 8px;
    font-size: 28px;
  }
  #root .user-menu-popover-bonus-item .dropdown {
    width: 38px;
    height: 38px;
  }
  #root .user-menu-popover-body-item .gift-img {
    width: 50px;
    height: 50px;
    margin-right: 24px;
  }
  #root .user-menu-popover-body .user-menu-popover-body-item p svg {
    width: 38px;
    min-width: 38px;
    margin: 0 16px 0 0;
  }
  #root .user-menu-popover-body .user-menu-popover-body-item p a {
    font-size: 32px;
    line-height: 38px;
  }
  #root .user-menu-popover-body-item .logout-img {
    width: 38px;
    height: 38px;
    margin-right: 16px;
  }
  #root .user-popover p {
    margin-bottom: 40px;
  }
  #root .user-popover p svg {
    margin-left: 26px;
    margin-right: 26px;
    width: 44px;
  }
  #root .user-popover a {
    font-size: 32px;
    line-height: 38px;
  }
  #root .dropdown-img {
    width: 25px;
    min-width: 25px;
  }
  #root .hline {
    height: 100px;
    margin-left: 40px;
    margin-right: 40px;
  }
  #root .notification-container {
    min-width: 100px;
    width: 100px;
    height: 100px;
  }
  #root .notification-container svg {
    width: 60px;
    height: 60px;
  }
  #root .notification-container ion-badge {
    padding: 6px 12px;
    top: 0;
    right: 0;
    font-size: 24px;
    border-radius: 20px;
  }
  #root .user-popover.user-menu.notification-popover::part(content) {
    width: 780px;
  }
  #root .notification-popover .user-menu-popover-header {
    padding: 24px;
  }
  #root .settings-icon {
    width: 44px;
    height: 44px;
  }
  #root .user-menu-popover-header .vl {
    height: 44px;
    margin: 0 32px;
  }
  #root .notification-popover-body {
    max-height: calc(90vh - 323px);
  }
  #root .notification-popover-body::-webkit-scrollbar {
    width: 6px;
  }
  #root .notification-popover-body::-webkit-scrollbar-thumb {
    height: 70px;
    border-radius: 48px;
  }
  #root .notification-popover-tabs {
    padding: 32px;
  }
  #root .notification-top-btn {
    margin-right: 24px;
    padding: 10px 20px;
    border-radius: 14px;
    font-size: 28px;
    line-height: 44px;
  }
  #root .notification-top-btn.without-frame {
    font-size: 32px;
  }
  #root .notification-popover-footer {
    padding: 32px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .notification-popover-no-option {
    padding: 62px 32px;
  }
  #root .no-option-pic {
    width: 146px;
    height: 100px;
    margin: 0 auto 16px;
  }
  #root .no-option-label {
    font-size: 32px;
    line-height: 38px;
  }
  #root .notification-popover-body-item {
    padding: 32px;
  }
  #root .notification-popover-body-item .live-message-left {
    margin-right: 24px;
  }
  #root .notification-popover-body-item .live-message-left .userpic {
    min-width: 80px;
    width: 80px;
    height: 80px;
  }
  #root .notification-popover-body-item .live-message-left .userpic.giveaway-pic img {
    width: 40px;
    height: 40px;
  }
  #root .notification-popover-body-item .live-message-right .live-message-user-title {
    margin-bottom: 4px;
  }
  #root .notification-popover-body-item .live-message-right .live-message-action-label {
    margin-left: 8px;
  }
  #root .notification-text-container {
    margin-bottom: 4px;
    font-size: 28px;
    line-height: 40px;
  }
  #root .notification-time-container {
    font-size: 28px;
    line-height: 40px;
  }
  #root .logout-btn img {
    min-width: 36px;
    width: 36px;
    height: auto;
  }
  #root .logout-btn p {
    margin-left: 20px;
    font-size: 32px;
    line-height: 38px;
  }
  #root ion-menu {
    width: 500px;
    max-width: 500px;
    min-width: 500px;
  }
  #root ion-menu::part(container) {
    width: 500px;
    --height: calc(100% - 150px);
    margin-top: 150px;
  }
  #root #menu-list {
    padding-bottom: 40px;
  }
  #root .bingo-coin-div {
    width: 424px;
    height: 100px;
    margin-top: 40px;
    border-radius: 14px;
  }
  #root .bingo-coin-div img {
    width: 34px;
    margin-right: 30px;
  }
  #root .bingo-coin-div p {
    font-size: 32px;
    line-height: 38px;
  }
  #root .search-game-container {
    margin: 50px auto;
    /* margin-bottom: -40px; */
  }
  #root ion-searchbar.search-game-field {
    width: 424px;
    height: 86px;
    border-radius: 14px;
    --border-radius: 14px;
  }
  #root .search-game-container ion-searchbar.search-game-field.focused {
    box-shadow: 0 0 0 3px var(--green);
  }
  #root .searchbar-input.sc-ion-searchbar-md {
    height: 80px;
    padding-inline-start: 100px;
    padding-inline-end: 80px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .searchbar-search-icon.sc-ion-searchbar-md {
    width: 42px;
    height: 42px;
    left: 32px;
    top: 22px;
  }
  #root .searchbar-clear-icon.sc-ion-searchbar-md {
    width: 44px;
  }
  #root .search-game-popover {
    width: 420px;
    max-height: 1400px;
    top: 85px;
    left: 40px;
  }
  #root .search-game-link {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  #root .search-game-link .game-image {
    width: 100px;
    min-width: 100px;
    height: 60px;
  }
  #root .search-game-link p {
    font-size: 32px;
    line-height: 38px;
    margin-left: 20px;
  }
  #root .url-selected {
    border-left-width: 5px;
  }
  #root .menu-link svg {
    width: 38px;
    height: auto;
    margin-left: 64px;
    margin-right: 28px;
  }
  #root .menu-link svg.question,
  #root .menu-link svg.menu-link-icon,
  #root .menu-link svg.live-chat-icon {
    width: 38px;
    height: auto;
  }
  #root .menu-link img {
    width: 40px;
    margin-left: 64px;
    margin-right: 28px;
  }
  #root .menu-link p,
  #root .menu-link a {
    font-size: 32px;
    line-height: 38px;
  }
  #root .horizontal-line {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  #root .more-container {
    margin-bottom: 20px;
  }
  #root .more-container svg {
    width: 26px;
    height: 26px;
    margin-top: 0;
    margin-left: 70px;
    margin-right: 18px;
  }
  #root .more {
    font-size: 28px;
    line-height: 34px;
  }
  #root .favs-menu-container {
    margin-top: 40px;
  }
  #root .menu-fav {
    margin-bottom: 40px;
  }
  #root .menu-fav-preview {
    width: 86px;
    height: 66px;
    margin-left: 70px;
  }
  #root .menu-fav p {
    max-width: 250px;
    margin-left: 28px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .store-links {
    width: 420px;
    margin-top: 40px;
  }
  #root .store-links a,
  #root .store-links .store-link {
    width: 280px;
    height: 80.88px;
  }
  #root .store-links a {
    margin-bottom: 20px;
  }
  #root .footer-container {
    margin-top: 200px;
  }
  #root .footer-container-top {
    height: 130px;
  }
  #root .footer-container-top img {
    padding: 0 20px;
    width: 180px;
    -webkit-animation-name: movefoot4k;
    animation-name: movefoot4k;
  }
  @-webkit-keyframes movefoot4k {
    0% {
      right: -180px;
    }
    100% {
      right: 0px;
    }
  }
  @keyframes movefoot4k {
    0% {
      right: -180px;
    }
    100% {
      right: 0px;
    }
  }
  #root .provider-logo-footer {
    max-width: 300px;
    max-height: 100px;
  }
  #root .footer-container-body {
    max-width: 2280px;
    padding: 40px 80px 0;
  }
  #root .footer-container-body-top {
    margin-bottom: 60px;
  }
  #root .footer-container-body-top .logo {
    max-width: 373px;
  }
  #root .footer-container-socials {
    height: 60px;
  }
  #root .footer-container-socials a {
    width: 80px;
    height: 80px;
    margin-left: 35px;
  }
  #root .footer-container-socials a svg {
    width: 60%;
    height: 60%;
    /* width: 60px; */
    /* height: 60px; */
  }
  #root .footer-container-links {
    margin-bottom: 80px;
  }
  #root .footer-container-links div a {
    max-width: 520px;
    margin-top: 50px;
    font-size: 28px;
  }
  #root .footer-text-content {
    font-size: 24px;
    line-height: 32px;
    padding: 40px 0;
    border-width: 2px;
  }
  #root .footer-logos-bottom > div {
    padding: 20px;
  }
  #root .footer-logos-bottom .logo-bottom-right > * {
    min-width: 120px;
    height: 120px;
  }
  #root .footer-logos-bottom .to-gov,
  #root .footer-logos-bottom .to-kkk {
    height: 100px;
    margin: 10px;
  }
  #root .footer-logos-bottom .to-gov {
    width: 120px;
  }
  #root .footer-logos-bottom .to-kkk {
    width: 100px;
  }
  #root .footer-logos-bottom .im-casino {
    width: 112px;
    height: 112px;
  }
  #root .footer-logos-bottom .ridotto {
    width: 140px !important;
    height: 120px;
  }
  #root .footer-valid {
    margin-top: 0px;
  }
  #root .footer-valid div {
    width: 120px !important;
  }
  #root .footer-copyright {
    font-size: 24px;
    padding: 40px 0;
    border-width: 2px;
  }
  #root .chat-global {
    right: 60px;
    bottom: 140px;
    max-width: 800px;
  }
  #root .chat-hidden.active {
    width: 140px;
    height: 140px;
  }
  #root .chat-window {
    height: 1500px;
  }
  #root .chat-header {
    flex: 0 0 100px;
  }
  #root .chat-header p {
    margin-left: 40px;
  }
  #root .close {
    margin-right: 26px;
  }
  #root .close svg {
    width: 30px;
  }
  #root .chat-body {
    flex-basis: calc(100% - 360px);
  }
  #root .message-box {
    min-height: 86px;
    margin-top: 50px;
  }
  #root .message-box.in {
    margin-left: 20px;
  }
  #root .message-box.in:before {
    width: 40px;
    height: 40px;
    left: 24px;
    bottom: -38px;
  }
  #root .message-box.out {
    margin-right: 10px;
  }
  #root .message-box.out:before {
    bottom: -38px;
    right: 24px;
    height: 40px;
    width: 40px;
  }
  #root .message-text {
    margin-top: 10px;
    font-size: 30px;
    line-height: 36px;
    padding-left: 28px;
    padding-right: 40px;
    padding-top: 14px;
  }
  #root .message-time {
    font-size: 24px;
    margin-right: 20px;
    flex: 0 0 30px;
    margin-bottom: 10px;
  }
  #root .message-box.in .popup-box {
    right: 10px;
    top: 10px;
  }
  #root .message-box.out .popup-box {
    left: 10px;
    top: -34px;
  }
  #root .popup-box {
    padding: 0 10px;
  }
  #root .popup-box p {
    margin: 6px 0;
    font-size: 26px;
  }
  #root .chat-input {
    min-height: 256px;
  }
  #root .chat-input form {
    padding: 30px;
  }
  #root .chat-input textarea {
    height: 96px;
    margin-bottom: 20px;
    padding: 26px 10px 20px 40px;
  }
  #root .chat-input .clip-btn {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border-width: 2px;
  }
  #root .chat-input .clip-btn::before {
    width: 80px !important;
    height: 80px !important;
    background-size: 50%;
  }
  #root .chat-input .send-btn {
    width: 130px;
    height: 80px;
  }
  #root .chat-input .send-btn svg {
    width: 48px;
    height: auto;
  }
  #root .type-name {
    margin-top: -40px;
  }
  #root .unlogged-container input {
    width: 200px;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
  #root .unlogged-container button {
    height: 60px;
    width: 80px;
  }
  #root .width-container {
    max-width: 2280px;
    padding: 0 80px;
  }
  #root .page-title {
    font-size: 48px;
    line-height: 56px;
  }
  #root .top-of-the-page {
    margin-top: 64px;
    margin-bottom: 44px;
  }
  #root .premium-vip-container {
    /* height: 300px; */
    height: 560px;
    margin-bottom: 140px;
    padding: 50px;
  }
  #root .premium-vip-container.home-page {
    margin-top: 160px;
  }
  #root .premium-vip-rank {
    width: 414px;
    margin-right: 50px;
    padding: 24px 32px;
    border-width: 3px;
    border-radius: 14px;
  }
  #root .promo-vip-rank-pic {
    width: 320px;
    height: 320px;
  }
  #root .promo-vip-rank-label {
    font-size: 48px;
    line-height: 60px;
  }
  #root .premium-vip-rank .info-icon {
    width: 44px;
  }
  #root .premium-vip-content-top {
    margin-bottom: 50px;
  }
  #root .premium-vip-content-top .userpic {
    width: 74px;
    height: 74px;
    margin-right: 16px;
  }
  #root .premium-vip-title {
    font-size: 32px;
    line-height: 38px;
  }
  #root .premium-vip-container.bonus-page .premium-vip-title {
    font-size: 32px;
    line-height: 38px;
  }
  #root .premium-vip-container.bonus-page .premium-vip-title span {
    font-size: 30px;
  }
  #root .premium-vip-progress-top {
    margin-bottom: 16px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .premium-vip-progress-top.greetings {
    margin-top: 40px;
  }
  #root .premium .premium-vip-progress-top span {
    font-size: 48px;
    line-height: 60px;
  }
  #root .premium-vip-progress-bar {
    height: 28px;
    margin-bottom: 16px;
  }
  #root .premium .premium-vip-progress-bar {
    height: 38px;
  }
  #root .premium-vip-progress-center {
    margin-top: 40px;
    padding: 16px 20px;
    border-radius: 80px;
  }
  #root .premium-vip-progress-center-item img {
    width: 38px;
    margin-right: 16px;
  }
  #root .premium-vip-progress-center-item span.green {
    margin-right: 10px;
  }
  #root .premium-vip-progress-bottom-item span {
    font-size: 28px;
    line-height: 100%;
  }
  #root .premium-vip-progress-bottom-item .bc-icon {
    width: 36px;
    margin: 0 10px;
  }
  #root .premium-vip-progress-bottom-item .premium-vip-progress-bottom-item-link {
    font-size: 32px;
    line-height: 44px;
  }
  #root .premium-vip-bg-image {
    width: 900px;
    height: 900px;
    right: -100px;
  }
  #root .promoted {
    margin-top: 80px;
  }
  #root .promoted-card:first-child {
    margin-right: 20px;
  }
  #root .banners-small {
    max-width: 1040px;
  }
  #root .btn-cnt {
    height: calc(100% - 100px);
  }
  #root .promoted-card .filter-btn {
    width: 330px;
    height: 94px;
    font-size: 28px;
    line-height: 34px;
    border-width: 2px;
  }
  #root .pr-img img {
    width: 1040px;
  }
  #root .pr-info {
    height: 100px;
  }
  #root .pr-info p {
    margin-left: 60px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .pr-info .pr-info-label {
    margin-right: 60px;
    margin-top: -20px;
    font-size: 48px;
    line-height: 84px;
  }
  #root .pr-info .pr-info-label img {
    width: 36px;
    height: auto;
    margin-top: 10px;
    margin-left: 10px;
  }
  #root .recommended-block {
    margin-top: 100px;
    margin-bottom: 20px;
  }
  #root .arrows div {
    width: 56px;
    height: 56px;
    margin-left: 20px;
  }
  #root .arrows div span {
    width: 14px;
    height: 22px;
  }
  #root .rec-games-block {
    perspective: 2000px;
  }
  #root .rec-card {
    flex: 0 0 470px;
  }
  #root .rec-card img {
    height: 310px;
  }
  #root .rec-card > p {
    margin-bottom: 14px;
    padding: 0 45px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .rec-card span {
    margin-bottom: 40px;
    padding: 0 45px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .playnow-btn {
    height: 87px;
  }
  #root .playnow-btn p {
    font-size: 28px;
    line-height: 34px;
  }
  #root .big-banner {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  #root .carousel .control-prev.control-arrow:before {
    border-right-width: 16px;
  }
  #root .carousel .control-next.control-arrow:before {
    border-left-width: 16px;
  }
  #root .carousel .control-arrow:before,
  #root .carousel.carousel-slider .control-arrow:before {
    margin: 0 10px;
    border-top-width: 16px;
    border-bottom-width: 16px;
  }
  #root .carousel .control-dots {
    margin-top: 10px;
  }
  #root .carousel .control-dots .dot {
    width: 46px;
    height: 6px;
    margin: 0 16px;
  }
  #root .games-block .title-w-filter,
  #root .games-page-container .title-w-filter {
    margin-bottom: 60px;
    /* padding: 32px 0; */
  }
  #root .games-block .title-w-filter .page-title,
  #root .games-page-container .title-w-filter .page-title {
    margin: 0;
    font-size: 84px;
    line-height: 94px;
  }
  #root .title-w-filter .page-title {
    margin-right: 20px;
  }
  #root .title-w-filter ion-searchbar {
    min-width: 500px;
  }
  #root .title-w-filter ion-searchbar .searchbar-input-container input {
    height: 86px;
  }
  #root .categories-filter-container {
    height: 180px;
    padding: 24px;
    margin-bottom: 54px;
    border-radius: 14px;
    gap: 20px;
  }
  #root .slot-tag {
    border-radius: 14px;
  }
  #root .tag-img {
    width: 60px;
    height: 60px;
  }
  #root .tag-img svg {
    max-width: 60px;
  }
  #root .slot-tag p {
    margin-top: 10px;
    font-size: 32px;
    line-height: 44px;
    /* font-size: 28px;
          line-height: 34px; */
  }
  #root .subcategories-filter-container {
    margin-bottom: 54px;
  }
  #root .subcategories-item {
    padding: 10px 20px;
    border-radius: 14px;
    font-size: 28px;
    line-height: 44px;
  }
  #root .games-container {
    grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));
    grid-gap: 40px !important;
    perspective: 3000px;
  }
  #root .subcategories-filter-container .levels-carousel-arrows {
    margin-left: 25px;
    margin-right: 25px;
    gap: 24px;
  }
  #root .subcategories-filter-container .levels-carousel-arrow {
    width: 60px;
    height: 60px;
  }
  #root .subcategories-filter-container .levels-carousel-arrow svg {
    width: 28px;
    height: 28px;
  }
  #root .cust-select.providers-select {
    width: 500px;
    min-width: 500px;
  }
  #root .cust-select.providers-select .css-tj5bde-Svg {
    width: 40px;
    height: 40px;
  }
  #root .game-card {
    max-width: 650px;
  }
  #root .fav-block {
    width: 66px;
    height: 66px;
  }
  #root .fav {
    width: 36px;
    height: 32px;
  }
  #root .jackpot-btn-container {
    width: 266px;
    height: 70px;
    bottom: 140px;
    right: calc(100% - 374px);
    border-radius: 60px;
  }
  #root .jackpot-btn-container p {
    font-size: 32px;
    line-height: 38px;
  }
  #root .jackpot-btn-container p img {
    width: 34px;
    margin-left: 6px;
  }
  #root .btn-container {
    height: calc(100% - 172px);
  }
  #root .play,
  #root .demo {
    max-width: 244px;
    max-height: 93px;
    margin: 12px auto;
    border-width: 2px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .game-preview {
    height: 350px;
  }
  #root .game-card .game-info {
    height: 172px;
    margin-top: -8px;
    padding-left: 26px;
    padding-right: 26px;
  }
  #root .game-card .game-info p {
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .game-card .game-info span {
    font-size: 28px;
    line-height: 34px;
  }
  #root .game-card .provider-logo {
    width: 116px;
    right: 20px;
    bottom: 20px;
  }
  #root .game-card .not-available-text {
    padding: 16px;
  }
  #root .game-card .not-available-text img {
    min-width: 30px;
    width: 30px;
    margin-right: 12px;
  }
  #root .game-card .not-available-text span {
    font-size: 24px;
    line-height: 28px;
  }
  #root .more-games {
    max-width: 267px;
    margin: 50px auto;
    border-width: 2px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .categories-filter-container.slots-page {
    height: 106px;
    margin-bottom: 30px;
  }
  #root .slots-page .slot-tag p {
    max-width: 164px;
  }
  #root .game-container {
    max-width: 2120px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  #root .game-header {
    height: 100px;
    padding: 0 40px;
  }
  #root .game-footer {
    height: 110px;
    padding: 0 40px;
  }
  #root .game-footer-like-btn {
    width: 140px;
    height: 80px;
  }
  #root .game-footer-like-btn svg {
    width: 38px;
    height: auto;
  }
  #root .game-footer-like-btn span {
    margin-left: 20px;
  }
  #root .game-footer-fullscreen-btn {
    width: 80px;
    height: 80px;
    margin-left: 60px;
  }
  #root .game-footer-fullscreen-btn svg {
    width: 32px;
    height: auto;
  }
  #root .game-canvas-header {
    height: 150px;
    padding: 0 100px;
  }
  #root .tournaments-container {
    perspective: 3000px;
    grid-template-columns: repeat(auto-fill, minmax(580px, 672px));
    grid-gap: 40px !important;
    margin-bottom: 50px;
  }
  #root .tournament-top {
    height: 616px;
  }
  #root .tournament-card .btn-container {
    height: 624px;
  }
  #root .read-more {
    max-width: 330px;
    height: 94px;
    margin: 12px auto;
    border-width: 2px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .tournament-count {
    top: 588px;
  }
  #root .tournament-count span {
    padding: 16px 56px;
  }
  #root .tournament-card .tournament-info {
    height: 372px;
    padding: 70px 56px 0;
  }
  #root .tournament-card p {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 10px;
  }
  #root .tournament-card .tournament-date {
    margin-top: 20px;
  }
  #root .tournament-card .tournament-date span {
    font-size: 28px;
    line-height: 34px;
  }
  #root .tournament-card .tournament-date span:first-child {
    margin-right: 46px;
    margin-bottom: 10px;
  }
  #root .tournament-card .prize-block {
    margin-top: 50px;
  }
  #root .tournament-card .prize-btn {
    padding: 16px 56px;
  }
  #root .tournament-card .prize-btn img {
    width: 38px;
    margin-left: 10px;
  }
  #root .back-button {
    margin-top: 130px;
    font-size: 32px;
  }
  #root .tournament-single-header .tournament-date {
    margin-top: 64px;
    margin-bottom: 44px;
  }
  #root .tournament-single-header .tournament-date .tournament-start {
    margin-right: 40px;
  }
  #root .tournament-single-btns-container {
    margin-bottom: 50px;
  }
  #root .tournament-single-prize-block .prize-title {
    margin-right: 44px;
  }
  #root .tournament-single-prize-block .prize-btn {
    margin-right: 44px;
    padding: 16px 56px;
  }
  #root .tournament-single-prize-block .prize-btn img {
    width: 38px;
    margin-left: 10px;
  }
  #root .tournament-description {
    padding: 40px;
  }
  #root .tournament-description .account-table {
    max-width: 750px;
  }
  #root .account-table th {
    padding: 30px;
    font-size: 30px;
  }
  #root .tournament-table tbody td,
  #root .tournament-table .device-title div {
    font-size: 28px;
  }
  #root .account-table td {
    min-height: 100px;
    padding: 20px;
  }
  #root .tournament-description .account-table td div.first-place,
  #root .tournament-description .account-table td div.second-place,
  #root .tournament-description .account-table td div.third-place,
  #root .tournament-description .account-table td div.empty-place {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    margin-bottom: -20px;
  }
  #root .tournament-detail-cms {
    max-width: 1040px;
  }
  #root .tournament-table .actual-head th {
    font-size: 24px;
  }
  #root .no-results {
    padding: 30px;
  }
  #root #tournaments-page .games-container {
    margin-top: 50px;
  }
  #root .bonuses-container {
    perspective: 3000px;
    /* grid-template-columns: repeat(auto-fill, minmax(580px, 772px)); */
    grid-template-columns: repeat(auto-fill, minmax(580px, 675px));
    grid-gap: 40px !important;
    margin-bottom: 50px;
  }
  #root .bonus-top {
    height: 616px;
  }
  #root .bonus-card .btn-container {
    height: 624px;
  }
  #root .bonus-card .bonus-info {
    height: 172px;
    padding: 0 56px;
  }
  #root .bonus-card p {
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .bonus-description-opn * {
    font-size: 36px !important;
  }
  #root .bonus-description-text .bonus-description-title,
  #root .bonus-description-opn h1 {
    margin-bottom: 60px;
    font-size: 96px !important;
  }
  #root .bonus-description-text .dang-big-paragraph {
    margin-left: 60px;
    margin-right: 60px;
    padding: 40px 0;
  }
  #root .bonus-description-text .dang-big-paragraph-left,
  #root .bonus-description-text .dang-big-paragraph-right {
    font-size: 48px !important;
  }
  #root .bonus-description-text .bonus-description-dang-text li,
  #root .bonus-description-opn li {
    margin-bottom: 30px;
  }
  #root .bonus-description-text .bonus-description-dang-text .bc-icon,
  #root .premium-vip-container.bonus-page .premium-vip-progress-top .pale .bc-icon,
  #root .premium-vip-content-right-label .bc-icon {
    width: 38px;
    margin-right: 6px;
  }
  #root .bonus-description-text .dang-big-paragraph:first-of-type::after {
    height: 18px;
    bottom: -30px;
  }
  #root .bonus-description-bottom {
    margin-top: 60px;
  }
  #root .bonus-description-bottom .laptop {
    max-height: 250px;
    top: -150px;
    left: -150px;
  }
  #root .coming-soon-mask {
    font-size: 200px;
  }
  #root .premium-vip-container.bonus-page {
    padding: 40px 100px;
    margin-bottom: 60px;
  }
  #root .premium-vip-container.bonus-page .premium-vip-title {
    margin-bottom: 20px;
  }
  #root .premium-vip-content-right-label {
    font-size: 36px;
  }
  #root .save-btn {
    /* width: 316px; */
    height: 100px;
    margin-right: 48px;
  }
  #root .save-btn,
  #root .change-password,
  #root .cancel-btn {
    border-radius: 14px;
  }
  #root .change-password p,
  #root .save-btn p,
  #root .cancel-btn p {
    font-size: 32px;
    line-height: 38px;
  }
  #root .bonus-description-content .save-btn {
    max-width: 400px;
    min-width: 240px;
    padding: 0 20px;
  }
  #root .bonus-description-text-bottom {
    margin-top: 50px;
  }
  #root .bonus-description-alert-container {
    margin-top: 60px;
    padding: 30px 0;
  }
  #root .bonus-description-alert-container-top {
    margin-bottom: 8px;
    font-size: 28px !important;
    line-height: 34px;
  }
  #root .bonus-description-alert-container-bottom {
    font-size: 32px !important;
    line-height: 38px;
  }
  #root .levels-main-info-container {
    margin: 120px 0 250px;
    padding: 80px;
    border-radius: 14px;
  }
  #root .small-ring-bg {
    width: 550px;
    height: 553px;
    /* bottom: 30px; */
    background-image: url("../images/bg/levels/bg-ring-small-4k.png");
    opacity: 1;
    filter: blur(7px);
  }
  #root .big-ring-bg {
    width: 1000px;
    height: 1000px;
    /* right: -150px; */
    /* bottom: -70px; */
    /* background-size: 85%; */
  }
  #root .levels-main-info-container-title {
    width: 68%;
    margin-bottom: 150px;
    font-size: 84px;
    line-height: 94px;
  }
  #root .levels-main-info-container-title .oval-text {
    margin-left: 6px;
    font-size: 84px;
    line-height: 94px;
  }
  #root .levels-main-info-container-body-item {
    margin-bottom: 48px;
  }
  #root .body-item-image {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }
  #root .body-item-title {
    margin-bottom: 16px;
    font-size: 48px;
    line-height: 60px;
  }
  #root .body-item-description {
    font-size: 32px;
    line-height: 48px;
  }
  #root .levels-second-info-container-title {
    margin-bottom: 70px;
    font-size: 84px;
    line-height: 94px;
  }
  #root .levels-page .modal-body {
    margin-bottom: 250px;
    border-radius: 14px;
  }
  #root .levels-carousel-container {
    margin-bottom: 250px;
  }
  #root .levels-carousel-top {
    margin-bottom: 20px;
  }
  #root .levels-carousel-title {
    font-size: 84px;
    line-height: 94px;
  }
  #root .levels-carousel-arrows {
    gap: 25px;
  }
  #root .levels-carousel-arrow {
    width: 80px;
    height: 80px;
    border-radius: 14px;
  }
  #root .levels-carousel-arrow svg {
    width: 38px;
    height: auto;
  }
  #root .levels-carousel-description {
    margin-bottom: 70px;
    font-size: 32px;
    line-height: 48px;
  }
  #root .levels-carousel {
    margin-left: -20px;
    margin-right: -20px;
    /* gap: 40px; */
  }
  #root .levels-page .modal-data {
    /* width: calc(100% - 40px); */
    padding: 50px;
    border-width: 3px;
    border-radius: 14px;
  }
  #root .levels-terms-title {
    margin-bottom: 70px;
    font-size: 84px;
    line-height: 94px;
  }
  #root .levels-terms ul {
    padding-left: 30px;
  }
  #root .levels-terms li {
    padding: 0 0 40px 40px;
    background-position: 0 18px;
    background-size: 14px;
    font-size: 32px;
    line-height: 48px;
  }
  #root .premium-page-container {
    max-width: 4620px;
  }
  #root .premium-vertical-container {
    grid-template-columns: repeat(auto-fill, 514px);
    grid-gap: 20px !important;
    margin-bottom: 30px;
  }
  #root .premium-vertical-block,
  #root .premium-horizontal-block,
  #root .special-benefits-container {
    padding: 40px;
  }
  #root .premium-bronze,
  #root .premium-silver,
  #root .premium-gold {
    background-position: right 60px top 0;
    background-size: 120px;
  }
  #root .premium-vertical-title {
    padding: 50px 160px 24px 0;
    font-size: 44px;
  }
  #root .premium-vertical-bottom {
    padding-top: 46px;
  }
  #root .premium-money {
    margin-bottom: 40px;
  }
  #root .premium-money-title {
    margin-bottom: 6px;
  }
  #root .premium-money-text {
    font-size: 26px;
    line-height: 36px;
  }
  #root .premium-checklist {
    padding-left: 60px;
  }
  #root .premium-checklist li {
    padding: 20px 0;
    padding-right: 20px;
    line-height: 50px;
  }
  #root .premium-horizontal-container {
    margin-bottom: 150px;
  }
  #root .premium-horizontal-block {
    margin-bottom: 30px;
  }
  #root .premium-horizontal-left {
    margin-bottom: 70px;
  }
  #root .premium-platinum .premium-horizontal-left,
  #root .premium-diamond .premium-horizontal-left,
  #root .premium-exclusive .premium-horizontal-left {
    background-position: center bottom 90px;
    background-size: 120px;
  }
  #root .premium-horizontal-title {
    min-width: 340px;
    font-size: 44px;
  }
  #root .premium-horizontal-container .premium-money-container,
  #root .premium-horizontal-container .premium-checklist {
    padding: 0 40px;
  }
  #root .special-benefits-container {
    margin-bottom: 60px;
  }
  #root .special-benefits-container p:first-of-type {
    margin: 0 0 64px 0;
  }
  #root .special-benefits-container p:not(:first-of-type) {
    margin: 10px;
    font-size: 28px;
  }
  #root .width-container-right-desktop {
    max-width: 800px;
    margin-top: 164px;
    padding-right: 80px;
  }
  #root .general-information-container {
    margin-bottom: 90px;
    padding: 100px 40px 40px;
  }
  #root .general-information-title {
    margin-bottom: 50px;
    padding-bottom: 24px;
    font-size: 44px;
    line-height: 50px;
  }
  #root .general-information-text {
    font-size: 32px;
    line-height: 50px;
  }
  #root .providers-block {
    grid-template-columns: repeat(auto-fill, minmax(490px, 1fr));
    grid-gap: 40px !important;
    margin-bottom: 50px;
  }
  #root .prov-card .prov-img {
    height: 348px;
  }
  #root .prov-card .prov-discription {
    padding: 44px 26px;
  }
  #root .prov-card .prov-count {
    margin-top: 14px;
    font-size: 28px;
  }
  #root .prov-card .playnow-btn {
    height: calc(100% - 348px);
  }
  #root .providers-page .back-button {
    margin-top: 70px;
  }
  #root .page-title.providers-games-title {
    margin-top: 80px;
  }
  #root .list-provs {
    margin-top: 80px;
  }
  #root .account-container-header {
    height: 110px;
    margin-top: 60px;
  }
  #root .account-header-tab {
    padding: 0 20px;
  }
  #root .account-header-tab .img-center-account {
    height: 70px;
    margin-top: -30px;
    margin-bottom: 10px;
    background-size: contain;
  }
  #root .account-header-tab p {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .account-container {
    margin-bottom: 100px;
  }
  #root .account-container-body {
    min-height: 200px;
  }
  #root .account-fields-container,
  #root .account-table,
  #root .account-verification,
  #root .account-closure {
    min-height: 1160px;
    padding: 50px 74px;
  }
  #root .account-fields-container {
    background-size: 1175px auto;
    background-position: right 240px;
  }
  #root .input-container {
    /* max-width: 700px; */
    margin-top: 40px;
  }
  #root .input-container > span {
    font-size: 28px;
    line-height: 34px;
  }
  #root .input-container input {
    height: 100px;
    margin-top: 10px;
    padding-left: 40px;
    padding-right: 80px;
    /* font-size: 28px; */
    border-radius: 14px;
  }
  #root .input-container input:focus {
    border: 3px solid var(--green);
  }
  #root .input-container input::placeholder {
    font-size: 28px;
  }
  #root ion-select {
    height: 100px;
    margin-top: 10px;
    /* font-size: 28px; */
  }
  #root ion-select.gender,
  #root input.zcode {
    width: 316px;
  }
  #root .input-container .date-container ion-select {
    width: 228px;
    margin-right: 8px;
  }
  #root .input-container ion-item.ion-item-select {
    margin-top: 10px;
    --inner-padding-start: 32px;
    border-radius: 14px;
  }
  #root ion-item.ion-item-select ion-select {
    --padding-start: inherit;
  }
  #root .input-container ion-item.ion-item-select.gender {
    width: 316px;
  }
  #root .input-container .date-container ion-item.ion-item-select {
    margin-right: 8px;
  }
  #root .deposite-container {
    max-width: 700px;
  }
  #root .deposite-container .input-container:not(.deposit-daily) {
    width: 332px;
  }
  #root .user-image {
    width: 172px;
    margin-top: 40px;
  }
  #root .avatar-title {
    margin-bottom: 20px;
  }
  #root .user-image span:first-child {
    font-size: 28px;
    line-height: 34px;
  }
  #root .avatar-link {
    width: 100px;
    height: 80px;
    bottom: -30px;
    left: -20px;
    animation: 0.9s ease-in infinite reverse movesvg4k;
  }
  @-webkit-keyframes movesvg4k {
    from {
      height: 80px;
    }
    to {
      height: 90px;
    }
  }
  @keyframes movesvg4k {
    from {
      height: 80px;
    }
    to {
      height: 90px;
    }
  }
  #root .account-container-body .userpic {
    width: 172px;
    height: 172px;
  }
  #root .avatar-prev {
    width: 172px;
    height: 172px;
  }
  #root .pick-avatar-default {
    margin-top: 50px;
    padding: 20px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .account-container-footer,
  #root .account-verification .account-footer-btns {
    height: 200px;
  }
  #root .change-password {
    width: 407px;
    height: 100px;
    margin-left: 74px;
  }
  #root .cancel-btn {
    width: 316px;
    height: 100px;
    margin-right: 32px;
  }
  #root .account-title {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 60px;
  }
  #root .account-fields-container .password,
  #root .account-fields-container .password-opened {
    padding-right: 90px;
    background-position: right 30px top 50%, 0 0;
    background-size: 32px auto;
  }
  #root .account-fields-container .password::placeholder,
  #root .account-fields-container .password-opened::placeholder {
    font-size: 28px;
  }
  #root .input-container .validation-message {
    margin-top: 12px;
    font-size: 24px;
  }
  #root .input-container .validation-message::before {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  #root .save-btn.save-psword {
    width: auto;
    padding: 0 100px;
  }
  #root .number-container .ccode {
    max-width: 200px;
    margin-right: 8px;
  }
  #root .games-limit > div {
    max-width: 700px;
  }
  #root .input-container-item {
    height: 100px;
    margin-top: 10px;
    border-radius: 14px;
    /* padding-left: 26px; */
  }
  #root .input-container-item input {
    margin-top: 0;
    padding-left: 80px;
    /* border: none; */
  }
  #root .input-container-item .bc-icon {
    width: 38px;
    left: 26px;
    margin-top: 5px;
  }
  #root .sort-btns {
    padding-right: 24px;
  }
  #root .sort-btns div {
    width: 20px;
    height: 14px;
  }
  #root .sort-btns .sort-btn-up {
    margin-bottom: 4px;
  }
  #root .sort-btns div svg {
    width: 20px;
    height: 14px;
  }
  #root .device-title {
    font-size: 28px;
  }
  #root .device-title div:first-child {
    font-size: 40px;
    margin-bottom: 10px;
  }
  #root .address-title {
    font-size: 30px;
  }
  #root .current-device ion-checkbox {
    width: 36px;
    height: 36px;
  }
  #root ion-checkbox::part(container) {
    padding: 4px;
  }
  #root .action-btn {
    width: 332px;
  }
  #root .action-btn div {
    height: 80px;
    padding: 0 40px;
    font-size: 32px;
    line-height: 80px;
  }
  #root .account-verification .account-title {
    margin-bottom: 20px;
  }
  #root .account-description {
    font-size: 28px;
    line-height: 40px;
  }
  #root .verification-steps {
    margin-top: 80px;
  }
  #root .step-one,
  #root .step-two {
    max-width: 700px;
  }
  #root .step-one {
    margin-right: 260px;
  }
  #root .step-title {
    font-size: 32px;
  }
  #root .step-title svg {
    width: 38px;
    height: 38px;
    margin-right: 30px;
  }
  #root .file-loading {
    max-width: 700px;
    margin-top: 40px;
    border-width: 2px;
    background-size: 46px auto;
  }
  #root .file-zone-container {
    height: 270px;
  }
  #root .thumbs-titles {
    padding: 10px 16px;
  }
  #root .step-two-content {
    margin-top: 40px;
  }
  #root .step-two-content p {
    font-size: 24px;
    line-height: 36px;
  }
  #root .step-two.add-docks {
    min-height: 780px;
  }
  #root .account-verification .account-footer-btns {
    width: calc(100% + 148px);
    margin-left: -74px;
    margin-top: 60px;
    margin-bottom: -74px;
  }
  #root .account-closure {
    max-width: 1000px;
  }
  #root .account-closure-content p {
    font-size: 28px;
    line-height: 34px;
  }
  #root .account-closure-content p:last-of-type {
    line-height: 34px;
  }
  #root .save-btn.delete {
    width: 492px;
  }
  #root .account-header-tab .img-center-balance {
    height: 70px;
    margin-top: -30px;
    margin-bottom: 10px;
    background-size: contain;
  }
  #root .balance-fields-container {
    min-height: 1160px;
    padding: 50px 74px;
    background-position: right bottom 140px;
    background-size: 724px auto;
  }
  #root .balance-fields-wrapper {
    width: 1080px;
  }
  #root .balance-fields-wrapper:first-child {
    margin-bottom: 80px;
  }
  #root .balance-fields-wrapper .input-container.select {
    margin-right: 80px;
  }
  #root .field.currency::before {
    width: 38px;
    height: 38px;
    margin-right: 20px;
  }
  #root .field.currency::before,
  #root .field.currency::before {
    background-size: contain;
  }
  #root .currency-item::part(native) {
    --padding-end: 15px;
    background-position: 24px;
    background-size: 48px auto;
  }
  #root .currency-item ion-label {
    margin-left: 80px !important;
  }
  #root .balance-fields-wrapper .input-container.comission {
    width: 300px;
  }
  #root .input-container .body-field {
    height: 100px;
    margin-top: 10px;
    padding: 0 30px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .balance-fields-wrapper .input-container.address {
    margin-bottom: 60px;
  }
  #root .input-container .body-field svg {
    width: 34px;
    height: auto;
  }
  #root .balance-fields-wrapper .qr {
    width: 320px;
    height: 320px;
  }
  #root .qr-label {
    width: 490px;
    margin-left: 0px;
    margin-top: 10px;
    font-size: 32px;
    line-height: 48px;
    align-self: flex-start;
  }
  #root .text-container-wrapper .text-container {
    margin-bottom: 40px;
    padding: 40px;
    border-width: 2px;
    font-size: 28px;
    line-height: 40px;
  }
  #root .balance-fields-deposites-container {
    margin-bottom: 60px;
  }
  #root .balance-fields-deposites-container .deposites-container {
    min-width: 700px;
    margin-right: 110px;
  }
  #root .deposites-container {
    max-width: 700px;
    padding: 36px 48px;
  }
  #root .deposite-line {
    padding-bottom: 40px;
  }
  #root .deposite-line-value img {
    width: auto;
    height: 48px;
    margin-left: 10px;
  }
  #root .rates-container {
    min-width: 750px;
  }
  #root .rates-title {
    margin-bottom: 10px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .rates-body {
    padding: 40px;
  }
  #root .rates-body-item {
    margin-bottom: 40px;
  }
  #root .rates-body-item img.chip-icon {
    width: auto;
    height: 48px;
    margin-right: 20px;
  }
  #root .rates-body-item-name {
    max-width: 400px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .rates-body-item-value {
    font-size: 28px;
    line-height: 34px;
  }
  #root .rates-body-item-value .bc-icon {
    width: auto;
    height: 48px;
    margin-left: 10px;
  }
  #root .account-footer-btns .calculated {
    margin-left: 74px;
    font-size: 36px;
    line-height: 42px;
  }
  #root .withdraw-amount-input-container {
    height: 100px;
    margin-top: 10px;
    padding-left: 30px;
  }
  #root .withdraw-amount-input-container .icon-container {
    width: 48px;
    min-width: 48px;
    height: 48px;
  }
  #root .withdraw-amount-input-container .amount-input {
    padding-left: 20px;
  }
  #root .withdraw-amount-input-container .max-btn {
    padding: 0 48px;
  }
  #root .input-container-item .max-btn {
    height: calc(100% - 4px);
    padding: 0 48px;
    border-radius: 14px;
  }
  #root .balance-title {
    margin-bottom: 60px;
    font-size: 48px;
    line-height: 56px;
  }
  #root .balance-title.personal-balance-title {
    margin-bottom: 30px;
  }
  #root .balance-fields-deposites-container .text-container {
    margin-top: 84px;
    padding: 40px;
    border-width: 2px;
    font-size: 28px;
    line-height: 40px;
  }
  #root .loan-request-header,
  #root .loan-request-header strong {
    font-size: 48px;
    line-height: 56px;
  }
  #root .balance-fund-top > div,
  #root .balance-fund-bottom {
    max-width: 900px;
  }
  #root .balance-content {
    font-size: 28px;
    line-height: 40px;
  }
  #root .balance-cards img:first-child {
    width: 168px;
    margin-right: 40px;
  }
  #root .balance-cards img:last-child {
    width: 200px;
  }
  #root .statistics-page {
    padding: 60px;
  }
  #root .stats p {
    font-size: 48px;
    line-height: 56px;
  }
  #root .pie-container {
    padding: 0 50px;
  }
  #root .statistics-page ion-select {
    width: 300px;
    margin-bottom: 20px;
    padding-left: 30px;
    background: var(--user-btn-bg);
  }
  #root .pies-container .pie {
    /* min-width: 670px; */
    min-width: 830px;
  }
  #root .pie-header {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 32px;
  }
  #root .data-line {
    margin: 20px auto;
  }
  #root .color-bg {
    width: 44px;
    height: 44px;
  }
  #root .data-card .chip-icon {
    width: 38px;
    margin-left: 20px;
  }
  #root .data-card .number {
    margin-left: 10px;
    font-size: 52px;
    line-height: 78px;
  }
  #root .single-data-line > p {
    font-size: 24px;
    line-height: 30px;
  }
  #root .games-chart-container {
    margin-top: 40px;
  }
  #root .games-chart {
    padding: 60px;
  }
  #root .apexcharts-xaxis-label tspan {
    font-size: 24px;
  }
  #root .hist-header .account-header-tab {
    max-width: 312px;
  }
  #root .img-center-history {
    height: 70px;
    margin-top: -30px;
    margin-bottom: 10px;
    background-size: contain;
  }
  #root .transactions-page-container {
    margin-bottom: 40px;
    padding: 40px 0;
  }
  #root .buttons-container .history-button {
    max-width: 312px;
    height: 100px;
    font-size: 32px;
    border-radius: 14px;
  }
  #root .daypicker-container {
    margin-top: 56px;
  }
  #root .daypicker-container .input-title {
    font-size: 28px;
  }
  #root .InputFromTo .DayPickerInput,
  #root .InputFromTo-to .DayPickerInput {
    margin-right: 20px;
  }
  #root .InputFromTo-to input,
  #root .DayPickerInput input {
    max-width: 480px;
    height: 100px;
    margin-top: 10px;
    border-radius: 14px;
    font-size: 28px;
  }
  #root .daypicker-btns-ovrl .showbtn {
    width: 370px;
    height: 100px;
    border-radius: 14px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .notransactions {
    margin-top: 40px;
    font-size: 40px;
  }
  #root .transaction-graph-container {
    max-width: inherit;
    margin-top: 40px;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }
  #root .hist-table tr {
    height: 100px;
  }
  #root .apexcharts-title-text {
    font-size: inherit;
  }
  #root .apexcharts-legend-marker {
    width: 32px !important;
    height: 32px !important;
  }
  #root .apexcharts-legend-text {
    top: -8px;
  }
  #root .transaction-graph-container.with-pie {
    max-width: 70%;
  }
  #root .with-pie {
    max-height: 728px;
  }
  #root .with-pie .pie svg {
    max-width: 320px;
  }
  #root .affiliate-content {
    font-size: 28px;
    line-height: 40px;
  }
  #root .affiliate-content.small {
    max-width: 1332px;
  }
  #root .affiliate-iframe-container {
    margin-bottom: 50px;
  }
  #root .refferal-link-label {
    margin: 10px 0;
  }
  #root .refferal-link-field {
    max-width: 1080px;
    height: 100px;
    padding: 0 30px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .refferal-link-field svg {
    width: 34px;
    height: auto;
  }
  #root .affiliate-content-title {
    font-size: 40px;
    line-height: 48px;
  }
  #root .affiliate-content-note-container {
    margin-top: 200px;
    padding: 40px;
    border-width: 2px;
  }
  #root .affiliate-content-top {
    margin-bottom: 60px;
  }
  #root .affiliate-content-top.funds-content-top .funds-withdraw-btn {
    margin-bottom: 50px;
    padding: 0 60px;
  }
  #root .affiliate-content-top.funds-content-top .section-info-message {
    padding: 32px;
    border-radius: 14px;
  }
  #root .affiliate-content-top .section-info-message img {
    min-width: 44px;
    width: 44px;
    height: 44px;
    margin-right: 32px;
  }
  #root .affiliate-content-top .section-info-message p {
    font-size: 28px;
    line-height: 40px;
  }
  #root .affiliate-content-top.funds-content-top .section-message {
    margin-top: 50px;
    padding: 30px 40px;
  }
  #root .section-message .section-message-normal-text {
    font-size: 28px;
    line-height: 34px;
  }
  #root .section-message .section-message-big-text {
    font-size: 32px;
    line-height: 38px;
  }
  #root .affiliate-table {
    margin-top: 40px;
  }
  #root .account-table.affiliate-table tr {
    height: 110px;
  }
  #root .account-table.affiliate-table div img {
    width: 36px;
    margin-left: 10px;
  }
  #root .affiliate-table {
    padding: 0;
  }
  #root .leader-board-container.affiliate-table {
    margin-top: 200px;
    border-radius: 14px;
  }
  #root .leader-board-container.account-table .leader-board-header {
    height: 140px;
    padding: 0 35px;
    gap: 24px;
  }
  #root .leader-board-header-tab {
    padding: 10px 20px;
    border-radius: 14px;
    font-size: 28px;
    line-height: 44px;
  }
  #root .leader-board-table {
    min-height: 1290px;
    padding: 0 35px 35px;
  }
  #root .table-swiper {
    max-height: 1100px;
  }
  #root .leader-board-container .leader-board-table .leader-board-table-head div {
    height: 155px;
    padding: 32px;
    font-size: 28px;
    line-height: 44px;
  }
  #root .leader-board-container .leader-board-table div.swiper-slide {
    border-radius: 14px;
  }
  #root .leader-board-container .leader-board-table .swiper-slide .address-title {
    padding: 32px;
  }
  #root .leader-board-container .leader-board-table .swiper-slide .address-title img {
    width: 42px;
    height: 42px;
  }
  #root .leader-board-container .leader-board-table .swiper-slide .address-title .first-img img {
    margin: 0 12px 0 0;
  }
  #root .leader-board-container .leader-board-table .swiper-slide .address-title .last-img img {
    margin: 0 0 0 12px;
  }
  #root .no-entries-container {
    margin-top: 200px;
  }
  #root .no-entries-container img {
    width: 192px;
    height: auto;
  }
  #root .no-entries-label.big {
    margin-top: 60px;
    margin-bottom: 30px;
    font-size: 48px;
    line-height: 56px;
  }
  #root .affiliate-content-top-item {
    font-size: 32px;
    line-height: 38px;
  }
  #root .affiliate-content-top-item-label {
    margin-bottom: 14px;
  }
  #root .affiliate-content-top-item-value img {
    width: 36px;
    margin-left: 10px;
  }
  #root .affiliate-campaign-table-item {
    margin-bottom: 24px;
  }
  #root .affiliate-campaign-item-header {
    height: 110px;
    padding: 0 50px 0 30px;
  }
  #root .affiliate-campaign-item-header-value img {
    width: 36px;
    margin-left: 10px;
  }
  #root .affiliate-campaign-table-item .affiliate-campaign-item-header-value svg {
    width: 24px;
    height: auto;
  }
  #root .affiliate-campaign-item-body {
    max-height: 320px;
  }
  #root .affiliate-content-top.small {
    margin-bottom: 20px;
    padding: 30px 30px 0;
  }
  #root .affiliate-content-top.small .affiliate-content-top-item {
    margin-right: 50px;
    font-size: 28px;
  }
  #root .affiliate-campaign-item-referral {
    padding: 0 30px 30px;
  }
  #root .referral-body-item-top {
    margin-bottom: 10px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .refferal-link-field.contrast-bg {
    width: 1080px;
  }
  #root .referral-body-item-bottom .delete-btn {
    margin-left: 40px;
  }
  #root .terms.width-container {
    margin: 60px auto;
  }
  #root .terms .paragraph {
    max-height: 120px;
    padding: 0 50px;
    padding-right: 130px;
    margin-bottom: 20px;
  }
  #root .terms .h3-smaller {
    font-size: 32px !important;
    margin: 44px 0;
  }
  #root .terms .paragraph .h3-smaller::after {
    width: 56px;
    height: 56px;
    background-position-y: 22px;
    background-size: 24px auto;
    border-width: 2px;
    top: 30px;
    right: 50px;
  }
  #root .terms:not(.faq) * {
    font-size: inherit !important;
  }
  #root .terms .paragraph h6 img {
    width: 36px;
    height: 36px;
    margin-right: 16px;
  }
  #root .map-general {
    height: 800px;
  }
  #root .point-map::before {
    top: -100px;
    left: -118px;
    width: 240px;
    height: 80px;
    background-size: 90%;
  }
  #root .point-map:after {
    top: -20px;
    margin-left: -20px;
    border-top: solid 20px #ffffff;
    border-left: solid 20px transparent;
    border-right: solid 20px transparent;
  }
  #root .terms.faq .paragraph {
    max-height: 150px;
  }
  #root .terms .paragraph.opened {
    max-height: 4000px;
  }
  #root .terms .paragraph-left {
    height: 150px;
  }
  #root .terms .paragraph-left svg {
    width: 60px;
    height: auto;
    margin-right: 40px;
  }
  #root .terms.faq .paragraph .h3-smaller::after {
    top: 44px;
  }
  #root .terms.faq .paragraph-content {
    margin-left: 100px;
    margin-bottom: 40px;
  }
  #root .ios-app-setup-subtitle {
    font-size: 40px;
    line-height: 48px;
  }
  #root .ios-app-setup-content {
    height: 820px;
    padding: 80px 70px;
    margin-top: 320px;
  }
  #root .ios-app-setup-content-steps {
    margin-bottom: 70px;
  }
  #root .step-item {
    margin-bottom: 10px;
    padding: 12px 48px;
    border-radius: 40px;
    border-width: 2px;
    font-size: 48px;
    line-height: 56px;
  }
  #root .ios-app-setup-content-title {
    margin-bottom: 50px;
    font-size: 40px;
    line-height: 48px;
  }
  #root .ios-app-setup-content-description {
    line-height: 48px;
  }
  #root .ios-app-setup-content-btns {
    margin-top: 20px;
  }
  #root .ios-app-setup-content-right {
    width: 1296px;
    height: 1190px;
    right: -240px;
    bottom: -40px;
  }
  #root .crashpage .crash-content {
    margin-top: 100px;
  }
  #root .crashpage .crash-video-container {
    margin-bottom: 100px;
  }
  #root .crashpage .crash-video-title {
    padding-left: 70px;
    padding-right: 70px;
    margin-bottom: 70px;
  }
  #root .crashpage .crash-video-title p {
    font-size: 84px;
    line-height: 88px;
  }
  #root .crashpage .crash-game-container {
    /* height: 854px; */
    min-height: 850px;
    padding: 80px 50px;
    margin-bottom: 100px;
    border-radius: 8px;
  }
  #root .crashpage .crash-game-big-planet {
    width: 800px;
    height: 800px;
    top: -400px;
    left: -200px;
  }
  #root .crashpage .crash-game-container-left {
    min-width: 445px;
    width: 445px;
  }
  #root .crashpage .crash-game-small-planet {
    width: 400px;
    height: 400px;
    bottom: -200px;
  }
  #root .crashpage .crash-game-title {
    font-size: 64px;
    line-height: 74px;
  }
  #root .crashpage .crash-game-description {
    font-size: 32px;
    line-height: 48px;
  }
  #root .crashpage .crash-subscribe-container {
    height: 420px;
    padding: 130px 80px 130px 70px;
  }
  #root .crashpage .crash-subscribe-left.animate {
    animation: 0.2s move4k1;
  }
  @keyframes move4k1 {
    from {
      margin-top: -40px;
      opacity: 0;
    }
    to {
      margin-top: 0;
      opacity: 1;
    }
  }
  #root .crashpage .crash-subscribe-left.animate2 {
    animation: 0.2s move4k2;
  }
  @keyframes move4k2 {
    from {
      margin-top: -40px;
      opacity: 0;
    }
    to {
      margin-top: 0;
      opacity: 1;
    }
  }
  #root .crashpage .crash-subscribe-left svg {
    height: 126px;
    margin-right: 32px;
  }
  #root .crashpage .crash-subscribe-title {
    font-size: 64px;
    line-height: 74px;
  }
  #root .crashpage .crash-subscribe-description {
    font-size: 32px;
    line-height: 48px;
  }
  #root .crashpage .crash-subscribe-right .input-container {
    margin-right: 20px;
  }
  #root .crashpage .crash-subscribe-right .input-container span {
    font-size: 28px;
    line-height: 44px;
  }
  #root .crashpage .crash-subscribe-right .input-container input {
    font-size: 32px;
    line-height: 38px;
  }
  #root .crashpage .crash-subscribe-right .send-btn {
    min-width: 100px;
    width: 100px;
    height: 100px;
    border-radius: 8px;
  }
  #root .crashpage .crash-subscribe-right .send-btn svg {
    width: 48px;
    height: auto;
  }
  #root .mod-window::part(content) {
    max-width: 1770px;
    min-height: 1150px;
    margin: 50px;
  }
  #root .img-container {
    min-height: 1150px;
    max-width: 820px;
    background: url("../images/bg/login-img.png");
  }
  #root .absolute-close-modal {
    width: 80px;
    height: 80px;
  }
  #root .absolute-close-modal > * {
    width: 28px;
    height: 28px;
  }
  #root .modal-description {
    max-width: 700px;
    margin-bottom: 40px;
    font-size: 48px;
    line-height: 56px;
  }
  #root .mod-container .login-btn {
    max-width: 700px;
    margin: 270px auto 0;
  }
  #root .logout-container .login-btn {
    margin-top: 50px;
  }
  #root .modal-question {
    margin-top: 48px;
  }
  #root .modal-title {
    max-width: 700px;
    margin-top: 100px;
    margin-bottom: 14px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .remember-me {
    max-width: 700px;
    margin-top: 60px;
  }
  #root ion-checkbox {
    width: 36px;
    height: 36px;
    --border-width: 2px;
  }
  #root .remember-me span {
    font-size: 28px;
    line-height: 34px;
    margin-left: 15px;
    margin-top: 2px;
  }
  #root .forgot-password {
    margin-top: 2px;
    font-size: 28px;
    line-height: 34px;
  }
  #root .mod-container .btn {
    font-size: 32px;
    line-height: 38px;
    padding: 40px;
    height: 100px;
  }
  #root .forgot-disclaimer {
    font-size: 32px;
    line-height: 38px;
    max-width: 700px;
    margin: 20px auto 80px;
  }
  #root .mod-container .forgot-btn {
    max-width: 700px;
    margin: 70px auto 0;
  }
  #root .register-modal::part(content) {
    min-height: 1400px;
  }
  #root .register-modal .mod-container .img-container {
    min-height: 1400px;
  }
  #root .progress-bar {
    max-width: 700px;
    margin: -20px auto 20px;
  }
  #root .progress-bar ul {
    width: 108px;
  }
  #root .progress-bar li::before {
    width: 46px;
    height: 6px;
  }
  #root .promo-field {
    margin-bottom: -180px;
  }
  #root .modal-buttons {
    max-width: 700px;
    margin-bottom: 70px;
  }
  #root .bit.btn.only-btn {
    margin: 184px 0 0 auto;
  }
  #root .modal-buttons .btn {
    max-width: 316px;
  }
  #root .policy-checkbox {
    max-width: 700px;
    margin-top: 40px;
  }
  #root .policy-checkbox ion-checkbox {
    min-width: 37px;
    min-height: 37px;
    --border-width: 2px;
  }
  #root .policy-checkbox span {
    font-size: 28px;
    line-height: 34px;
    margin-left: 15px;
    margin-top: 2px;
  }
  #root .btn-back::before {
    width: 30px;
    height: 20px;
  }
  #root .btn-back span {
    margin-left: 20px;
  }
  #root .auth-modal.mod-window.auto-height::part(content) {
    max-width: 1780px;
    border-radius: 14px;
  }
  #root .auth-modal .auth-modal-right {
    width: 800px;
    min-width: 800px;
    padding: 0 50px;
  }
  #root .auth-modal .auth-modal-header {
    height: 112px;
    min-height: 112px;
  }
  #root .auth-modal .auth-modal-header-btn {
    padding: 10px 20px 9px;
    border-radius: 14px;
    font-size: 28px;
    line-height: 44px;
  }
  #root .auth-modal .auth-modal-header-btn:first-of-type {
    margin-right: 16px;
  }
  #root .auth-modal .auth-modal-header .absolute-close-modal {
    width: 44px;
    height: 44px;
  }
  #root .auth-modal .auth-modal-header .absolute-close-modal svg {
    width: 21px;
    height: 21px;
  }
  #root .auth-modal .auth-modal-body {
    padding: 24px 0 48px;
  }
  #root .auth-modal .mod-container.register .auth-modal-body {
    min-height: 1298px;
  }
  #root .auth-modal .auth-modal-socials {
    padding: 24px 24px 24px 32px;
  }
  #root .auth-modal .auth-modal-socials span {
    font-size: 38px;
    line-height: 38px;
  }
  #root .auth-modal .auth-modal-social-btn {
    min-width: 70px;
    width: 70px;
    height: 70px;
    margin-left: 16px;
  }
  #root .auth-modal .auth-modal-social-btn svg {
    width: 46px;
    height: 46px;
  }
  #root .auth-modal .auth-modal-hl-container {
    height: 94px;
  }
  #root .auth-modal .auth-modal-hl-label {
    margin: 0 16px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .auth-modal .auth-modal-hl-container .line {
    height: 2px;
  }
  #root .auth-modal .auth-modal-inputs-container .input-container {
    margin-top: 24px;
  }
  #root .auth-modal .auth-modal-inputs-container .input-container.promo-field {
    margin-bottom: 0;
  }
  #root .auth-modal .auth-modal-inputs-container .input-container-label,
  #root .auth-modal .auth-modal-inputs-container .input-container-label span {
    font-size: 28px;
    line-height: 44px;
  }
  #root .auth-modal .auth-modal-inputs-container .input-container input {
    padding-left: 32px;
    padding-right: 32px;
  }
  #root .auth-modal auth-modal-inputs-container .input-container input.field-error {
    box-shadow: 0 0 0 3px var(--red);
  }
  #root .auth-modal .auth-modal-inputs-container .input-container.password input {
    padding-right: 100px;
  }
  #root .auth-modal .auth-modal-inputs-container .input-container .eye {
    width: 30px;
    height: 30px;
    top: 90px;
    /* bottom: 35px; */
    right: 35px;
  }
  #root .auth-modal .auth-modal-inputs-container .input-container input:focus-within:not(.field-error) {
    box-shadow: 0 0 0 3px var(--green);
  }
  #root .auth-modal .auth-modal-body .remember-me {
    margin-top: 24px;
  }
  #root .auth-modal .auth-modal-body .remember-me ion-item {
    --min-height: 48px;
    --padding-start: 5px;
    --border-radius: 14px;
  }
  #root .auth-modal .auth-modal-body ion-checkbox {
    --border-width: 4px;
    --border-radius: 8px;
  }
  #root .auth-modal .auth-modal-body .remember-me .remember-me-label {
    margin: 0 0 0 13px;
    font-size: 28px;
  }
  #root .auth-modal .auth-modal-body .forgot-password {
    font-size: 32px;
  }
  #root .auth-modal .auth-modal-body .login-btn {
    margin: 48px 0 0 0;
    border-radius: 14px;
  }
  #root .auth-modal .auth-modal-inputs-container .input-container.select .date-container {
    margin-top: 10px;
    gap: 16px;
  }
  #root .auth-modal .auth-modal-inputs-container .input-container.select ion-select {
    --padding-start: 24px;
  }
  #root .auth-select.select-popover ion-list.sc-ion-select-popover,
  #root .auth-select.select-popover ion-list.sc-ion-select-popover-md {
    max-height: 400px;
  }
  #root .auth-modal .auth-modal-body .modal-buttons {
    margin: 48px 0 0 0;
  }
  #root .auth-modal .auth-modal-body .modal-buttons .cancel-btn {
    padding: 0 60px;
  }
  #root .auth-modal .auth-modal-body .modal-buttons .save-btn {
    margin: 0 0 0 24px;
  }
  #root .auth-modal .doc-container {
    width: 200px;
    margin: 40px auto 60px;
  }
  #root .auth-modal .auth-modal-body .policy-checkbox {
    margin-top: 32px;
  }
  #root .auth-modal .auth-modal-body .policy-checkbox .require-field,
  #root .auth-modal .auth-modal-body .policy-checkbox .policy-label.not-require {
    margin-top: 0;
    margin-left: 13px;
  }
  #root .auth-modal .auth-modal-body .policy-checkbox .policy-label {
    line-height: 40px;
  }
  #root .auth-modal .auth-modal-body .policy-checkbox span a {
    font-size: 28px;
    line-height: 40px;
  }
  #root ion-toast.toast {
    --min-width: 660px;
    --min-height: 86px;
    --border-radius: 14px;
  }
  #root ion-toast.toast::part(container) {
    padding: 10px;
  }
  #root ion-toast.toast::part(icon) {
    width: 38px;
    height: 38px;
  }
  #root ion-toast.toast:not(.toast-with-header)::part(message) {
    font-size: 32px;
    line-height: 38px;
  }
  #root ion-toast.toast.toast-with-header::part(message) {
    font-size: 28px;
    line-height: 40px;
  }
  #root ion-toast.toast.toast-with-header::part(header) {
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 38px;
  }
  #root ion-toast.toast::part(button) {
    font-size: 0.8rem;
    width: 50px;
    height: 50px;
    margin-right: 14px;
    padding: 5px 4px 5px 6px;
  }
  #root .auth-modal.reg-final-modal.mod-window.auto-height::part(content) {
    max-width: 796px;
  }
  #root .reg-final-modal.auth-modal .absolute-close-modal {
    height: 44px;
    width: 44px;
    right: 22px;
    top: 22px;
  }
  #root .reg-final-modal.auth-modal .absolute-close-modal svg {
    width: 21px;
    height: 21px;
  }
  #root .reg-final-modal.auth-modal .modal-data {
    padding: 64px 48px 48px;
  }
  #root .reg-final-modal .reg-final-modal-top img {
    width: 200px;
  }
  #root .reg-final-modal .reg-final-modal-title {
    margin-bottom: 16px;
    font-size: 48px;
    line-height: 60px;
  }
  #root .reg-final-modal .reg-final-modal-description {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 48px;
  }
  #root .reg-final-modal.auth-modal .auth-modal-inputs-container {
    margin-bottom: 60px;
  }
  #root .auth-modal.forget-modal.mod-window.auto-height::part(content) {
    max-width: 800px;
  }
  #root .forget-modal.auth-modal .modal-header {
    height: 92px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .forget-modal.auth-modal .absolute-close-modal {
    width: 44px;
    height: 44px;
  }
  #root .forget-modal.auth-modal .absolute-close-modal svg {
    width: 21px;
    height: 21px;
  }
  #root .forget-modal.auth-modal .forget-modal-body {
    padding: 56px 48px 48px;
  }
  #root .forget-modal .forget-modal-top {
    margin-bottom: 48px;
  }
  #root .forget-modal .forget-modal-top img {
    width: 120px;
    margin-bottom: 24px;
  }
  #root .forget-modal .forget-modal-title {
    padding: 0 30px;
    font-size: 48px;
    line-height: 60px;
  }
  #root .forget-modal .forget-modal-description {
    margin-top: 16px;
    font-size: 32px;
    line-height: 48px;
  }
  #root .forget-modal.auth-modal .auth-modal-inputs-container {
    margin-bottom: 48px;
  }
  #root .forget-modal.auth-modal .save-btn {
    min-width: 194px;
    padding: 0 60px;
  }
  #root .premium-modal.mod-window::part(content) {
    max-width: 680px;
    border-radius: 14px;
  }
  #root .premium-modal .mod-confirm .modal-data {
    padding: 50px 50px 35px;
    border-width: 3px;
    border-radius: 14px;
  }
  #root .premium-modal .absolute-close-modal {
    width: 44px;
    height: 44px;
    top: 20px;
    right: 20px;
  }
  #root .premium-modal .absolute-close-modal svg {
    width: 21px;
    height: 21px;
  }
  #root .promo-modal-hl {
    margin-top: 55px;
    margin-bottom: 40px;
    margin-left: -50px;
    margin-right: -50px;
  }
  #root .promo-modal-bottom p {
    margin: 20px 0 0;
    line-height: 38px;
  }
  #root .modal-data .promo-modal-title {
    font-size: 48px;
    line-height: 56px;
  }
  #root .promo-modal-title.big-modal-title {
    margin-bottom: 50px;
  }
  #root .promo-modal-title-pic {
    width: 380px;
    height: 380px;
    margin-bottom: 40px;
  }
  #root .promo-modal-title.big-modal-title div {
    font-size: 48px;
    line-height: 60px;
  }
  #root .promo-modal-title-info {
    margin-top: 25px;
    padding: 16px 24px;
  }
  #root .promo-modal-title-info img {
    width: 38px;
    height: 38px;
    margin: 0 16px;
  }
  #root .аchieves-item {
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .аchieves-item svg {
    width: 30px;
    min-width: 30px;
    height: 30px;
    margin-right: 20px;
  }
  #root .premium-vip-progress-badge {
    width: 132px;
    height: 60px;
    margin-left: -66px;
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 36px;
  }
  #root .premium-vip-progress-badge svg {
    width: 20px;
    height: 10px;
    bottom: -10px;
  }
  #root .premium-vip-progress-bottom-item:first-of-type {
    margin-right: 74px;
  }
  #root .premium-vip-progress-bottom-item svg {
    width: auto;
    height: 38px;
    margin-right: 10px;
  }
  #root .promo-modal-text {
    margin-top: 60px;
    font-size: 28px;
    line-height: 40px;
  }
  #root .promo-modal-text.alert {
    padding: 40px;
    border-width: 2px;
    border-radius: 10px;
  }
  #root .how-to-charge-modal.mod-window::part(content) {
    max-width: 2120px;
  }
  #root .how-to-charge-modal .mod-confirm .modal-data {
    padding: 0;
  }
  #root .modal-header {
    height: 120px;
    padding: 0 40px;
    font-size: 48px;
    line-height: 60px;
  }
  #root .modal-body {
    padding: 64px 0;
    border-radius: 0px 0px 14px 14px;
  }
  #root .modal-block {
    padding: 0 65px;
    border-right: 2px solid #268a0033;
  }
  #root .modal-block-top {
    margin-bottom: 64px;
  }
  #root .modal-block-top-img {
    width: 230px;
  }
  #root .modal-block-top .gradient-number {
    font-size: 230px;
  }
  #root .modal-block-bottom {
    min-height: 580px;
  }
  #root .modal-block-bottom-title {
    /* margin-bottom: 16px; */
    font-size: 48px;
    line-height: 60px;
  }
  #root .modal-block-bottom-description {
    font-size: 32px;
    line-height: 48px;
  }
  #root .cookie-window::part(content) {
    min-height: 520px;
  }
  #root .mod-confirm-wrapper {
    max-width: 1770px;
  }
  #root .mod-confirm .modal-data {
    padding: 75px 100px;
  }
  #root .modal-content {
    font-size: 28px;
  }
  #root .mod-confirm-wrapper .btn {
    width: 316px;
    margin-top: 60px;
    /* margin-right: 104px; */
  }
  #root .forbidden-window::part(content) {
    min-height: 400px;
  }
  #root .forbidden-modal-description {
    font-size: 28px;
  }
  #root .kyc-window .mod-confirm .modal-data {
    padding: 60px 100px;
  }
  #root .kyc-window .forbidden-modal-logo {
    margin-bottom: 60px;
  }
  #root .kyc-window .forbidden-modal-title {
    margin-bottom: 60px;
    font-size: 48px;
  }
  #root .kyc-window .forbidden-modal-description {
    margin-bottom: 60px;
    font-size: 36px;
  }
  #root .player-info-modal.how-to-charge-modal.mod-window::part(content) {
    max-width: 1060px;
  }
  #root .player-info-modal .modal-header {
    height: 100px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .player-info-modal .modal-body {
    padding: 40px;
  }
  #root .player-info-modal .player-info-block {
    padding: 45px 0;
    border-radius: 14px;
  }
  #root .player-info-block-item {
    padding: 15px;
  }
  #root .player-avatar {
    width: 120px;
    height: 120px;
    margin-bottom: 32px;
  }
  #root .player-name {
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .player-joined {
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .player-best-win {
    padding: 10px 16px;
    font-size: 28px;
    line-height: 44px;
  }
  #root .player-level-pic {
    width: 240px;
    height: 240px;
    margin-bottom: 24px;
  }
  #root .player-info-modal .promo-modal-title-text {
    font-size: 64px;
    line-height: 78px;
  }
  #root .player-favorite-games-block {
    margin-top: 74px;
  }
  #root .player-favorite-games-top {
    margin-bottom: 48px;
  }
  #root .player-favorite-games-title {
    font-size: 48px;
    line-height: 60px;
  }
  #root .player-info-modal .levels-carousel-arrows {
    gap: 16px;
  }
  #root .player-info-modal .levels-carousel-arrow {
    width: 60px;
    height: 60px;
  }
  #root .player-info-modal .levels-carousel-arrow svg {
    width: 28px;
    height: 28px;
  }
  #root .player-favorite-games.hidden {
    transform: translateY(-40px);
  }
  #root .player-favorite-game {
    height: 260px;
    border-radius: 14px;
  }
  #root .player-favorite-game-pic {
    height: 160px;
    border-radius: 14px 14px 0px 0px;
  }
  #root .player-favorite-game-pic .play-img {
    width: 64px;
    height: 64px;
  }
  #root .player-favorite-game-bottom {
    height: 100px;
    min-height: 100px;
    border-radius: 0px 0px 14px 14px;
  }
  #root .player-favorite-game-bottom span {
    font-size: 28px;
    line-height: 44px;
  }
  #root .live-chat-rules-modal.how-to-charge-modal.mod-window::part(content) {
    max-width: 1060px;
  }
  #root .live-chat-rules-modal .modal-header {
    height: 100px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .live-chat-rules-modal .modal-body {
    padding: 56px;
  }
  #root .live-chat-rules-modal .promo-modal-title {
    margin-bottom: 56px;
    font-size: 48px;
    line-height: 60px;
  }
  #root .live-chat-rules-modal .promo-modal-text {
    margin: 0 0 32px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .bingo-coin-modal.how-to-charge-modal.mod-window::part(content) {
    max-width: 1680px;
  }
  #root .bingo-coin-modal .modal-header {
    height: 100px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .bingo-coin-modal .modal-block-top {
    width: 550px;
    margin-bottom: 90px;
  }
  #root .bingo-coin-modal .modal-block-bottom-title.gradient {
    font-size: 84px;
    line-height: 94px;
  }
  #root .bingo-coin-modal .modal-block-bottom-title.gradient span {
    font-size: 84px;
    line-height: 94px;
  }
  #root .modal-block-labels {
    margin-bottom: 56px;
  }
  #root .bingo-coin-modal .modal-block-bottom-title {
    margin-bottom: 16px;
  }
  #root .bingo-coin-modal .modal-block-top-img {
    width: 60px;
    margin-right: 24px;
  }
  #root .bingo-coin-modal .modal-block-labels .modal-block-bottom-description {
    font-size: 28px;
    line-height: 40px;
  }
  #root .modal-block-bottom-description .oval-text {
    margin-left: 4px;
    margin-right: 4px;
  }
  #root .modal-block-bottom-description .oval-text img {
    min-width: 120px;
    top: inherit;
  }
  #root .promo-modal-title {
    margin-bottom: 30px;
  }
  #root .bingo-coin-modal .promo-modal-text {
    margin-top: 50px;
  }
  #root .cashback-success-modal .mod-confirm .modal-data {
    padding: 70px 100px;
  }
  #root .cashback-success-modal-left {
    width: 580px;
    margin-right: 100px;
  }
  #root .cashback-success-modal-left svg {
    width: 580px;
  }
  #root .cashback-amount {
    margin-bottom: 20px;
    margin-right: 60px;
  }
  #root .cashback-amount-top {
    font-size: 64px;
    line-height: 88px;
  }
  #root .cashback-amount .bc-icon {
    width: 48px;
    margin-right: 6px;
  }
  #root .cashback-amount-bottom {
    font-size: 36px;
    line-height: 52px;
  }
  #root .cashback-success-modal .promo-modal-text {
    margin-top: 20px;
  }
  #root .cashback-success-modal .promo-modal-form-buttons {
    margin-top: 100px;
  }
  #root .cashback-success-modal .promo-modal-form-buttons button {
    font-size: 36px;
  }
  #root .promo-modal-label {
    margin-bottom: 14px;
    font-size: 32px;
    line-height: 38px;
  }
  #root .avatars-modal.mod-window::part(content) {
    min-width: 1160px;
    min-height: 1160px;
    border-radius: 14px;
  }
  #root .avatars-modal .absolute-close-modal {
    width: 44px;
    height: 44px;
  }
  #root .avatars-modal .absolute-close-modal svg {
    width: 21px;
    height: 21px;
  }
  #root .avatars-modal .close-gme {
    width: 100px;
    height: 100px;
  }
  #root .avatars-container {
    padding: 40px;
    gap: 20px;
  }
  #root .pick-avatar {
    width: 200px;
    height: 200px;
    /* flex: 0 0 200px; */
  }
  #root .pick-avatar:hover {
    box-shadow: 0 0 0 4px var(--green);
  }
  #root .pick-avatar img {
    width: 100%;
    height: auto;
  }
  #root .create-promo-window.mod-window::part(content) {
    max-width: 1200px;
    min-height: 720px;
  }
  #root .promo-modal-form .input-container {
    margin-bottom: 40px;
  }
  #root .promo-modal-form .input-container .input-title {
    font-size: 28px;
    line-height: 34px;
  }
  #root .promo-modal-form .input-container .input-title span {
    font-size: 32px;
  }
  #root .promo-modal-form-buttons button {
    font-size: 32px;
    line-height: 38px;
  }
  #root .promo-window.mod-window::part(content) {
    min-height: 600px;
  }
}
