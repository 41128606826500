ion-modal.premium-modal.modal-share {
  --width: 500px;
  --height: 216px;
  --border-radius: 8px;
  --background: var(--modal-body-standart-bg);
}
.modal-share-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: inherit;
  height: 52px;
  background-color: var(--modal-header-bg);
  padding: 14px 20px;
}
.modal-share-header .title-header-share {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: var(--section-message-text);
}
.basic-info-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.basic-info-cont .share-basic-text {
  margin-top: 32px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  background-image: var(--share-rainbow-text);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.basic-info-cont .circles-share {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 32px;
}
.basic-info-cont .circles-share .circl-cont-el {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.basic-info-cont .circles-share .circl-cont-el:hover {
  position: relative;
}
.basic-info-cont .circles-share .circl-cont-el:hover .text-circles {
  display: inline;
  padding: 0px 6px;
  background-color: var(--header-background);
}
.basic-info-cont .circles-share .circl-cont-el.fk:hover .circles {
  background-color: var(--blue);
}
.basic-info-cont .circles-share .circl-cont-el.fk:hover .circles svg {
  fill: #ffffff;
}
.basic-info-cont .circles-share .circl-cont-el.dd:hover .circles {
  background-color: var(--discord);
}
.basic-info-cont .circles-share .circl-cont-el.dd:hover .circles svg {
  fill: #ffffff;
}
.basic-info-cont .circles-share .circl-cont-el.tr:hover .circles {
  background-color: var(--twitter);
}
.basic-info-cont .circles-share .circl-cont-el.tr:hover .circles svg {
  fill: #ffffff;
}
.basic-info-cont .circles-share .circl-cont-el.tm:hover .circles {
  background-color: var(--telegram);
}
.basic-info-cont .circles-share .circl-cont-el.tm:hover .circles svg {
  fill: #ffffff;
}
.basic-info-cont .circles-share .circl-cont-el.im:hover .circles {
  background-color: var(--instagram);
}
.basic-info-cont .circles-share .circl-cont-el.im:hover .circles svg {
  fill: #ffffff;
}
.basic-info-cont .circles-share .circl-cont-el.cy:hover .circles {
  background-color: var(--green);
}
.basic-info-cont .circles-share .circl-cont-el.cy:hover .circles svg {
  fill: #ffffff;
}
.basic-info-cont .circles-share .text-circles {
  text-align: center;
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: auto;
  top: -24px;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0px 6px;
  border-radius: 4px;
  color: var(--subscribe-text-description);
}
.basic-info-cont .circles {
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: var(--subcategories-item-bg);
  cursor: pointer;
}
.basic-info-cont .circles svg {
  fill: var(--login-with-svg);
}
ion-toast.toast-copy {
  --background: var(--header-background);
  --border-radius: 8px;
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  --width: 352px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--section-message-text);
  --height: 56px;
}
@media  ( min-width: 3400px )  {
  #root ion-modal.premium-modal.modal-share {
    --width: 926px;
    --height: 416px;
    --border-radius: 14px;
  }
  #root .modal-share-header {
    height: 92px;
    padding: 24px 30px;
  }
  #root .modal-share-header .title-header-share {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
  }
  #root .basic-info-cont .share-basic-text {
    margin-top: 72px;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
  }
  #root .basic-info-cont .circles-share {
    gap: 32px;
    margin-bottom: 72px;
  }
  #root .basic-info-cont .circles-share .circl-cont-el.fk .circles svg {
    width: 19.6px;
    height: 44.8px;
  }
  #root .basic-info-cont .circles-share .circl-cont-el.dd .circles svg {
    width: 44.8px;
    height: 35.2px;
  }
  #root .basic-info-cont .circles-share .circl-cont-el.tr .circles svg {
    width: 44.8px;
    height: 36.4px;
  }
  #root .basic-info-cont .circles-share .circl-cont-el.tm .circles svg {
    width: 44.8px;
    height: 36.4px;
  }
  #root .basic-info-cont .circles-share .circl-cont-el.im .circles svg {
    width: 44.8px;
    height: 44.8px;
  }
  #root .basic-info-cont .circles-share .circl-cont-el.cy .circles svg {
    width: 29.87px;
    height: 37.34px;
  }
  #root .basic-info-cont .circles-share .circl-cont-el:hover .text-circles {
    padding: 0px 10px;
  }
  #root .basic-info-cont .circles-share .circl-cont-el.fk:hover .circles {
    background-color: var(--blue);
  }
  #root .basic-info-cont .circles-share .circl-cont-el.fk:hover .circles svg {
    fill: #ffffff;
  }
  #root .basic-info-cont .circles-share .circl-cont-el.dd:hover .circles {
    background-color: var(--discord);
  }
  #root .basic-info-cont .circles-share .circl-cont-el.dd:hover .circles svg {
    fill: #ffffff;
  }
  #root .basic-info-cont .circles-share .circl-cont-el.tr:hover .circles {
    background-color: var(--twitter);
  }
  #root .basic-info-cont .circles-share .circl-cont-el.tr:hover .circles svg {
    fill: #ffffff;
  }
  #root .basic-info-cont .circles-share .circl-cont-el.tm:hover .circles {
    background-color: var(--telegram);
  }
  #root .basic-info-cont .circles-share .circl-cont-el.tm:hover .circles svg {
    fill: #ffffff;
  }
  #root .basic-info-cont .circles-share .circl-cont-el.im:hover .circles {
    background-color: var(--instagram);
  }
  #root .basic-info-cont .circles-share .circl-cont-el.im:hover .circles svg {
    fill: #ffffff;
  }
  #root .basic-info-cont .circles-share .circl-cont-el.cy:hover .circles {
    background-color: var(--green);
  }
  #root .basic-info-cont .circles-share .circl-cont-el.cy:hover .circles svg {
    fill: #ffffff;
  }
  #root .basic-info-cont .circles-share .text-circles {
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
    top: -48px;
    padding: 0px 10px;
    border-radius: 8px;
  }
  #root .basic-info-cont .circles {
    margin-top: 8px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
  }
  #root ion-toast.toast-copy {
    --border-radius: 14px;
    padding: 24px;
    gap: 16px;
    --width: 615px;
    --min-width: 615px;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    --height: 86px;
  }
}
@media  ( max-width: 450px )  {
  ion-modal.premium-modal.modal-share {
    --width: 336px;
    --height: 288px;
    --border-radius: 8px;
    --background: var(--modal-body-standart-bg);
  }
  .modal-share-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    height: 52px;
    background-color: var(--modal-header-bg);
    padding: 14px 20px;
  }
  .modal-share-header .title-header-share {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: var(--section-message-text);
  }
  .basic-info-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .basic-info-cont .share-basic-text {
    margin-top: 32px;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    background-image: var(--share-rainbow-text);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .basic-info-cont .circles-share {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 69px;
    gap: 24px;
    margin-bottom: 32px;
  }
  .basic-info-cont .circles-share .circl-cont-el {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .basic-info-cont .circles-share .circl-cont-el:hover {
    position: relative;
  }
  .basic-info-cont .circles-share .circl-cont-el:hover .text-circles {
    display: inline;
    padding: 0px 6px;
    background-color: var(--header-background);
  }
  .basic-info-cont .circles-share .circl-cont-el.fk:hover .circles {
    background-color: var(--blue);
  }
  .basic-info-cont .circles-share .circl-cont-el.fk:hover .circles svg {
    fill: #ffffff;
  }
  .basic-info-cont .circles-share .circl-cont-el.dd:hover .circles {
    background-color: var(--discord);
  }
  .basic-info-cont .circles-share .circl-cont-el.dd:hover .circles svg {
    fill: #ffffff;
  }
  .basic-info-cont .circles-share .circl-cont-el.tr:hover .circles {
    background-color: var(--twitter);
  }
  .basic-info-cont .circles-share .circl-cont-el.tr:hover .circles svg {
    fill: #ffffff;
  }
  .basic-info-cont .circles-share .circl-cont-el.tm:hover .circles {
    background-color: var(--telegram);
  }
  .basic-info-cont .circles-share .circl-cont-el.tm:hover .circles svg {
    fill: #ffffff;
  }
  .basic-info-cont .circles-share .circl-cont-el.im:hover .circles {
    background-color: var(--instagram);
  }
  .basic-info-cont .circles-share .circl-cont-el.im:hover .circles svg {
    fill: #ffffff;
  }
  .basic-info-cont .circles-share .circl-cont-el.cy:hover .circles {
    background-color: var(--green);
  }
  .basic-info-cont .circles-share .circl-cont-el.cy:hover .circles svg {
    fill: #ffffff;
  }
  .basic-info-cont .circles-share .text-circles {
    text-align: center;
    position: absolute;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    width: auto;
    top: -24px;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 0px 6px;
    border-radius: 4px;
    color: var(--subscribe-text-description);
  }
  .basic-info-cont .circles {
    display: flex;
    margin-top: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: var(--subcategories-item-bg);
    cursor: pointer;
  }
  .basic-info-cont .circles svg {
    fill: var(--login-with-svg);
  }
  ion-toast.toast-copy {
    --background: var(--header-background);
    --border-radius: 8px;
    padding: 16px;
    gap: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    --width: 352px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--section-message-text);
    --height: 56px;
  }
}
